export const ACCESS_ALL_INFLUENCERS = 'access_all_influencers';
export const ACCESS_ALL_ADVERTISERS = 'access_all_advertisers';
export const ACCESS_ALL_GIGS = 'access_all_gigs';
export const ACCOUNTING_ROLE = 'accounting_role';
export const ACCOUNT_MANAGER_ROLE = 'account_manager_role';
export const CREATE_BRAND = 'create_brand';
export const VIEW_BRAND_INFO = 'view_brand_info';
export const DEVELOPER = 'developer';
export const EDIT_CAMPAIGN = 'edit_campaign';
export const LAUNCH_CAMPAIGN = 'launch_campaign';
export const MANAGE_INFLUENCERS = 'manage_influencers';
export const MANAGE_PAYMENTS = 'manage_payments';
export const MANAGE_USERS = 'manage_users';
export const TEAM_MEMBER = 'team_member';
export const VIEW_PARTICIPANTS = 'view_participants';
export const VIEW_POST_REPORTS = 'view_post_reports';
export const DASHBOARD_PAGE_ACCESS = 'dashboard_page_access';
export const CAMPAIGNS_PAGE_ACCESS = 'campaigns_page_access';
export const MANAGE_SHIPPING_INFO = 'manage_shipping_info';
export const VIEW_GIG_HISTORY = 'view_gig_history';
export const CAMPAIGN_MANAGER = 'campaign_manager';
export const REVIEW_GIG = 'review_gig';
export const IMPERSONATE_ADVERTISER = 'impersonate_advertiser';
export const IMPERSONATE_ANY_USER = 'impersonate_any_user';
export const REMOVE_USER_FROM_ADVERTISER = 'remove_user_from_advertiser';
export const VIEW_CAMPAIGN_PERFORMANCE = 'view_campaign_performance';
