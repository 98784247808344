import React from 'react';
import {gql, useQuery} from '@apollo/client';

import {
  Definition,
  InstagramMedia,
  LoaderDots,
  ProfilePicture,
  RowWrap,
  Modal,
} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';

import styles from './style.css';
import {Gig, Influencer} from 'services/fragments';
import {Alert} from '@material-ui/lab';

interface Props {
  id: string;
  closeModal: () => void;
}

const InfluencerProfileModal: React.FC<Props> = ({id, closeModal}) => {
  const {
    loading: influencerProfileLoading,
    error: influencerProfileError,
    data: influencerProfileData,
  } = useQuery(InfluencerProfileQuery, {variables: {id}, skip: !id});

  const {
    loading: influencerMediaLoading,
    error: influencerMediaError,
    data: influencerMediaData,
  } = useQuery(InfluencerMediaQuery, {variables: {id}, skip: !id});

  if (!id) {
    return null;
  }

  const renderInfluencerProfileHeader = () => {
    const {influencer}: {influencer: Influencer} = influencerProfileData;
    return (
      <div className={styles.header}>
        <ProfilePicture src={influencer.profilePicture} size={100} />
        <div className={styles.info}>
          <div className={styles.username}>{influencer.fullName}</div>
          <div className={styles.stats}>
            <Definition title="Followers" definition={numberWithCommas(influencer.followers)} />
            <Definition
              title="Engagement"
              definition={influencer.instagramAccount?.engagement?.formattedValue ?? '0.00%'}
            />
          </div>
          <Definition small title="Bio" definition={influencer.biography} />
        </div>
      </div>
    );
  };

  const renderInfluencerMedia = () => {
    const {influencer}: {influencer: Influencer} = influencerProfileData;
    const {instagramPosts} = influencerMediaData;
    return (
      <div className={styles.mediaContainer}>
        {influencerMediaLoading && <LoaderDots />}
        {influencerMediaError && <Alert severity="error">Error loading media</Alert>}
        <RowWrap className={styles.rowWrap}>
          {instagramPosts.edges
            .map(edge => edge.node)
            .map(instagramPost => (
              <InstagramMedia
                key={instagramPost.id}
                influencer={influencer}
                instagramPost={instagramPost}
              />
            ))}
          {instagramPosts && influencer.instagramAccount && (
            <a
              className={styles.viewMore}
              href={`https://www.instagram.com/${influencer.instagramAccount?.username}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View more on Instagram
            </a>
          )}
        </RowWrap>
      </div>
    );
  };

  return (
    <Modal id="influencer-modal" title="" fullWidth maxWidth="sm" open onClose={closeModal}>
      {influencerProfileLoading && <LoaderDots />}
      {influencerProfileError && <Alert severity="error">Error loading influencer</Alert>}
      <div className={styles.root}>
        {renderInfluencerProfileHeader()}
        {renderInfluencerMedia()}
      </div>
    </Modal>
  );
};

const InfluencerProfileQuery = gql`
  query InfluencerProfile($id: UUIDString!) {
    influencer: profile(id: $id) {
      id
      ... on Influencer {
        id
        fullName
        profilePicture
        ...socialPlatformsFields
      }
    }
  }
  ${Influencer.socialPlatformsFragment}
`;

const InfluencerMediaQuery = gql`
  query InfluencerMediaQuery($id: UUIDString!, $cursor: String) {
    instagramPosts: instagramMediaByUsername(id: $id, after: $cursor) {
      edges {
        node {
          id
          caption
          mentions
          hashtags
          media {
            ...mediaResultFields
          }
          shortcode
          comments
          likes
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${Gig.mediaPreview}
`;

export default InfluencerProfileModal;
