import React, {useRef} from 'react';
import {useMutation, gql} from '@apollo/client';
import moment from 'moment';
import {Button, DialogContent} from '@material-ui/core';

import {Modal} from '../../../../../components/Widgets';
import {
  Form,
  FormListOfFilesInput,
  FormSubmitButton,
  FormTextArea,
} from '../../../../../components/Widgets/Forms';
import {uploadMultipleMedia} from '../../../../../services/api';
import {CampaignPostType} from '../../../../../consts/campaignPostType';
import {ModalElement} from '../../../../../components/Widgets/Modal';
import ErrorReporting from '../../../../../services/error-reporting';
import {Block} from '@material-ui/icons';

interface Props {
  influencer: {
    id: string;
  };
  post: {
    id: string;
    postType: string;
    opened: string;
    mention: string;
    hashtags: string[];
    startFirstHashtag: boolean;
  };
  idx: number;
  refetchQueries?: string[];
  disabled?: boolean;
}

type Model = {
  files: string[];
  caption: string;
};

type Missing = {
  hashtags?: string[];
  mention?: string;
  first?: string;
};

const SubmitModal: React.FC<Props> = ({influencer, post, idx, refetchQueries, disabled}) => {
  const modal = useRef<ModalElement>(null);
  const [submitMedia] = useMutation(SubmitMediaMutation, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const onSubmit = async (model: Model) => {
    const {files, caption} = model;

    // Upload the media
    const formData = new FormData();
    const keys: string[] = [];
    files.forEach((file, idx) => {
      const key = `file${idx}`;
      formData.append(key, file);
      keys.push(key);
    });

    try {
      const result = await uploadMultipleMedia(formData, keys);
      const media = result.map(({data}) => ({
        url: data.urls[0],
        type: data.filetype?.startsWith('video') ? 'video' : 'image',
      }));

      // Submit the whole thing
      await submitMedia({
        variables: {
          influencerId: influencer.id,
          caption,
          media,
          postId: post.id,
        },
      });

      modal.current?.close();
    } catch (error: any) {
      ErrorReporting.captureError(error);
    }
  };

  const missingConditions = (caption: string): Missing => {
    const missing: Missing = {};
    const hashtags = post.hashtags?.filter(
      hashtag => caption.toLowerCase().indexOf(`#${hashtag?.toLowerCase()}`) === -1
    );
    if (hashtags?.length) {
      missing.hashtags = hashtags;
    }
    if (caption?.toLowerCase().indexOf(`@${post.mention?.toLowerCase()}`) === -1) {
      missing.mention = post.mention;
    }
    if (caption?.toLowerCase().trim().indexOf(`#${post?.hashtags[0]?.toLowerCase()}`) !== 0) {
      missing.first = post.hashtags[0];
    }
    return missing;
  };

  const validCaption = (caption: string): boolean => {
    const missing = missingConditions(caption);
    return !missing.hashtags && !missing.mention && !missing.first;
  };

  const validCaptionMessage = (caption: string): JSX.Element => {
    const errors = [];
    const missing = missingConditions(caption);
    if (missing.first) {
      errors.push(`#${missing.first} needs to be at the start of the caption`);
    }
    if (missing.hashtags) {
      missing.hashtags.forEach(hashtag => errors.push(`#${hashtag} is missing`));
    }
    if (missing.mention) {
      errors.push(`@${missing.mention} is missing`);
    }
    return (
      <div>
        {errors.map(error => (
          <span key={error}>{error}</span>
        ))}
      </div>
    );
  };

  const getPostDate = () => {
    const date = moment(post.opened);
    if (!date.isValid) {
      return '-';
    }
    return date.format('Do MMM YYYY');
  };

  return (
    <Modal
      ref={modal}
      id="submit-modal"
      title={`Post ${idx}. ${
        post.postType[0].toUpperCase() + post.postType.substring(1)
      } - ${getPostDate()}`}
      modalToggler={
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          startIcon={disabled ? <Block /> : null}
        >
          Submit for influencer
        </Button>
      }
      showClose
    >
      <DialogContent>
        <Form initialState={{caption: ''}} onSubmit={onSubmit}>
          {[
            CampaignPostType.standard,
            CampaignPostType.video,
            CampaignPostType.reel,
            CampaignPostType.tiktok,
            CampaignPostType.youtube,
            CampaignPostType.content,
          ].indexOf(post.postType) >= 0 && (
            <FormTextArea
              name="caption"
              label="Caption"
              validators={{validCaption}}
              messages={{validCaption: validCaptionMessage}}
            />
          )}
          <FormListOfFilesInput
            type="file"
            name="files"
            label="Media"
            required
            validators={(files: Array<File | undefined> | undefined): boolean => {
              if (!files) {
                return false;
              }
              return files.length > 0 && files.every(file => file !== undefined);
            }}
            description="The media that should be uploaded for this particular post"
          />
          <FormSubmitButton text="Submit" gold block />
        </Form>
      </DialogContent>
    </Modal>
  );
};

const SubmitMediaMutation = gql`
  mutation SubmitMediaMutation(
    $influencerId: UUIDString
    $caption: String!
    $media: [MediaInput]!
    $postId: UUIDString!
  ) {
    submitMedia(influencerId: $influencerId, caption: $caption, media: $media, postId: $postId) {
      ok
      gig {
        id
        state
      }
    }
  }
`;

export default SubmitModal;
