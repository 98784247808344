import React, {useEffect, useRef, useState} from 'react';
import {Box, Chip, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import {useQuery} from '@apollo/client';

import {
  LoaderDots,
  NoCampaignsSelected,
  NoPerformanceData,
  UnauthorizedAccess,
} from 'components/Widgets';
import {ModalElement} from 'components/Widgets/Modal';
import {
  ApiError,
  MissingPaidPerformanceData,
  MissingPerformanceData,
} from 'components/Widgets/Alerts';

import useFeatureFlags from 'hooks/useFeatureFlags';
import useCurrentUser from 'hooks/useCurrentUser';
import {useDataServiceApi} from 'hooks/useDataServiceApi';
import type {
  CampaignPaidPerformanceResponse,
  CampaignPerformanceResponse,
  CampaignPlatformPerformanceResponse,
} from 'hooks/useDataServiceApi/types';

import {AdvertiserCampaignQuery} from '../AdvertiserPerformance/graphqlQuery';
import AdvertiserTabs from '../AdvertiserTabs';
import {CampaignModal} from '../components';
import {Match} from '../AdvertiserMembers/types';
import {useSelectedCampaignsLocationState} from '../hooks/useSelectedCampaignsLocationState';
import CampaignComparisonTable from '../components/CampaignComparisonTable';
import CampaignPlatformComparisonTable, {
  Props as CampaignPlatformComparisonCardsProps,
} from '../components/CampaignPlatformComparisonTable';
import CampaignPaidPerformanceComparisonTable from '../components/CampaignPaidPerformanceComparisonTable';

interface Props {
  match: Match;
}

const AdvertiserCampaignCompare: React.FC<Props> = ({match: {params}}) => {
  const classes = useStyles();
  const {showCampaignPerformanceCompare} = useFeatureFlags();
  const user = useCurrentUser();

  const {
    selected: campaignIdsArray,
    add: addToSelectedCampaigns,
    remove: removeFromSelectedCampaigns,
  } = useSelectedCampaignsLocationState();
  const campaignIds = campaignIdsArray.join(',');
  const modal = useRef<ModalElement>(null);
  const [loading, setLoading] = useState(true);
  const [missingCampaignWarnings, setMissingCampaignsWarning] = useState<string[]>([]);
  const [performanceData, setPerformanceData] = useState<CampaignPerformanceResponse | null>(null);
  const [platformData, setPlatformData] = useState<CampaignPlatformPerformanceResponse | null>(
    null
  );
  const [paidPerformanceData, setPaidPerformanceData] =
    useState<CampaignPaidPerformanceResponse | null>(null);
  const {data} = useQuery<{advertiser: Advertiser}>(AdvertiserCampaignQuery, {
    variables: {domain: params.advertiser},
  });

  const advertiser = data && data.advertiser;
  const campaigns = advertiser && (advertiser.campaigns as Campaign[]);
  const selectedCampaigns =
    advertiser &&
    (advertiser.campaigns?.filter(campaign =>
      campaignIdsArray.includes(String(campaign!.id))
    ) as Campaign[]);

  const unselectedCampaigns =
    campaigns?.filter(
      campaign => !selectedCampaigns?.some(selected => selected.id === campaign.id)
    ) || [];

  const {error, fetchCampaignPerformanceData} = useDataServiceApi();
  const {error: platformError, fetchCampaignPerformanceByPlatformData} = useDataServiceApi();
  const {error: paidError, fetchCampaignPaidPerformanceByCampaign} = useDataServiceApi();

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      const apiToken = user?.takumiDataApiToken || '';
      const params = {campaignIds, apiToken};

      return Promise.all([
        fetchCampaignPerformanceData(params),
        fetchCampaignPerformanceByPlatformData(params),
        fetchCampaignPaidPerformanceByCampaign(params),
      ]).then(([performanceData, platformData, paidData]) => {
        setPerformanceData(performanceData || []);
        setPlatformData(platformData || []);
        setPaidPerformanceData(paidData || []);

        setMissingCampaignsWarning([
          ...(performanceData?.length !== campaignIdsArray.length ? ['performance'] : []),
          ...(paidData?.length !== campaignIdsArray.length ? ['paid'] : []),
        ]);
        /**
         * We don't use the `useDataServiceApi.loading` property to avoid
         * displaying the loading dots every time you unselect a campaign.
         */
        setLoading(false);
      });
    };

    fetchData();
  }, [user?.takumiDataApiToken, campaignIds]);

  if (loading || !campaigns) {
    return <LoaderDots className={classes.spacing} />;
  }

  if (!showCampaignPerformanceCompare) {
    return <UnauthorizedAccess />;
  }

  if (error || platformError || paidError) {
    return (
      <>
        <AdvertiserTabs params={params} />
        <ApiError title="Brand Performance" classes={classes} />
      </>
    );
  }

  return (
    <>
      <AdvertiserTabs params={params} />
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} md={7} lg={8}>
          <Typography variant="h3">Campaign Performance</Typography>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          xs={12}
          md={5}
          lg={4}
          alignItems="center"
          className={classes.toolbar}
        >
          <Grid item>
            <CampaignModal
              campaigns={unselectedCampaigns}
              handleModalSubmit={selected => {
                addToSelectedCampaigns(selected);
                modal.current?.close();
              }}
              forwardedRef={modal}
            />
          </Grid>
        </Grid>
        <Grid item>
          {selectedCampaigns?.map((campaign, i) => (
            <Chip
              key={i}
              label={campaign.name}
              className={classes.chips}
              onDelete={() => removeFromSelectedCampaigns(String(campaign.id))}
            />
          ))}
        </Grid>
      </Grid>

      {(() => {
        if (campaignIdsArray.length === 0)
          return (
            <Box my={2}>
              <NoCampaignsSelected />
            </Box>
          );

        if (performanceData!.length === 0)
          return (
            <Box my={2}>
              <NoPerformanceData title="Campaign comparison metrics" />
            </Box>
          );

        return (
          <>
            {missingCampaignWarnings.includes('performance') && (
              <Box my={2}>
                <MissingPerformanceData
                  amountMissing={campaignIds.length - performanceData!.length}
                />
              </Box>
            )}
            <CampaignComparisonTable
              brandName={params.advertiser}
              /**
               * The reason we do this mapping during rendering (rather than at
               * the point the data is requested) is because the campaign data
               * may not be returned before the performance data is.
               */
              data={performanceData!.map(item => ({
                ...item,
                campaign_name: (campaigns.find(({id}) => id === item.campaign_id) as Campaign)
                  ?.name,
                photos: (campaigns.find(({id}) => id === item.campaign_id) as Campaign)?.photos,
              }))}
            />

            {(() => {
              if (!platformData?.length) return null;

              /**
               * The reason we do this mapping during rendering (rather than at
               * the point the data is requested) is because the campaign data
               * may not be returned before the performance data is.
               *
               */
              const formattedPlatformData = platformData!.reduce<
                CampaignPlatformComparisonCardsProps['data']
              >(
                (acc, cv) => ({
                  ...acc,
                  [cv.campaign_id]: {
                    ...acc[cv.campaign_id],
                    name: (campaigns.find(({id}) => cv.campaign_id === id) as Campaign)?.name,
                    photos: (campaigns.find(({id}) => id === cv.campaign_id) as Campaign)?.photos,
                    [cv.social_media_platform]: cv,
                  },
                }),
                {}
              );

              const hasMultiPlatformCampaign = Object.values(formattedPlatformData).some(
                campaign => campaign.instagram != null && campaign.tiktok != null
              );

              if (!hasMultiPlatformCampaign) return null;

              return (
                <>
                  <Typography variant="h4" className={classes.header}>
                    By Platform
                  </Typography>
                  <Divider className={classes.spacing} />
                  <CampaignPlatformComparisonTable
                    brandName={params.advertiser}
                    data={formattedPlatformData}
                  />
                </>
              );
            })()}

            {!!paidPerformanceData?.length && (
              <>
                <Typography variant="h4" className={classes.header}>
                  Paid Performance
                </Typography>
                <Divider className={classes.spacing} />

                {missingCampaignWarnings.includes('paid') && (
                  <MissingPaidPerformanceData
                    amountMissing={campaignIds.length - paidPerformanceData?.length}
                  />
                )}

                <CampaignPaidPerformanceComparisonTable
                  brandName={params.advertiser}
                  /**
                   * The reason we do this mapping during rendering (rather than at
                   * the point the data is requested) is because the campaign data
                   * may not be returned before the performance data is.
                   */
                  data={paidPerformanceData!.map(item => ({
                    ...item,
                    campaign_name: (campaigns.find(({id}) => id === item.campaign_id) as Campaign)
                      ?.name,
                    photos: (campaigns.find(({id}) => id === item.campaign_id) as Campaign)?.photos,
                  }))}
                />
              </>
            )}
          </>
        );
      })()}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  spacing: {
    margin: '10px auto 20px',
  },
  chips: {
    margin: '5px',
  },
  toolbar: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  header: {
    marginTop: '3rem',
  },
}));

export default AdvertiserCampaignCompare;
