import React from 'react';
import {Icon} from '@iconify/react';

import {numberWithCommas} from './utilities';
import {influencer} from 'scenes/Admin/Influencer/Search/style.css';
import {getSocialPlatformNameByPostType} from 'utils/gigUtils';

const IG_ENGAGEMENT_RATE_LOWER_LIMIT = 0.01;
const IG_ENGAGEMENT_RATE_UPPER_LIMIT = 0.15;

export function getEngagementExplain(totalEngagement) {
  let text = 'Normal engagement';
  if (totalEngagement < IG_ENGAGEMENT_RATE_LOWER_LIMIT) {
    text = 'Total engagement is very low';
  }
  if (totalEngagement > IG_ENGAGEMENT_RATE_UPPER_LIMIT) {
    text = 'Total engagement is very high';
  }
  return <div>{text}</div>;
}

export function isEngagementLimitExceeded(totalEngagement) {
  return (
    totalEngagement < IG_ENGAGEMENT_RATE_LOWER_LIMIT ||
    totalEngagement > IG_ENGAGEMENT_RATE_UPPER_LIMIT
  );
}

export function getLocation(regionName, regionCountry) {
  if (regionName !== regionCountry) {
    return `${regionName}, ${regionCountry}`;
  }

  return regionName;
}

/**
 *
 * @param {{[platform: string]: Maybe<number> | undefined}} followerCount
 * @returns {JSX.Element} HTML to display to user
 */
export function multiPlatformFollowerCount(followerCount) {
  const formatPlatformName = name => {
    if (name.toLowerCase() === 'instagram') return 'Instagram';
    else if (name.toLowerCase() === 'tiktok') return 'TikTok';
  };

  return (
    <div>
      {Object.entries(followerCount).map(([platform, followerNumber]) => (
        <p key={platform}>
          <b>{formatPlatformName(platform)}:</b>{' '}
          {followerNumber ? numberWithCommas(followerNumber) : 'N/A'}
        </p>
      ))}
    </div>
  );
}

/**
 * Returns the username for a given platform and influencer.
 * @param {string} platform - "instagram", "tiktok", "youtube"
 * @param {Influencer} influencer
 * @returns {string|null}
 */
export const usernameForPlatform = (platform, influencer) => {
  switch (platform.toLowerCase()) {
    case 'instagram':
      return influencer.instagramAccount?.username;
    case 'tiktok':
      return influencer.tiktokAccount?.username;
    // case 'youtube':
    //   return influencer.youtubeAccount?.username;
    default:
      return null;
  }
};

/**
 * Returns the username for a given post and influencer.
 * @param {Post} post - A Gig Post object.
 * @param {Influencer} influencer - A Influencer object.
 * @returns {string|null}
 */
export const usernameForPost = (post, influencer) => {
  return usernameForPlatform(
    getSocialPlatformNameByPostType(post.postType)?.toLowerCase(),
    influencer
  );
};

export function campaignParticipantPlatformFollowerCount(followerCount) {
  const icon = name => {
    if (name.toLowerCase() === 'instagram') return 'mdi:instagram';
    else if (name.toLowerCase() === 'tiktok') return 'ic:baseline-tiktok';
  };
  return (
    <div>
      {Object.entries(followerCount).map(([platform, followerNumber]) => {
        if (followerNumber === undefined || followerNumber === 0) {
          return null;
        }
        return (
          <p key={platform} style={{display: 'flex', alignItems: 'center'}}>
            <Icon icon={icon(platform)} style={{height: '20px', width: '20px', margin: '5px'}} />
            {numberWithCommas(followerNumber)}
          </p>
        );
      })}
    </div>
  );
}

export const sumMultiPlatform = (accumulator, offer) => ({
  instagram: (accumulator?.instagram || 0) + (offer.influencer.instagramAccount?.followers || 0),
  tiktok: (accumulator?.tiktok || 0) + (offer.influencer.tiktokAccount?.followers || 0),
});

/**
 *
 * @param {*} offer
 * @returns {number} Total number of followers across all tracked platforms
 */
export function getTotalFollowersForOffer(offer) {
  return (
    (offer.influencer.instagramAccount?.followers || 0) +
    (offer.influencer.tiktokAccount?.followers || 0)
  );
}
