import PropTypes from 'prop-types';
import React from 'react';

import {LoaderDots} from 'components/Widgets';

import {enrollVerify} from 'services/api';

import styles from './style.css';
import {removeStoredApiToken, storeApiToken} from '../../services/auth';

class SignupVerify extends React.PureComponent {
  static propTypes = {
    signup: PropTypes.object,
    match: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  state = {
    error: null,
  };

  async componentDidMount() {
    const {
      match: {params},
    } = this.props;
    removeStoredApiToken();
    try {
      const response = await enrollVerify(params.token);

      if (response.data) {
        storeApiToken(response.data.token);
        this.props.history.push('/welcome?invite=true');
      } else {
        this.props.history.push('/login');
      }
    } catch (err) {
      this.setState({
        error: err.response ? err.response.data.error.message : 'Something went wrong',
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div className={styles.root}>
          <div className={styles.center}>
            <h1>{this.state.error}</h1>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.root}>
        <div className={styles.center}>
          <h1>Creating Account</h1>
          <LoaderDots className={styles.loader} />
        </div>
      </div>
    );
  }
}

export default SignupVerify;
