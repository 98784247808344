import React from 'react';
import classNames from 'classnames';

import styles from './style.css';

interface Props {
  className?: string;
  loading?: boolean;
  left?: boolean;
  small?: boolean;
}

const LoaderDots: React.FC<Props> = ({className = '', loading = true, left, small = false}) => {
  const clsName = classNames({
    [styles.loadingDots]: true,
    [className]: Boolean(className),
    [styles.left]: left,
  });

  return (
    <div
      className={clsName}
      style={{visibility: loading ? 'visible' : 'hidden'}}
      data-testid="loader"
    >
      <span />
      {!small && (
        <>
          <span />
          <span />
        </>
      )}
    </div>
  );
};

export default LoaderDots;
