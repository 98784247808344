import React, {useRef} from 'react';
import {
  Button,
  DialogContentText,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {useFormik} from 'formik';
import * as yup from 'yup';

import {LoaderDots, RequiresPermission, Modal} from 'components/Widgets';

import {black40} from '../../../consts/brand';
import {ModalElement} from '../../Widgets/Modal';
import {MANAGE_INFLUENCERS} from 'consts/permissions';

interface Props {
  requestResubmission: (reason: string, explanation: string) => Promise<any>;
  pending?: boolean;
}

const GigUnsubmit: React.FC<Props> = ({requestResubmission, pending}) => {
  const classes = useStyles();
  const modal = useRef<ModalElement>(null);

  const formik = useFormik({
    initialValues: {
      reason: '',
      explanation: '',
    },
    validationSchema: yup.object({
      reason: yup.string().required(),
      explanation: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = () => {
    requestResubmission(formik.values.reason, formik.values.explanation);
    modal.current?.close();
  };

  const renderForm = () => (
    <DialogContentText>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel htmlFor="reason">Reason</InputLabel>
            <Input
              id="reason"
              name="reason"
              type="text"
              value={formik.values.reason}
              aria-describedby={'reason'}
              error={formik.touched.reason}
              required
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              id="explanation"
              name="explanation"
              label="Explanation"
              value={formik.values.explanation}
              aria-describedby={'explanation'}
              error={formik.touched.explanation}
              variant="outlined"
              minRows={4}
              maxRows={10}
              multiline
              required
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>
      </Grid>
    </DialogContentText>
  );

  return (
    <RequiresPermission permission={MANAGE_INFLUENCERS}>
      <Modal
        ref={modal}
        id="gig-unsubmit"
        title="Request resubmission"
        modalToggler={
          <Button className={classes.toggler} variant="text" fullWidth>
            Request resubmission
          </Button>
        }
        fullWidth
        maxWidth="xs"
        showClose
        submitTitle="Submit"
        footer={
          <Typography variant="caption" className={classes.footer}>
            The influencer will be notified by email
          </Typography>
        }
        onSubmit={formik.submitForm}
      >
        {pending ? <LoaderDots /> : renderForm()}
      </Modal>
    </RequiresPermission>
  );
};

const useStyles = makeStyles({
  toggler: {
    color: 'red',
  },
  footer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    color: black40,
  },
});

export default GigUnsubmit;
