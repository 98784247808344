import compose from 'lodash/flowRight';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import classNames from 'classnames';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

import InfluencerProfileCard from '../../../components/Widgets/InfluencerProfileCard/InfluencerProfileCard';
import {Gig, Influencer} from '../../../services/fragments';

import {
  Media,
  MediaContainer,
  ProfilePicture,
  LoaderDots,
  RequiresPermission,
  Pill,
  Tooltip,
} from 'components/Widgets';
import {LeftChevron, RightChevron} from 'components/Icons';

import {REVIEWED, REPORTED, REQUIRES_RESUBMIT, APPROVED} from 'consts/variables';
import {advertiserUrl, campaignUrl} from 'consts/urls';
import {black20} from 'consts/brand';
import {hasMedia, isDeleted, latestMedia, isLive} from 'consts/gigHelper';

import {
  GigActionsMenu,
  GigBusStop,
  GigMediaInfo,
  MediaPlaceholder,
  OfferPaymentLink,
  ReviewGigButton,
  GigApprovedCheckbox,
} from '../';
import styles from './style.css';

class CampaignGig extends PureComponent {
  static propTypes = {
    gig: PropTypes.object,
    insightsGig: PropTypes.object,
    goBack: PropTypes.func.isRequired,
    goToGig: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    nextGigId: PropTypes.string,
    previousGigId: PropTypes.string,
    canAccessAllInfluencers: PropTypes.bool,
    reviewGig: PropTypes.func.isRequired,
    galleryPage: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      reviewButtonIsVisible: false,
      videoPlaying: false,
    };
  }

  componentDidMount() {
    this.overflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    window.addEventListener('beforeunload', this.restoreScroll);
  }

  componentWillUnmount() {
    this.restoreScroll();
    window.removeEventListener('beforeunload', this.restoreScroll);
  }

  restoreScroll = () => {
    document.body.style.overflow = this.overflow;
    requestAnimationFrame(() => {
      document.body.removeAttribute('style');
    });
  };

  pillForState = (state, deleted, reviewer) => {
    const wrap = (reviewer, pill) =>
      reviewer ? <Tooltip overlay={`Reviewed by ${reviewer.fullName}`}>{pill}</Tooltip> : pill;

    if (deleted) {
      return wrap(reviewer, <Pill red>Post deleted</Pill>);
    }
    if (state === REPORTED) {
      return wrap(reviewer, <Pill red>Reported</Pill>);
    }
    if (state === REQUIRES_RESUBMIT) {
      return wrap(reviewer, <Pill yellow>Resubmit requested</Pill>);
    }
    return null;
  };

  adminReviewGig = () => {
    const {gig} = this.props;
    this.props.reviewGig(gig.id);
  };

  closeOverlay = event => {
    if (event.target === event.currentTarget) {
      this.props.goBack();
    }
  };

  toggleViewButton = () => {
    this.setState({
      reviewButtonIsVisible: !this.state.reviewButtonIsVisible,
    });
  };

  playVideo = () => this.setState({videoPlaying: !this.state.videoPlaying});

  insertInsights(gig) {
    const {insightsGig} = this.props;
    if (!insightsGig) return gig;
    return {
      ...gig,
      instagramContent: insightsGig.instagramContent && {
        ...gig.instagramContent,
        storyFrames:
          insightsGig?.instagramContent?.storyFrames &&
          gig?.instagramContent?.storyFrames.map(f => {
            const matchingstoryFrameInsight = insightsGig?.instagramContent?.storyFrames.find(
              sfi => sfi.id === f.id
            );
            return {
              ...f,
              instagramStoryFrameInsight: matchingstoryFrameInsight?.instagramStoryFrameInsight,
            };
          }),
        instagramPostInsight: insightsGig.instagramContent.instagramPostInsight,
      },
    };
  }

  render() {
    if (this.props.loading) {
      return (
        <div className={styles.root}>
          <LoaderDots />
        </div>
      );
    }

    const {previousGigId, nextGigId, canAccessAllInfluencers} = this.props;

    if (!this.props.gig) {
      return null;
    }

    const gig = this.insertInsights(this.props.gig);
    const campaign = gig.post.campaign;
    const _hasMedia = hasMedia(gig);
    const media = latestMedia(gig);

    return (
      <div className={styles.root} onClick={this.closeOverlay}>
        <div
          className={classNames({
            [styles.leftArrow]: true,
            [styles.disable]: !this.props.loading && !previousGigId,
          })}
          onClick={() => previousGigId && this.props.goToGig(previousGigId)}
        >
          <LeftChevron color={black20} />
        </div>
        <div className={styles.container}>
          {_hasMedia && (
            <MediaContainer
              width={600}
              height={700}
              onMouseOver={this.toggleViewButton}
              onMouseOut={this.toggleViewButton}
            >
              <Media media={media} width={600} height={600} />

              <RequiresPermission permission="review_gig">
                {gig.state !== REPORTED &&
                  gig.state !== REQUIRES_RESUBMIT &&
                  !isDeleted(gig) &&
                  !this.state.videoPlaying && (
                    <ReviewGigButton
                      reviewed={
                        gig.state === REVIEWED || gig.state == APPROVED ? gig.reviewer : null
                      }
                      reviewGig={this.adminReviewGig}
                      visible={this.state.reviewButtonIsVisible}
                    />
                  )}
              </RequiresPermission>

              <RequiresPermission permission="review_gig">
                {gig.post.campaign.brandSafety &&
                  gig.state === APPROVED &&
                  gig.approver &&
                  !this.state.videoPlaying && (
                    <GigApprovedCheckbox approver={gig.state === APPROVED ? gig.approver : null} />
                  )}
              </RequiresPermission>

              <div className={styles.pillContainer}>
                {this.pillForState(gig.state, isDeleted(gig), gig.reviewer)}
              </div>
            </MediaContainer>
          )}
          {!_hasMedia && (
            <div className={styles.media}>
              <MediaPlaceholder />
            </div>
          )}
          <div className={styles.gigData}>
            <div className={styles.topSection}>
              <div className={styles.header}>
                <div className={styles.influencerUser}>
                  <InfluencerProfileCard influencer={gig.influencer} showFollowers showRegion />
                </div>
                <GigActionsMenu
                  gig={gig}
                  toggler={
                    <div className={styles.menu}>
                      <span />
                      <span />
                      <span />
                    </div>
                  }
                />
              </div>

              <div className={styles.divider} />

              {campaign && (
                <div className={styles.campaign}>
                  <Link to={advertiserUrl(campaign.advertiser)}>
                    <ProfilePicture
                      square
                      src={campaign.advertiser.profilePicture}
                      size={44}
                      className={styles.campaignBrandLogo}
                    />
                  </Link>

                  <div className={styles.campaignInfo}>
                    <Link to={campaignUrl(campaign)} className={styles.campaignName}>
                      {campaign.name}
                    </Link>
                    <Link
                      to={advertiserUrl(campaign.advertiser)}
                      className={styles.campaignBrandName}
                    >
                      {campaign.advertiser.name}
                    </Link>
                  </div>
                </div>
              )}

              <div className={styles.gigBusStop}>
                <GigBusStop gig={gig} />
                <RequiresPermission permission="manage_payments">
                  <OfferPaymentLink offerId={gig.offer.id} />
                </RequiresPermission>
              </div>
            </div>

            <div className={styles.bottomSection}>
              {_hasMedia && (
                <div className={styles.gigMediaInfo}>
                  <GigMediaInfo gig={gig} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames({
            [styles.rightArrow]: true,
            [styles.disable]: !this.props.loading && !nextGigId,
          })}
          onClick={() => nextGigId && this.props.goToGig(nextGigId)}
        >
          <RightChevron color={black20} />
        </div>
      </div>
    );
  }
}

const GigInstagramInsightsQuery = gql`
  query GigInstagramInsightsQuery($gigId: UUIDString!) {
    gig(id: $gigId) {
      id
      instagramContent {
        id
        ... on InstagramPost {
          instagramPostInsight(fetchIfNotAvailable: true) {
            id
            created
            modified
            engagement
            impressions
            reach
            saved
            videoViews
            carouselAlbumEngagement
            carouselAlbumSaved
            carouselAlbumEngagement
            carouselAlbumImpressions
            shares
          }
        }
        ... on InstagramStory {
          storyFrames {
            id
            instagramStoryFrameInsight(fetchIfNotAvailable: true) {
              id
              created
              modified
              exits
              impressions
              reach
              replies
              tapsForward
              tapsBack
            }
          }
        }
      }
    }
  }
`;

const GigQuery = gql`
  query GigQuery(
    $gigId: UUIDString!
    $postId: UUIDString
    $influencerId: UUIDString
    $state: String
    $unreviewed: Boolean
    $reported: Boolean
    $mine: Boolean
  ) {
    gig(id: $gigId) {
      id
      created
      state
      canBeReported
      isPosted
      isVerified
      insight {
        id
      }
      submission {
        created
        caption
        mentions
        hashtags
        media {
          ...mediaResultFields
        }
      }
      instagramContent {
        __typename
        id
        created
        followers
        modified
        posted
        media {
          ...mediaResultFields
        }
        ... on InstagramPost {
          deleted
          link
          caption
          mentions
          hashtags
          comments
          likes
          shares
          videoViews
          plays
          scraped
          engagementRateByFollowers {
            formattedValue
          }
        }
        ... on InstagramStory {
          storyFrames {
            id
            media {
              ... on MediaInterface {
                id
                url
              }
              ... on Video {
                id
                thumbnail
              }
            }
          }
        }
      }
      tiktokPost {
        id
        ... on TiktokPost {
          __typename
          id
          link
          comments
          likes
          shares
          scraped
          videoViews
          videoDuration
          posted
          engagementByFollowers {
            formattedValue
          }
        }
      }
      youtubeVideo {
        id
        ... on YoutubeVideo {
          __typename
          id
          viewCount
          likeCount
          commentCount
          engagementRateBySubscribers
        }
      }
      reviewer {
        id
        fullName
      }
      approver {
        id
        fullName
      }
      offer {
        id
        state
        isPaid
        payable
        claimed
      }
      post {
        id
        postType
        campaign {
          id
          name
          advertiser {
            id
            name
            domain
            profilePicture
          }
          preApproval
          brandSafety
          reportToken
          submissionsToken
        }
      }
      influencer {
        id
        fullName
        profilePicture
        ...socialPlatformsFields
        targetRegion {
          id
          name
          country
          countryCode
        }
      }
      reportReason
      rejectReason
      reporter {
        id
        fullName
        profilePicture
        email
      }
    }
    gigPagination(
      id: $gigId
      postId: $postId
      influencerId: $influencerId
      state: $state
      unreviewed: $unreviewed
      reported: $reported
      mine: $mine
    ) {
      next
      previous
    }
    user: currentUser {
      id
      fullName
    }
    canAccessAllInfluencers: hasPermission(permission: "access_all_influencers")
  }
  ${Gig.mediaPreview}
  ${Influencer.socialPlatformsFragment}
`;

const ReviewMutation = gql`
  mutation ReviewGig($id: UUIDString!) {
    reviewGig(id: $id) {
      ok
      gig {
        id
        state
        reviewer {
          id
          fullName
        }
      }
    }
  }
`;

export default compose(
  withRouter,
  graphql(GigQuery, {
    props: ({data: {loading, user, gig, gigPagination, canAccessAllInfluencers}}) => ({
      loading,
      gig,
      user,
      canAccessAllInfluencers,
      nextGigId: gigPagination?.next,
      previousGigId: gigPagination?.previous,
    }),
    options: ({
      match: {
        params: {gigId, postId, influencerId, unreviewed, reported, mine},
      },
      location: {state},
    }) => ({
      variables: {
        gigId,
        postId,
        influencerId,
        state,
        unreviewed,
        reported,
        mine,
      },
    }),
  }),
  graphql(GigInstagramInsightsQuery, {
    props: ({data: {gig}}) => ({
      insightsGig: gig,
    }),
    options: ({
      match: {
        params: {gigId},
      },
    }) => ({
      variables: {
        gigId,
      },
    }),
  }),
  graphql(ReviewMutation, {
    props: ({ownProps: {user}, mutate}) => ({
      reviewGig: id =>
        mutate({
          variables: {id, user},
          optimisticResponse: {
            reviewGig: {
              __typename: 'ReviewGig',
              ok: true,
              gig: {
                __typename: 'Gig',
                id,
                state: 'reviewed',
                reviewer: {
                  __typename: 'User',
                  id: user.id,
                  fullName: user.fullName,
                },
              },
            },
          },
        }),
    }),
  })
)(CampaignGig);
