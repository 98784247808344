import React from 'react';
import {Box, Grid, Tooltip} from '@material-ui/core';

import useRequiresPermission from 'hooks/useRequiresPermission';
import {black40, purple, red} from 'consts/brand.integration';
import InfluencerProfileCard from 'components/Widgets/InfluencerProfileCard/InfluencerProfileCard';
import {Icon} from '@iconify/react';

interface Props {
  influencer: Influencer;
  insightStatus?: 'not_set' | 'submitted' | 'approved' | 'missing';
}

const InfluencerBox: React.FC<Props> = ({influencer, insightStatus}) => {
  const canAccessAllInfluencers = useRequiresPermission('access_all_influencers');

  const renderInsightStatus = () => {
    switch (insightStatus) {
      case 'not_set':
        return null;
      case 'submitted':
        return (
          <Tooltip title="Insight needs processing" placement="bottom" arrow>
            <Icon icon="material-symbols:star-half" fontSize={22} color={black40} />
          </Tooltip>
        );
      case 'approved':
        return (
          <Tooltip title="Insight approved" placement="bottom" arrow>
            <Icon icon="material-symbols:star" fontSize={22} color={purple} />
          </Tooltip>
        );
      case 'missing':
        return (
          <Tooltip title="Insight missing" placement="bottom" arrow>
            <Icon icon="mdi:star-border" fontSize={22} color={red} />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <Box margin={2}>
      <Grid container spacing={2}>
        <Grid item xs>
          <InfluencerProfileCard influencer={influencer} showFollowers />
        </Grid>
        <Grid item>{canAccessAllInfluencers && renderInsightStatus()}</Grid>
      </Grid>
    </Box>
  );
};

export default InfluencerBox;
