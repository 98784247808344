import React from 'react';
import {useMutation, gql} from '@apollo/client';
import moment from 'moment';
import {Button} from '@material-ui/core';

import {Modal} from '../../../../../components/Widgets';
import {
  Form,
  FormListOfFilesInput,
  FormSubmitButton,
} from '../../../../../components/Widgets/Forms';
import {uploadMultipleMedia} from '../../../../../services/api';
import {Block} from '@material-ui/icons';

interface Props {
  gig: Gig;
  post: Post;
  idx: number;
  refetchQueries?: string[];
  disabled?: boolean;
}

type Model = {
  files: string[];
};

const SubmitInsightsModal: React.FC<Props> = ({gig, post, idx, refetchQueries, disabled}) => {
  const [submitInsights] = useMutation(SubmitInsightsMutation, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const onSubmit = async (model: Model) => {
    const {files} = model;

    // Upload the media
    const formData = new FormData();
    const keys: string[] = [];

    files.forEach((file, idx) => {
      const key = `file${idx}`;
      formData.append(key, file);
      keys.push(key);
    });

    const result = await uploadMultipleMedia(formData, keys);
    const insightUrls = result.map(({data}) => data.urls[0]);

    // Submit the whole thing
    submitInsights({
      variables: {
        gigId: gig.id,
        insightUrls,
      },
    });
  };

  const getPostDate = () => {
    const date = moment(post.opened);
    if (!date.isValid) {
      return '-';
    }
    return date.format('Do MMM YYYY');
  };

  return (
    <Modal
      id="submit-insights"
      modalToggler={
        <Button
          variant="outlined"
          color="primary"
          disabled={disabled}
          startIcon={disabled ? <Block /> : null}
        >
          Submit Insights for influencer
        </Button>
      }
      title={`Post ${idx}. ${
        post.postType![0].toUpperCase() + post.postType?.substring(1)
      } - ${getPostDate()}`}
      showClose
    >
      <Form onSubmit={onSubmit}>
        <FormListOfFilesInput
          type="file"
          name="files"
          label="Media"
          required
          validators={(files: Array<File | undefined> | undefined): boolean => {
            if (!files) {
              return false;
            }
            return files.length > 0 && files.every(file => file !== undefined);
          }}
          description="The insights that should be uploaded for this particular post"
        />
        <FormSubmitButton text="Submit" gold block />
      </Form>
    </Modal>
  );
};

const SubmitInsightsMutation = gql`
  mutation SubmitInsightsMutation($gigId: UUIDString!, $insightUrls: [Url]!) {
    submitInsight(gigId: $gigId, insightUrls: $insightUrls) {
      ok
    }
  }
`;

export default SubmitInsightsModal;
