import React, {useState, useEffect, useRef, useMemo} from 'react';
import {useQuery, useApolloClient} from '@apollo/client';
import {Chip, Grid, Typography, makeStyles} from '@material-ui/core';

import ExportJsonToExcel from 'components/ExportJsonToExcel';
import {ApiError, LoaderDots, UnauthorizedAccess, NoCampaignsSelected} from 'components/Widgets';
import {ModalElement} from 'components/Widgets/Modal';

import {influencerSelect} from 'consts/dataApiProperties';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useCurrentUser from 'hooks/useCurrentUser';
import {useDataServiceApi} from 'hooks/useDataServiceApi';
import type {
  CampaignPaidAdsPerformanceResponse,
  CampaignPaidPerformanceResponse,
  CampaignPerformanceByGigResponse,
  CampaignPerformanceByInfluencerResponse,
  CampaignPerformanceHistoricalDataResponse,
  CampaignPerformanceResponse,
  CampaignPlatformPerformanceResponse,
  CampaignInstagramPaidAdPerformanceResponse,
  CampaignTikTokPostPaidAdPerformanceResponse,
} from 'hooks/useDataServiceApi/types';
import PaidPlatformMetrics from 'scenes/Campaign/components/CampaignPerformance/PaidPlatformData/PaidPlatformMetrics';

import {AdvertiserCampaignQuery, GigQuery} from '../AdvertiserPerformance/graphqlQuery';
import AdvertiserTabs from '../AdvertiserTabs';
import {Match} from '../AdvertiserMembers/types';
import {
  CampaignModal,
  CampaignMetrics,
  PlatformMetrics,
  InfluencerMetrics,
  GigMetrics,
  PaidMediaMetrics,
} from '../components';
import {
  aggregateMetrics,
  aggregatePlatformMetrics,
  influencerMetrics,
  postMetrics,
  aggregatePaidMetrics,
  aggregateHistoricalData,
  aggregatePaidPlatformData,
} from './helper';
import {useSelectedCampaignsLocationState} from '../hooks/useSelectedCampaignsLocationState';
import PaidAdvertMetrics from '../components/PaidAdvertMetrics';

interface Props {
  match: Match;
}

const AdvertiserCampaignAggregate: React.FC<Props> = ({match: {params}}) => {
  const currentUser = useCurrentUser();
  const classes = useStyles();
  const client = useApolloClient();

  const {
    selected: campaignIdsArray,
    add: addToSelectedCampaigns,
    remove: removeFromSelectedCampaigns,
  } = useSelectedCampaignsLocationState();
  const campaignIds = campaignIdsArray.join(',');
  const modal = useRef<ModalElement>(null);
  const {showCampaignPerformanceAggregate} = useFeatureFlags();
  const title = 'Campaign Performance';

  const {data} = useQuery<{advertiser: Advertiser}>(AdvertiserCampaignQuery, {
    variables: {domain: params.advertiser},
  });
  const advertiser = data?.advertiser;
  const campaigns = advertiser && (advertiser.campaigns as Campaign[]);
  const selectedCampaigns = (campaigns ?? []).filter(campaign =>
    campaignIdsArray.includes(String(campaign.id))
  ) as Campaign[];

  const unselectedCampaigns = (campaigns ?? []).filter(
    campaign => !selectedCampaigns?.some(selected => selected.id === campaign.id)
  );

  const {error: summaryError, fetchCampaignPerformanceData} = useDataServiceApi();
  const {error: historyError, fetchCampaignPerformanceHistoricalData} = useDataServiceApi();
  const {error: platformError, fetchCampaignPerformanceByPlatformData} = useDataServiceApi();
  const {error: creatorError, fetchCampaignPerformanceByInfluencerData} = useDataServiceApi();
  const {error: gigError, fetchCampaignPerformanceByGigData} = useDataServiceApi();
  const {error: paidError, fetchCampaignPaidPerformanceByCampaign} = useDataServiceApi();
  const {error: paidAdsError, fetchCampaignPaidPerformanceByAds} = useDataServiceApi();
  const {error: igAdsError, fetchCampaignPaidPerformanceByInstagramAds} = useDataServiceApi();
  const {error: tikTokAdsError, fetchCampaignPaidPerformanceByTikTokAds} = useDataServiceApi();

  const [loading, setLoading] = useState(true);
  const [summaryData, setSummaryData] = useState<CampaignPerformanceResponse | null>(null);
  const [historicalData, setHistoricalData] =
    useState<CampaignPerformanceHistoricalDataResponse | null>(null);
  const [platformData, setPlatformData] = useState<CampaignPlatformPerformanceResponse | null>(
    null
  );
  const [influencerData, setInfluencerData] =
    useState<CampaignPerformanceByInfluencerResponse | null>(null);
  const [gigData, setGigData] = useState<CampaignPerformanceByGigResponse | null>(null);
  const [paidData, setPaidData] = useState<CampaignPaidPerformanceResponse | null>(null);
  const [paidAds, setPaidAds] = useState<CampaignPaidAdsPerformanceResponse | null>(null);
  const [gigContent, setGigContent] = useState([]);
  const [instagramAds, setInstagramAds] =
    useState<CampaignInstagramPaidAdPerformanceResponse | null>(null);
  const [tiktokAds, setTiktokAds] = useState<CampaignTikTokPostPaidAdPerformanceResponse | null>(
    null
  );

  const error =
    summaryError ||
    historyError ||
    platformError ||
    creatorError ||
    gigError ||
    paidError ||
    paidAdsError ||
    igAdsError ||
    tikTokAdsError;

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser?.takumiDataApiToken && campaignIds) {
        const data = await fetchCampaignPerformanceData({
          apiToken: currentUser?.takumiDataApiToken || '',
          campaignIds: campaignIds,
        });
        setSummaryData(data ?? null);
        const historyData = await fetchCampaignPerformanceHistoricalData({
          apiToken: currentUser?.takumiDataApiToken || '',
          campaignIds: campaignIds,
          orderBy: 'snapshot_date',
        });
        setHistoricalData(historyData ?? null);
        const platformData = await fetchCampaignPerformanceByPlatformData({
          apiToken: currentUser?.takumiDataApiToken || '',
          campaignIds: campaignIds,
        });
        setPlatformData(platformData ?? null);
        const influencerData = await fetchCampaignPerformanceByInfluencerData({
          apiToken: currentUser?.takumiDataApiToken || '',
          campaignIds: campaignIds,
          select: influencerSelect,
        });
        setInfluencerData(influencerData ?? null);
        const gigData = await fetchCampaignPerformanceByGigData({
          apiToken: currentUser?.takumiDataApiToken || '',
          campaignIds: campaignIds,
          select: influencerSelect,
        });
        setGigData(gigData ?? null);
        const paidData = await fetchCampaignPaidPerformanceByCampaign({
          apiToken: currentUser?.takumiDataApiToken || '',
          campaignIds: campaignIds,
        });
        setPaidData(paidData ?? null);
        const paidAds = await fetchCampaignPaidPerformanceByAds({
          apiToken: currentUser?.takumiDataApiToken || '',
          campaignIds: campaignIds,
        });
        setPaidAds(paidAds ?? null);
        const instagramAds = await fetchCampaignPaidPerformanceByInstagramAds({
          apiToken: currentUser?.takumiDataApiToken || '',
          campaignIds: campaignIds,
        });
        setInstagramAds(instagramAds ?? null);
        const tiktokAds = await fetchCampaignPaidPerformanceByTikTokAds({
          apiToken: currentUser?.takumiDataApiToken || '',
          campaignIds: campaignIds,
        });
        setTiktokAds(tiktokAds ?? null);
      }
      setLoading(false);
    };
    fetchData();
  }, [currentUser?.takumiDataApiToken, campaignIds]);

  useEffect(() => {
    const fetchGigs = async id => {
      const {data} = await client.query({
        query: GigQuery,
        variables: {campaignId: id},
      });
      return data ? data.gigs.edges : [];
    };

    const fetchAllGigs = async () => {
      const allPromises = campaignIdsArray.map(id => fetchGigs(id));
      const allResults = await Promise.all(allPromises);
      setGigContent(allResults.flat());
    };

    fetchAllGigs();
  }, [campaignIds]);

  const aggregatedMetrics = summaryData && aggregateMetrics(summaryData);
  const performanceMetrics = aggregatedMetrics && [aggregatedMetrics];
  const aggregatedPlatformMetrics = platformData && aggregatePlatformMetrics(platformData);
  const platformMetrics = aggregatedPlatformMetrics && aggregatedPlatformMetrics;
  const historicalMetrics = historicalData && aggregateHistoricalData(historicalData);
  const creatorMetrics = influencerData && influencerMetrics(influencerData);
  const gigMetrics = gigData && postMetrics(gigData);
  const aggregatedPaidMetrics = paidData && aggregatePaidMetrics(paidData);
  const paidMetrics = aggregatedPaidMetrics && [aggregatedPaidMetrics];
  const hasPaidMetrics = !!paidData?.length;
  const hasPaidAds = !!paidAds?.length;

  const brandData = {
    brand: advertiser?.name,
    campaign: 'Aggregated Campaigns',
  };

  const apiData = useMemo(
    () => ({
      Summary: performanceMetrics,
      Platforms: platformMetrics,
      Creators: creatorMetrics,
      Gigs: gigMetrics,
      'Paid Media Summary': paidMetrics,
    }),
    [performanceMetrics, platformMetrics, creatorMetrics, gigMetrics, paidMetrics]
  );

  const hasExportData = Object.values(apiData).every(data => data != null);

  if (loading) {
    return <LoaderDots className={classes.spacing} />;
  }

  if (!showCampaignPerformanceAggregate) {
    return <UnauthorizedAccess />;
  }

  if (error) {
    return (
      <>
        <AdvertiserTabs params={params} />
        <ApiError title="Brand Performance" classes={classes} />
      </>
    );
  }

  return (
    <>
      <AdvertiserTabs params={params} />

      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} md={7} lg={8}>
          <Typography variant="h3">{title}</Typography>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          xs={12}
          md={5}
          lg={4}
          alignItems="center"
          className={classes.toolbar}
        >
          <Grid item>
            <CampaignModal
              campaigns={unselectedCampaigns}
              handleModalSubmit={selected => {
                addToSelectedCampaigns(selected);
                modal.current?.close();
              }}
              forwardedRef={modal}
            />
          </Grid>
          <Grid item>
            <ExportJsonToExcel
              apiData={apiData}
              multipleCampaigns={selectedCampaigns}
              brandData={brandData}
              disabled={!hasExportData}
            />
          </Grid>
        </Grid>
        <Grid item>
          {selectedCampaigns?.map((campaign, i) => (
            <Chip
              key={i}
              label={campaign.name!}
              aria-label={campaign.name!}
              className={classes.chips}
              onDelete={() => removeFromSelectedCampaigns(String(campaign.id))}
            />
          ))}
        </Grid>
      </Grid>

      {campaignIdsArray.length === 0 && <NoCampaignsSelected />}

      {performanceMetrics && (
        <CampaignMetrics metrics={performanceMetrics} historicalMetrics={historicalMetrics} />
      )}

      {/* NOTE: > 1 because if there's only a single platform then it's no different to summary metrics */}
      {platformMetrics && platformMetrics.length > 1 && (
        <PlatformMetrics metrics={platformMetrics} />
      )}

      {creatorMetrics && <InfluencerMetrics metrics={creatorMetrics} />}

      {gigMetrics && gigContent && <GigMetrics gigMetrics={gigMetrics} gigContent={gigContent} />}

      {hasPaidMetrics && <PaidMediaMetrics metrics={paidMetrics} />}

      {/* NOTE: > 1 because if there's only a single platform then it's no different to summary metrics */}
      {instagramAds && instagramAds.length > 1 && tiktokAds && tiktokAds.length > 1 && (
        <PaidPlatformMetrics
          title="Paid Platform Metrics"
          data={aggregatePaidPlatformData(instagramAds, tiktokAds)}
        />
      )}

      {hasPaidAds && (
        <PaidAdvertMetrics
          title="Paid Adverts"
          gigs={gigContent.map(gig => gig?.node!) ?? []}
          adverts={paidAds}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  spacing: {
    margin: '10px auto 20px',
  },
  chips: {
    margin: '5px',
  },
  toolbar: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));

export default AdvertiserCampaignAggregate;
