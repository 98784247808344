import React, {useRef} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {InputAdornment, makeStyles, TextField, Typography} from '@material-ui/core';

import {Button, LoaderDots, RadioButton} from 'components/Widgets';
import Modal, {ModalElement} from 'components/Widgets/Modal';
import TextLink from 'components/Widgets/TextLink';
import {Campaign} from 'services/fragments';

interface Props {
  campaign: Campaign;
}

const rewardModelLabels = {
  assets: 'Creator count',
  reach: 'Follower reach',
  engagement: 'Number of engagements',
  impressions: 'Number of impressions',
};

const LaunchCampaign: React.FC<Props> = ({campaign: {id}}) => {
  const classes = useStyles();
  const modal = useRef<ModalElement>(null);
  const launchCampaignQueryResult = useQuery(LaunchCampaignQuery, {variables: {campaignId: id}});
  const [launchCampaign, {loading}] = useMutation(LaunchCampaignMutation, {
    variables: {id},
    optimisticResponse: {
      launchCampaign: {
        ok: true,
        campaign: {
          __typename: 'Campaign',
          id: id,
          state: 'launched',
        },
      },
    },
  });

  return (
    <Modal
      ref={modal}
      id="launch-campaign-confirmation"
      title="Launch Campaign"
      modalToggler={<Button text="Launch Campaign" />}
      fullWidth
      maxWidth="sm"
      showClose
      showSubmit
      submitTitle="Launch Campaign"
      loading={loading}
      disabled={launchCampaignQueryResult.loading}
      onSubmit={launchCampaign}
    >
      {(() => {
        if (launchCampaignQueryResult.loading) {
          return <LoaderDots />;
        }

        const {campaign} = launchCampaignQueryResult.data;

        return (
          <>
            <Typography gutterBottom>
              Please confirm the following campaign details before launch. These settings cannot be
              modified after the campaign has launched.
            </Typography>

            <TextField
              label="List Price"
              type="text"
              name="listPrice"
              id="list-price-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{campaign.listPrice?.symbol}</InputAdornment>
                ),
              }}
              value={campaign.listPrice?.formattedValue.replace(campaign.listPrice?.symbol, '')}
              disabled={true}
              fullWidth
              variant="outlined"
              className={classes.input}
            />

            <TextField
              label="Payment Terms"
              name="paymentTerms"
              id="payment-terms-input"
              value={campaign.paymentTerms}
              disabled={true}
              fullWidth
              variant="outlined"
              className={classes.input}
            />

            <TextField
              label={rewardModelLabels[campaign.rewardModel!]}
              fullWidth
              type="number"
              name="target-overall"
              id="reward-model-units-input"
              value={campaign.target.targetOverall}
              disabled={true}
              variant="outlined"
              className={classes.input}
            />

            {campaign.hasCustomReward ? (
              <TextField
                fullWidth
                label={
                  campaign.rewardModel === 'assets'
                    ? 'Reward per influencer'
                    : 'Reward per 1000 followers'
                }
                type="number"
                name="customRewardUnits"
                id="custom-reward-input"
                value={campaign.customRewardUnits / 100}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{campaign.listPrice?.symbol}</InputAdornment>
                  ),
                }}
                disabled={true}
                variant="outlined"
                className={classes.input}
              />
            ) : (
              <RadioButton
                checked={!campaign.hasCustomReward}
                name="hasCustomReward"
                label="Use recommended rewards"
                description="Rewards are calculated based on the platform margin"
                disabled={true}
              />
            )}

            <Typography gutterBottom>
              To adjust any of these options, visit the{' '}
              <TextLink to={`/brands/${campaign.advertiser.domain}/${campaign.id}/setup`}>
                Campaign Setup
              </TextLink>{' '}
              tab.
            </Typography>
          </>
        );
      })()}
    </Modal>
  );
};

const useStyles = makeStyles({
  input: {
    marginTop: '16px',
    marginBottom: '8px',
  },
});

const LaunchCampaignQuery = gql`
  query LaunchCampaignQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      paymentTerms
      rewardModel
      ...targetFields
      hasCustomReward
      customRewardUnits
      listPrice {
        symbol
        formattedValue
      }
      advertiser {
        id
        domain
      }
    }
  }
  ${Campaign.targetFragment}
`;

const LaunchCampaignMutation = gql`
  mutation launchCampaign($id: UUIDString!) {
    launchCampaign(id: $id) {
      ok
      campaign {
        id
        state
      }
    }
  }
`;

export default LaunchCampaign;
