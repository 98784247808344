import React, {useState, useEffect} from 'react';
import {Divider, Grid, Typography, makeStyles} from '@material-ui/core';
import {omit} from 'lodash';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {
  ApiError,
  NoPerformanceData,
  LoaderDots,
  StoryEngagements,
} from '../../../../../components/Widgets';
import {useDataServiceApi} from '../../../../../hooks/useDataServiceApi';
import useFeatureFlags from '../../../../../hooks/useFeatureFlags';
import SummaryMetrics from './SummaryMetrics';
import SummaryChart from './SummaryChart';
import {DataExport, HistoricalData, PerformanceData} from '../types';
import {performanceSettingsValues} from '../helper';

interface Props {
  campaign?: Campaign;
  apiData?: (data: DataExport) => void;
  historicalData: Array<Partial<HistoricalData[number]>>;
  setHistoricalData: React.Dispatch<React.SetStateAction<Array<Partial<HistoricalData[number]>>>>;
}

const CampaignPerformanceSummary: React.FC<Props> = ({
  campaign,
  apiData,
  historicalData,
  setHistoricalData,
}) => {
  const user = useCurrentUser();
  const classes = useStyles();
  const {error, loading, fetchCampaignPerformanceData} = useDataServiceApi();
  const [resourceData, setResourceData] = useState<PerformanceData[] | null>(null);
  const {loading: historicalDataLoading, fetchCampaignPerformanceHistoricalData} =
    useDataServiceApi();
  const title = 'Summary';
  const {showCampaignPerformanceChart} = useFeatureFlags();

  useEffect(() => {
    const fetchData = async () => {
      if (user?.takumiDataApiToken && campaign?.id) {
        const data = await fetchCampaignPerformanceData({
          apiToken: user?.takumiDataApiToken || '',
          campaignId: campaign?.id || '',
        });
        setResourceData(data);
        const historyData =
          (await fetchCampaignPerformanceHistoricalData<HistoricalData>({
            apiToken: user?.takumiDataApiToken || '',
            campaignId: campaign?.id || '',
            orderBy: 'snapshot_date',
          })) ?? [];
        const fieldsToOmit = performanceSettingsValues.organic.filter(
          value => !campaign.organicPerformanceMetrics?.includes(value)
        );
        const filteredHistoricalData: Array<Partial<HistoricalData[number]>> = !campaign
          .organicPerformanceMetrics?.length
          ? historyData
          : historyData.map(data => omit(data, fieldsToOmit));
        setHistoricalData(filteredHistoricalData);
        apiData(data);
      }
    };
    fetchData();
  }, [
    user?.takumiDataApiToken,
    campaign?.id,
    fetchCampaignPerformanceData,
    fetchCampaignPerformanceHistoricalData,
    setHistoricalData,
  ]);

  const modelName = 'campaign_performance';
  const apiResponse = resourceData?.[0];
  const summaryMetrics = apiResponse
    ? [
        {
          influencer_count: apiResponse.influencer_count,
          digital_content_count: apiResponse.digital_content_count,
          impression_total: apiResponse.impression_total,
          reach_total: apiResponse.reach_total,
          engagement_total: apiResponse.engagement_total,
          engagement_rate_by_impressions: apiResponse.engagement_rate_by_impressions,
          impression_change: apiResponse.impression_change,
          reach_change: apiResponse.reach_change,
          engagement_change: apiResponse.engagement_change,
          engagement_rate_by_impressions_percent_change_average:
            apiResponse.engagement_rate_by_impressions_percent_change_average,
        },
      ]
    : [];

  const [hasStories, setHasStories] = useState(false);
  useEffect(() => {
    const stories = campaign?.posts?.some(post => post?.postType === 'story') || false;
    setHasStories(stories);
  }, [campaign, campaign?.organicPerformanceMetrics]);

  if (loading) {
    return <LoaderDots className={classes.spacing} />;
  }

  if (error) {
    return <ApiError title={title} classes={classes} />;
  }

  return (
    <>
      {hasStories && summaryMetrics?.length > 0 && <StoryEngagements />}
      <Typography variant="h4" className={classes.header}>
        {title}
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container spacing={3} aria-label={`${title} Performance Metrics`}>
        {summaryMetrics?.length > 0 ? (
          <>
            <SummaryMetrics
              summaryMetrics={summaryMetrics}
              modelName={modelName}
              enabledMetrics={(campaign?.organicPerformanceMetrics ?? []) as string[]}
            />
            {historicalData?.length > 0 && showCampaignPerformanceChart && (
              <SummaryChart historicalData={historicalData} loading={historicalDataLoading} />
            )}
          </>
        ) : (
          <NoPerformanceData title="Summary performance metrics" />
        )}
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default CampaignPerformanceSummary;
