import compose from 'lodash/flowRight';
import React, {useState} from 'react';
import {Typography, Divider} from '@material-ui/core';
import {Icon} from '@iconify/react';
import {withRouter} from 'react-router-dom';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

import {Table, DownloadCSV, RequiresPermission, InfiniteScroll, Checkbox} from 'components/Widgets';

import {purple} from 'consts/brand.integration';
import {useLocalStorage} from 'consts/hooks';

import {EmailList} from '..';
import {PaginatedQuery} from '../../../../../services/graphql';
import {PlatformsConfig} from 'consts/platforms';

interface Props {
  query: Record<string, any>;
  variables: Record<string, any>;
  columns: Array<any>;
  campaign?: Campaign;
  params: {campaign: string};
  filters: string[];
  extraRow: (offer: Offer) => React.ReactNode;
}

const ListOffers: React.FC<Props> = ({query, variables, columns, campaign, extraRow}) => {
  const [showExtraRow] = useLocalStorage('showExtraRow', false);
  const [participantsBySocialPlatform, setParticipantsBySocialPlatform] = useState(false);

  const SocialPlatforms = () => {
    return (
      <Checkbox
        label="Split by Social Platforms"
        checked={participantsBySocialPlatform}
        onChange={() => setParticipantsBySocialPlatform(!participantsBySocialPlatform)}
      />
    );
  };

  return (
    <PaginatedQuery query={query} variables={variables}>
      {({loading, data, loadMore}) => {
        const offers = data?.offers?.edges.map(edge => edge.node) || [];
        const instagramInfluencers = offers
          .filter(offer => offer.influencer?.instagramAccount)
          .map(offer => ({
            ...offer,
            influencer: {
              ...offer.influencer,
              tiktokAccount: undefined,
              youtubeChannel: undefined,
              twitchChannel: undefined,
            },
          }));
        const tiktokInfluencers = offers
          .filter(offer => offer.influencer?.tiktokAccount)
          .map(offer => ({
            ...offer,
            influencer: {
              ...offer.influencer,
              instagramAccount: undefined,
              youtubeChannel: undefined,
              twitchChannel: undefined,
            },
          }));
        const youtubeInfluencers = offers
          .filter(offer => offer.influencer?.youtubeChannel)
          .map(offer => ({
            ...offer,
            influencer: {
              ...offer.influencer,
              instagramAccount: undefined,
              tiktokAccount: undefined,
              twitchChannel: undefined,
            },
          }));
        const twitchInfluencers = offers
          .filter(offer => offer.influencer?.twitchChannel)
          .map(offer => ({
            ...offer,
            influencer: {
              ...offer.influencer,
              instagramAccount: undefined,
              tiktokAccount: undefined,
              youtubeChannel: undefined,
            },
          }));
        const emails = offers.map(o => o.influencer.email);

        const InfluencerTable = ({title, icon, offers}) => {
          return (
            <>
              {title && (
                <>
                  <Typography component="h2" variant="h3" style={{color: purple}} paragraph>
                    <Icon icon={icon} width={20} color={purple} />
                    {` ${title}`}
                  </Typography>
                  <Divider style={{backgroundColor: purple}} />
                </>
              )}
              <Table
                columns={columns}
                extraRow={showExtraRow && extraRow}
                data={offers}
                loading={loading}
                emptyMessage="No influencers."
              />
            </>
          );
        };

        return (
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={data?.offers?.pageInfo?.hasNextPage || false}
            skipLoading
          >
            <SocialPlatforms />
            <div>
              {!participantsBySocialPlatform ? (
                <InfluencerTable offers={offers} id="influencer-table" />
              ) : (
                <>
                  <InfluencerTable
                    title={`${PlatformsConfig.instagram.name}`}
                    icon={`${PlatformsConfig.instagram.iconMono}`}
                    offers={instagramInfluencers}
                    id="influencer-table"
                  />
                  <InfluencerTable
                    title={`${PlatformsConfig.tiktok.name}`}
                    icon={`${PlatformsConfig.tiktok.iconMono}`}
                    offers={tiktokInfluencers}
                    id="influencer-table"
                  />
                  <InfluencerTable
                    title={`${PlatformsConfig.youtube.name}`}
                    icon={`${PlatformsConfig.youtube.iconMono}`}
                    offers={youtubeInfluencers}
                    id="influencer-table"
                  />
                  <InfluencerTable
                    title={`${PlatformsConfig.twitch.name}`}
                    icon={`${PlatformsConfig.twitch.iconMono}`}
                    offers={twitchInfluencers}
                    id="influencer-table"
                  />
                </>
              )}
              <RequiresPermission permission="manage_influencers">
                {emails && emails.length > 0 && <EmailList title="Emails" emails={emails} />}

                {offers && (
                  <DownloadCSV
                    data={offers}
                    filename={`${campaign.name} - addresses.csv`}
                    text="Download addresses as CSV"
                    fields={[
                      'influencer.id',
                      'influencer.fullName',
                      'influencer.address.name',
                      'influencer.address.address1',
                      'influencer.address.address2',
                      'influencer.address.city',
                      'influencer.address.postalCode',
                      'influencer.address.state',
                      'influencer.address.phonenumber',
                      'trackingCode',
                      'addressMissing',
                    ]}
                  />
                )}
              </RequiresPermission>
            </div>
          </InfiniteScroll>
        );
      }}
    </PaginatedQuery>
  );
};

const CampaignParticipantsCampaignQuery = gql`
  query CampaignParticipantsCampaignQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      marketSlug
      applyFirst
    }
  }
`;

export default compose(
  withRouter,
  graphql(CampaignParticipantsCampaignQuery, {
    options: ({
      match: {
        params: {campaign},
      },
    }) => ({
      variables: {
        campaignId: campaign,
      },
    }),
    props: ({data: {campaign, loading}}) => ({
      loading,
      campaign,
    }),
  })
)(ListOffers);
