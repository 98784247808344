import {
  Box,
  Button,
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Typography,
} from '@material-ui/core';
import {Modal} from 'components/Widgets';
import {ModalElement} from 'components/Widgets/Modal';
import {useFormik} from 'formik';
import * as yup from 'yup';
import React, {useEffect, useRef, useState} from 'react';
import {gql, useLazyQuery} from '@apollo/client';
import {Alert} from '@material-ui/lab';
import {Link} from 'react-router-dom';
import InfluencerCard from './InfluencerCard/InfluencerCard';

const AlternativeInfluencerSearch: React.FC = () => {
  const [searchUsers, {loading, error, data}] = useLazyQuery(SearchUsersQuery);
  const [profilesFound, setProfilesFound] = useState([]);
  const modal = useRef<ModalElement>(null);

  useEffect(() => {
    if (data) {
      setProfilesFound(data?.users.edges.map((edge: any) => edge.node.influencer));
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().email().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = () => {
    searchUsers({
      variables: {
        searchString: formik.values.email,
      },
    });
  };

  const onClose = () => {
    formik.resetForm();
    setProfilesFound([]);
    modal.current?.close();
  };

  return (
    <Modal
      ref={modal}
      id="search-influencer-profile"
      title="Alternative Influencer Search"
      modalToggler={
        <Button variant="outlined" color="primary" size="large">
          <Typography variant="button">Alternative Influencer Search</Typography>
        </Button>
      }
      submitTitle="Search"
      fullWidth
      maxWidth="xs"
      showClose
      onSubmit={formik.submitForm}
      onClose={onClose}
      loading={loading}
    >
      <DialogContentText>
        <Typography variant="body2" color="primary">
          Unfortunately sometimes it can take more then a few minutes to process recently created
          influencer profiles.
        </Typography>
        <br />
        <Typography variant="body2" color="primary">
          If you are unable to find the profile you are looking for, try searching for them using
          their email address below.
        </Typography>
        <br />
        <FormControl fullWidth>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            id="email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            required
          />
          <FormHelperText error={formik.errors.email?.length}>
            {formik.errors.email?.length ? 'is a required field' : ''}
          </FormHelperText>
        </FormControl>
        {error && (
          <>
            <br />
            <br />
            <Alert severity="error">{error?.message}</Alert>
          </>
        )}
        {profilesFound.length > 0 && (
          <Box marginTop="1rem" padding="2rem 2rem 0">
            <Grid container spacing={4}>
              {profilesFound.map((influencer: Influencer) => (
                <Grid item xs key={influencer.id}>
                  <Link
                    key={influencer.id}
                    to={`/influencers/${influencer.id}/${
                      influencer.isSignedUp ? 'gigs' : 'settings'
                    }`}
                    target="_blank"
                  >
                    <InfluencerCard influencer={influencer} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </DialogContentText>
    </Modal>
  );
};

const SearchUsersQuery = gql`
  query SearchUsersQuery($searchString: String) {
    users(search: $searchString, includeInfluencers: true) {
      edges {
        node {
          id
          influencer {
            __typename
            id
            fullName
            profilePicture
            state
            isSignedUp
            birthday
            gender
            targetRegion {
              id
              name
              country
              countryCode
            }
            instagramAccount {
              id
              followers
              username
            }
            tiktokAccount {
              id
              followers
              username
            }
            youtubeChannel {
              id
              subscriberCount
            }
            twitchChannel {
              id
              followers
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      count
    }
  }
`;

export default AlternativeInfluencerSearch;
