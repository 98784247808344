import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';

import type {CampaignPaidAdsPerformanceResponse} from 'hooks/useDataServiceApi/types';
import PaidAdvertMetrics from 'scenes/Advertiser/components/PaidAdvertMetrics';

import {ApiError, LoaderDots} from '../../../../../components/Widgets';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {useDataServiceApi} from '../../../../../hooks/useDataServiceApi';
import type {DataExport} from '../types';

interface Props {
  campaign?: Campaign;
  gigs?: GigConnection;
  apiData: (data: DataExport) => void;
}

const CampaignPaidAdvertPerformance: React.FC<Props> = ({
  campaign,
  gigs: gigConnection,
  apiData,
}) => {
  const user = useCurrentUser();
  const classes = useStyles();
  const {error, fetchCampaignPaidPerformanceByAds} = useDataServiceApi();
  const [loading, setLoading] = useState(true);
  const [adverts, setAdverts] = useState<CampaignPaidAdsPerformanceResponse>([]);
  const title = 'Adverts';
  const userToken = user?.takumiDataApiToken;
  const campaignId = campaign?.id;

  useEffect(() => {
    const fetchData = async () => {
      if (userToken && campaignId) {
        setLoading(true);
        const data =
          (await fetchCampaignPaidPerformanceByAds({
            apiToken: userToken || '',
            campaignId: campaignId || '',
          })) ?? [];
        setAdverts(data);
        setLoading(false);
        apiData(data);
      }
    };
    fetchData();
  }, [userToken, campaignId, fetchCampaignPaidPerformanceByAds]);

  const gigs = gigConnection?.edges.map(gig => gig?.node!) ?? [];
  const hasAdContent = gigs.length > 0 && adverts.length > 0;

  if (error) {
    return <ApiError title={title} classes={classes} />;
  }

  if (loading) {
    return <LoaderDots className={classes.spacing} />;
  }

  if (!hasAdContent) {
    return null;
  }

  return <PaidAdvertMetrics title={title} gigs={gigs} adverts={adverts} />;
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default CampaignPaidAdvertPerformance;
