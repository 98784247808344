import React, {useEffect, useState} from 'react';

import NotificationService from '../../../../../services/notifications';
import useCurrentUser from 'hooks/useCurrentUser';
import {UserRoles} from 'consts/roles';
import NotificationBarComponent from './NotificationBarComponent';

type NotificationType = 'error' | 'warning' | 'info' | 'success' | string;
type Notification = {
  message: string;
  type: NotificationType;
  timeout?: number;
};

const TAKUMI_HELP_URL = 'https://help.takumi.com/';
const TAKUMI_SUPPORT_URL = 'https://takumihq.atlassian.net/servicedesk/customer/portal/2';

const NotificationBar: React.FC = () => {
  const currentUser = useCurrentUser();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState<Notification | null>(null);

  const onCloseHandler = () => setOpen(false);

  const isTakumiUser = currentUser && currentUser.roleName !== UserRoles.Advertiser;

  // makes for a better exit animation
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!open) {
      timeout = setTimeout(() => setNotification(null), 1000);
    }
    return () => clearTimeout(timeout);
  }, [open]);

  useEffect(() => {
    const subscription = NotificationService.subscribe(({message, type, timeout}) => {
      setNotification({message, type, timeout});
      setOpen(true);
    });

    return () => {
      if (subscription) {
        NotificationService.unsubscribe(subscription);
      }
    };
  }, []);

  return (
    <NotificationBarComponent
      message={notification?.message ?? ''}
      type={notification?.type ?? 'info'}
      timeout={notification?.timeout}
      showHelpdeskLink={isTakumiUser ?? undefined}
      open={open}
      onClose={onCloseHandler}
    />
  );
};

export default NotificationBar;
