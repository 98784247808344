import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';

import type {
  CampaignInstagramPaidAdPerformanceResponse,
  CampaignTikTokPostPaidAdPerformanceResponse,
} from 'hooks/useDataServiceApi/types';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {ApiError, LoaderDots} from '../../../../../components/Widgets';
import {useDataServiceApi} from '../../../../../hooks/useDataServiceApi';
import {DataExport} from '../types';
import {filterMetrics} from './helper';
import PaidPlatformMetrics from './PaidPlatformMetrics';

interface Props {
  campaign?: Campaign;
  apiData: (type: string, data: DataExport) => void;
}

const CampaignPaidPerformanceByPlatform: React.FC<Props> = ({campaign, apiData}) => {
  const user = useCurrentUser();
  const classes = useStyles();
  const {
    error: tiktokError,
    loading: tiktokLoading,
    fetchCampaignPaidPerformanceByTikTokAds,
  } = useDataServiceApi();
  const {
    error: instagramError,
    loading: instagramLoading,
    fetchCampaignPaidPerformanceByInstagramAds,
  } = useDataServiceApi();
  const [tiktokAds, setTiktokAds] = useState<CampaignTikTokPostPaidAdPerformanceResponse | null>(
    null
  );
  const [instagramAds, setInstagramAds] =
    useState<CampaignInstagramPaidAdPerformanceResponse | null>(null);
  const title = 'Social Platforms';
  const enabledMetrics = (campaign?.paidPerformanceMetrics ?? []) as string[];

  useEffect(() => {
    const fetchData = async () => {
      if (user?.takumiDataApiToken && campaign?.id) {
        const tiktokData = await fetchCampaignPaidPerformanceByTikTokAds({
          apiToken: user?.takumiDataApiToken || '',
          campaignId: campaign?.id || '',
        });
        setTiktokAds(tiktokData ?? null);
        const instaData = await fetchCampaignPaidPerformanceByInstagramAds({
          apiToken: user?.takumiDataApiToken || '',
          campaignId: campaign?.id || '',
        });
        setInstagramAds(instaData ?? null);
        if (instaData && instaData.length > 0) {
          apiData('Paid Media (Instagram)', Array.isArray(instaData) ? instaData : [instaData]);
        }
        if (tiktokData && tiktokData.length > 0) {
          apiData('Paid Media (TikTok)', Array.isArray(tiktokData) ? tiktokData : [tiktokData]);
        }
      }
    };
    fetchData();
  }, [
    user?.takumiDataApiToken,
    campaign?.id,
    fetchCampaignPaidPerformanceByTikTokAds,
    fetchCampaignPaidPerformanceByInstagramAds,
  ]);

  const instagramMetrics = filterMetrics(instagramAds, enabledMetrics, 'instagram');
  const tiktokMetrics = filterMetrics(tiktokAds, enabledMetrics, 'tiktok');

  const data = {
    instagram: instagramMetrics[0] || {},
    tiktok: tiktokMetrics[0] || {},
  };

  if (tiktokLoading || instagramLoading) {
    return <LoaderDots className={classes.spacing} />;
  }

  if (tiktokError || instagramError) {
    return <ApiError title={title} classes={classes} />;
  }

  if (instagramMetrics?.length > 0 && tiktokMetrics?.length > 0)
    return <PaidPlatformMetrics title={title} data={data} />;

  return null;
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default CampaignPaidPerformanceByPlatform;
