import React from 'react';
import {makeStyles} from '@material-ui/styles';

import ImgixImage from '../Imgix';
import Pill from '../Pill';
import MaterialIcon from '../../Icons/MaterialDesignIcon';
import Media from '../Media';
import TikTokPreview from '../TikTokPreview';
import Video from '../Video';
import {Gig} from 'services/fragments';

interface Props {
  media: MediaResult;
  width: number;
  height: number;
  isDeleted?: boolean;
  arePillsShown?: boolean;
  alt?: string;
}

interface RenderPreviewProps {
  media: MediaResult;
  width: number;
  height: number;
  alt: string;
}

const renderPreview: React.FC<RenderPreviewProps> = ({media, height, width, alt}) => {
  switch (media.__typename) {
    case 'TikTok':
      return <TikTokPreview url={media.url} width={width} height={height} />;
    case 'Image':
      return <ImgixImage src={media.url} width={width} height={height} alt={alt} />;
    case 'Video':
      if (media.thumbnail || media.transcoded) {
        return (
          <ImgixImage
            src={
              media.transcoded && media.previewThumbnailUrl
                ? media.previewThumbnailUrl
                : media.thumbnail
            }
            width={width}
            height={height}
            alt={alt}
          />
        );
      } else {
        return (
          <Video
            src={media.transcoded && media.previewUrl ? media.previewUrl : media.url}
            height={height}
            width={width}
          />
        );
      }
    case 'Gallery':
      return media.items[0] ? renderPreview({media: media.items[0], width, height, alt}) : null;
    default:
      return <></>;
  }
};

const MediaPreview: React.FC<Props> = ({
  media,
  height,
  width,
  isDeleted = false,
  arePillsShown = true,
  alt = '',
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>{renderPreview({media, width, height, alt})}</div>
      {arePillsShown && (
        <div className={classes.pills}>
          {isDeleted && <Pill red>Post deleted</Pill>}
          {media.__typename === 'Video' && (
            <Pill black>
              <MaterialIcon icon="videocam" size={16} />
            </Pill>
          )}
          {media.__typename === 'Gallery' && (
            <Pill black>
              1/
              {media.items?.length}
            </Pill>
          )}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  pills: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

MediaPreview.fragment = Gig.mediaPreview;

export default MediaPreview;
