import React, {useRef} from 'react';
import {Button, Typography} from '@material-ui/core';

import {ProfilePicture, LoaderDots} from 'components/Widgets';

import {useStyles} from './styles';
import {ENTER_KEY_CODE, WHITE_SPACE_KEY} from '../../../../../consts/variables';

interface Props {
  image?: string;
  label?: string;
  id?: string;
  labelId?: string;
  error?: string;
  pending: boolean;
  square?: boolean;
  size?: number;
  isReadOnly?: boolean;
  onUpload: (files: FileList | null) => void;
}

const ImageUploader: React.FC<Props> = ({
  image,
  label,
  pending,
  error,
  square,
  id,
  labelId,
  isReadOnly,
  size = 70,
  onUpload,
}) => {
  const classes = useStyles();
  const uploadInputRef = useRef(null);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if ((event.key === ENTER_KEY_CODE || event.key === WHITE_SPACE_KEY) && !event.shiftKey) {
      event.preventDefault();
      uploadInputRef.current?.click();
    }
  };

  return (
    <div className={classes.root}>
      <div>
        {pending && <LoaderDots className={classes.loading} />}
        {!pending && (
          <ProfilePicture square={square} className={classes.image} src={image} size={size} />
        )}
      </div>
      <div className={classes.uploadButtonWrapper}>
        <p className={classes.label}>{label}</p>
        {!isReadOnly && (
          <Button
            className={classes.button}
            variant="outlined"
            fullWidth
            type="submit"
            onKeyDown={handleKeyDown}
          >
            <input
              ref={uploadInputRef}
              id={id}
              aria-labelledby={labelId}
              type="file"
              className={classes.filePicker}
              name="files[]"
              accept="image/png, image/jpeg"
              onChange={event => event.target.files && onUpload(event.target.files)}
            />
            <Typography variant="button">Upload new picture</Typography>
          </Button>
        )}
      </div>
      {error && <p className={classes.error}>{error}</p>}
    </div>
  );
};

export default ImageUploader;
