import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {RelativeDate, ProfilePicture} from 'components/Widgets';

import {sanitizeWhitespace} from 'consts/utilities';

import styles from './style.css';
import {usernameForPost} from 'consts/influencerHelper';

const InfluencerProfile = ({canAccessAllInfluencers, gig}) => {
  const date = gig.post.schedule.postToInstagramDeadline;

  return (
    <div className={styles.root}>
      <div className={styles.profilePicture}>
        <ProfilePicture src={gig.influencer.profilePicture} size={60} />
      </div>
      <div className={styles.info}>
        <div className={styles.influencerUsername}>
          <Link to={canAccessAllInfluencers ? `/influencers/${gig.influencer.id}` : undefined}>
            {sanitizeWhitespace(usernameForPost(gig.post, gig.influencer))}
          </Link>
        </div>
        <div className={styles.gigTimeline}>
          <p>
            Submitted <RelativeDate date={gig.submission.created} />
          </p>
          <p>
            {new Date(date) >= Date.now() ? (
              <span>
                <RelativeDate date={date} skipAGO /> left to verify
              </span>
            ) : (
              <span className={styles.overdue}>overdue</span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

InfluencerProfile.propTypes = {
  canAccessAllInfluencers: PropTypes.bool.isRequired,
  gig: PropTypes.object.isRequired,
};

export default InfluencerProfile;
