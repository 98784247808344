import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {useLazyQuery, gql} from '@apollo/client';
import {Box, Grid} from '@material-ui/core';
import * as qs from 'query-string';

import {FormattedAddress, HideInDemoMode, LoaderDots} from '../../../components/Widgets';
import {InfluencerOffer, InfluencerProfile} from './components';
import Search from './components/Search';
import NewOffer from './components/NewOffer';
import GigOptions from './components/GigOptions';
import {CampaignHeader} from '../components';
import {Alert} from '@material-ui/lab';

type Data = {
  offer: Offer;
  influencer: Influencer;
  campaign: Campaign;
};

const CampaignManageInfluencers: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const {params}: {params: {campaign: string}} = useRouteMatch();
  const campaignId = params.campaign;
  const {q: username} = qs.parse(location.search);

  const [searchResult, setSearchResult] = useState<Data | null>(null);

  const [getInfluencerOffer, {loading: influencerOfferLoading, data: influencerOfferData}] =
    useLazyQuery<Data | undefined>(InfluencerOfferQuery, {
      fetchPolicy: 'cache-and-network',
    });

  const resetResults = () => {
    setSearchResult(null);
  };

  useEffect(() => {
    if (username) {
      getInfluencerOffer({
        variables: {
          campaignId,
          username,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (influencerOfferData) {
      setSearchResult(influencerOfferData);
    }
  }, [influencerOfferData]);

  const handleOnSearchChange = (value: string) => {
    resetResults();
    history.replace({...location, search: qs.stringify({q: value})});
  };

  const handleOnSearchDebounced = (value: string) => {
    getInfluencerOffer({
      variables: {
        campaignId: params.campaign,
        username: value,
      },
    });
  };

  const AddressSection = () => {
    if (!searchResult || !searchResult.offer || !searchResult.campaign.shippingRequired) {
      return <>No Address</>;
    }
    return (
      <>
        {!searchResult.offer.addressMissing && searchResult.influencer.address ? (
          <FormattedAddress address={searchResult.influencer.address} />
        ) : (
          'Waiting for confirmation'
        )}
      </>
    );
  };

  const renderInfluencerNotFound = () => (
    <Grid item xs={12}>
      <Box textAlign="center">{<span>Influencer not found</span>}</Box>
    </Grid>
  );

  const renderInfluencerFound = () => {
    const needsShippingAddress = searchResult?.campaign.shippingRequired
      ? searchResult?.offer?.addressMissing
      : false;

    return (
      <>
        <Grid item xs={12} md={6}>
          {searchResult?.influencer && (
            <InfluencerProfile influencer={searchResult.influencer} address={<AddressSection />} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <HideInDemoMode>
            {searchResult?.offer ? (
              <Grid container>
                <Grid item xs={12}>
                  <InfluencerOffer offer={searchResult.offer} campaign={searchResult.campaign} />
                </Grid>
                <Grid item xs={12}>
                  {!needsShippingAddress && (
                    <GigOptions offer={searchResult.offer} influencer={searchResult.influencer} />
                  )}
                  {needsShippingAddress && (
                    <Box>
                      <Alert severity="error">
                        The Influencer has not provided the required shipping address for this
                        campaign yet. Please ask them to provide it in the TAKUMI mobile App before
                        continuing.
                      </Alert>
                      <br />
                      <GigOptions
                        offer={searchResult.offer}
                        influencer={searchResult.influencer}
                        disabled
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            ) : (
              searchResult?.influencer && (
                <NewOffer campaign={searchResult?.campaign!} influencer={searchResult.influencer} />
              )
            )}
          </HideInDemoMode>
        </Grid>
      </>
    );
  };

  return (
    <Box>
      <CampaignHeader title="Manage Influencer" campaignId={params.campaign} />
      <Grid container alignItems="flex-start" spacing={5}>
        <Grid item xs={12} style={{margin: '20px 0 40px'}}>
          <Search
            initialValue={(username && String(username)) || ''}
            onChange={handleOnSearchChange}
            onDebounced={handleOnSearchDebounced}
          />
        </Grid>
        {influencerOfferLoading && (
          <Grid item xs={12}>
            <LoaderDots />
          </Grid>
        )}
        {!influencerOfferLoading && username && (
          <>{searchResult?.influencer ? renderInfluencerFound() : renderInfluencerNotFound()}</>
        )}
      </Grid>
    </Box>
  );
};

export const InfluencerOfferQuery = gql`
  query InfluencerOfferQuery($campaignId: UUIDString!, $username: String!) {
    offer: offerForInfluencerInCampaign(campaignId: $campaignId, username: $username) {
      id
      state
      payable
      formattedState
      inTransit
      trackingCode
      addressMissing
      canBeEdited
      deliverables {
        id
        postId
        created
        delivered
        deliveredAt
        gigId
        post {
          id
          postType
          opened
          mention
          hashtags
          startFirstHashtag
          submissionDeadline
          instructions
          deadline
          requiresReviewBeforePosting
        }
      }
      reward {
        formattedValue
      }
      paymentTerms
      revertableState
      gigs {
        id
        state
        insightStatus
        post {
          id
          postType
          reach
          engagements
        }
      }
      rewardBreakdown {
        netValue {
          formattedValue
        }
        vatValue {
          formattedValue
        }
        totalValue {
          formattedValue
        }
        showNetAndVat
      }
      payment {
        id
        type
        successful
        __typename
      }
    }
    influencer(username: $username) {
      id
      state
      gender
      email
      profilePicture
      fullName
      targetRegion {
        id
        name
        country
        countryCode
      }
      address {
        id
        address1
        address2
        name
        city
        postalCode
        state
        phonenumber
      }
      instagramAccount {
        id
        followers
        username
        biography
        engagement {
          formattedValue
        }
        active
      }
      tiktokAccount {
        id
        followers
        username
      }
      youtubeChannel {
        id
        subscriberCount
        customUrl
      }
      twitchChannel {
        id
        followers
        url
      }
      snapchatAccount {
        id
        displayName
        snapchatUsername
        snapchatUserId
      }
    }
    campaign(id: $campaignId) {
      id
      isFullyReserved
      shippingRequired
      marketSlug
      applyFirst
      paymentTerms
      posts {
        id
        postType
        opened
        mention
        hashtags
        startFirstHashtag
        submissionDeadline
        instructions
        deadline
        requiresReviewBeforePosting
        timezone
      }
    }
  }
`;

export default CampaignManageInfluencers;
