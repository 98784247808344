import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  type InfluencerFilterType,
  ageFormatter,
  initialState,
  participatingCampaignCountFormatter,
  percentageFormatter,
  valueToKNotation,
} from './helper';
import {interestsItems} from '../../../consts/targetingValues';
import {black} from '../../../consts/brand.integration';
import useFeatureFlags from 'hooks/useFeatureFlags';

interface Props {
  selected?: InfluencerFilterType;
  onChange: (props: Record<string, any>) => void;
}

const InfluencerFilter: React.FC<Props> = ({selected, onChange}) => {
  const {useTwitchIntegration} = useFeatureFlags();
  const [canReset, setCanReset] = useState<boolean>(false);
  const [formState, setFormState] = useState<InfluencerFilterType>(initialState);
  const [formChanges, setFormChanges] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (selected && formState === initialState && Object.entries(selected).length > 0) {
      setFormState({...formState, ...selected});
    }
  }, [selected]);

  useEffect(() => {
    const diff = parseDiff();

    const changes = Object.entries(diff).length;
    setFormChanges(changes);
    setCanReset(changes > 0);

    if (onChange) {
      onChange(diff);
    }
  }, [formState]);

  /**
   * Parse diff values
   */
  const parseDiff = (): Record<string, any> => {
    const diff = {};
    for (const prop in formState) {
      if ((formState as any)[prop] !== (initialState as any)[prop]) {
        diff[prop] = (formState as any)[prop];
      }
    }
    return diff;
  };

  const handleFormChange = (changes: {name: string; value: any}[]) => {
    const updates = changes.reduce(
      (obj, item) => ({
        ...obj,
        [item.name]: item.value,
      }),
      {}
    );

    setFormState({...formState, ...updates});
  };

  const handleReset = () => {
    setFormState(initialState);
  };

  return (
    <Accordion
      id="moreFilters"
      aria-label="more-filters"
      onChange={(event, isExpanded) => setIsExpanded(isExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="button">{`${isExpanded ? 'Close' : 'More filters'} ${
          formChanges > 0 ? `(${formChanges})` : ''
        }`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel>Gender</FormLabel>
                <RadioGroup
                  row
                  name="gender"
                  aria-label="gender"
                  value={formState.gender}
                  onChange={(e, value) => handleFormChange([{name: 'gender', value}])}
                >
                  <FormControlLabel value="all" control={<Radio />} label="All" />
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel>Social Networks</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="hasFacebookPage"
                        color="primary"
                        checked={formState.hasFacebookPage}
                        onChange={(e, value) =>
                          handleFormChange([{name: 'hasFacebookPage', value}])
                        }
                      />
                    }
                    label="Has Instagram Linked"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="hasTiktokAccount"
                        color="primary"
                        checked={formState.hasTiktokAccount}
                        onChange={(e, value) =>
                          handleFormChange([{name: 'hasTiktokAccount', value}])
                        }
                      />
                    }
                    label="Has TikTok Linked"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="hasYoutubeChannel"
                        color="primary"
                        checked={formState.hasYoutubeChannel}
                        onChange={(e, value) =>
                          handleFormChange([{name: 'hasYoutubeChannel', value}])
                        }
                      />
                    }
                    label="Has YouTube Linked"
                  />
                  {useTwitchIntegration && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="hasTwitchChannel"
                          color="primary"
                          checked={formState.hasTwitchChannel}
                          onChange={(e, value) =>
                            handleFormChange([{name: 'hasTwitchChannel', value}])
                          }
                        />
                      }
                      label="Has Twitch Linked"
                    />
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel>Followers</FormLabel>
                <Slider
                  name="followers"
                  min={initialState.minFollowers}
                  max={initialState.maxFollowers}
                  valueLabelFormat={valueToKNotation}
                  defaultValue={[initialState.minFollowers, initialState.maxFollowers]}
                  value={[formState.minFollowers, formState.maxFollowers]}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  step={1000}
                  marks={[
                    {value: initialState.minFollowers, label: '1K'},
                    {value: initialState.maxFollowers, label: '1M+'},
                  ]}
                  onChange={(e, value: number | number[]) => {
                    const newValue = Array.isArray(value) ? value : [value, value];
                    handleFormChange([
                      {name: 'minFollowers', value: newValue[0]},
                      {name: 'maxFollowers', value: newValue[1]},
                    ]);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel>Instagram Engagement Rate</FormLabel>
                <Slider
                  name="engagements"
                  min={initialState.minEngagement}
                  max={initialState.maxEngagement}
                  valueLabelFormat={percentageFormatter}
                  defaultValue={[initialState.minEngagement, initialState.maxEngagement]}
                  value={[formState.minEngagement, formState.maxEngagement]}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  step={1}
                  marks={[
                    {
                      value: initialState.minEngagement,
                      label: `${initialState.minEngagement}%`,
                    },
                    {
                      value: initialState.maxEngagement,
                      label: `${initialState.maxEngagement}%+`,
                    },
                  ]}
                  onChange={(e, value: number | number[]) => {
                    const newValue = Array.isArray(value) ? value : [value, value];
                    handleFormChange([
                      {name: 'minEngagement', value: newValue[0]},
                      {name: 'maxEngagement', value: newValue[1]},
                    ]);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel>Age</FormLabel>
                <Slider
                  name="age"
                  min={initialState.minAge}
                  max={initialState.maxAge}
                  valueLabelFormat={ageFormatter}
                  defaultValue={[initialState.minAge, initialState.maxAge]}
                  value={[formState.minAge, formState.maxAge]}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  step={1}
                  marks={[
                    {value: initialState.minAge, label: `${initialState.minAge}`},
                    {value: initialState.maxAge, label: `${initialState.maxAge}+`},
                  ]}
                  onChange={(e, value: number | number[]) => {
                    const newValue = Array.isArray(value) ? value : [value, value];
                    handleFormChange([
                      {name: 'minAge', value: newValue[0]},
                      {name: 'maxAge', value: newValue[1]},
                    ]);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel>TAKUMI Campaign Count</FormLabel>
                <Slider
                  name="participatingCampaignCount"
                  min={initialState.minParticipatingCampaignCount}
                  max={initialState.maxParticipatingCampaignCount}
                  valueLabelFormat={participatingCampaignCountFormatter}
                  defaultValue={[
                    initialState.minParticipatingCampaignCount,
                    initialState.maxParticipatingCampaignCount,
                  ]}
                  value={[
                    formState.minParticipatingCampaignCount,
                    formState.maxParticipatingCampaignCount,
                  ]}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  step={1}
                  marks={[
                    {
                      value: initialState.minParticipatingCampaignCount,
                      label: `${initialState.minParticipatingCampaignCount}`,
                    },
                    {
                      value: initialState.maxParticipatingCampaignCount,
                      label: `${initialState.maxParticipatingCampaignCount}+`,
                    },
                  ]}
                  onChange={(e, value: number | number[]) => {
                    const newValue = Array.isArray(value) ? value : [value, value];
                    handleFormChange([
                      {name: 'minParticipatingCampaignCount', value: newValue[0]},
                      {name: 'maxParticipatingCampaignCount', value: newValue[1]},
                    ]);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="interestIds">Interests</InputLabel>
                <Select
                  labelId="interestIds"
                  name="interestIds"
                  defaultValue={initialState.interestIds}
                  value={formState.interestIds}
                  multiple
                  color="primary"
                  onChange={e => handleFormChange([{name: 'interestIds', value: e.target.value}])}
                >
                  {interestsItems.map(obj => (
                    <MenuItem key={obj.value} value={obj.value}>
                      {obj.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="hasNoInterests"
                      color="primary"
                      checked={formState.hasNoInterests}
                      onChange={(e, value) => handleFormChange([{name: 'hasNoInterests', value}])}
                    />
                  }
                  label="Show influencers with no interests"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel>Order by</FormLabel>
                <RadioGroup
                  name="sortBy"
                  row
                  aria-label="sortBy"
                  value={formState.sortBy}
                  onChange={(e, value) => handleFormChange([{name: 'sortBy', value}])}
                >
                  <FormControlLabel value="user_created" control={<Radio />} label="Signed up" />
                  <FormControlLabel value="followers" control={<Radio />} label="Followers" />
                  <FormControlLabel
                    value="participating_campaign_count"
                    control={<Radio />}
                    label="Campaign Count"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel>Sort</FormLabel>
                <RadioGroup
                  name="sortOrder"
                  row
                  aria-label="sortOrder"
                  value={formState.sortOrder}
                  onChange={(e, value) => handleFormChange([{name: 'sortOrder', value}])}
                >
                  <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
                  <FormControlLabel value="desc" control={<Radio />} label="Descending" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {canReset && (
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <Button variant="outlined" color="secondary" onClick={handleReset}>
                  Reset Filters
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {},
  content: {
    justifyContent: 'flex-end',
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    border: `1px solid ${black}23`,
    borderRadius: '4px',
    backgroundColor: `${black}05`,
  },
}))(MuiAccordionDetails);

export default InfluencerFilter;
