import {gql} from '@apollo/client';

import {GigMedia} from '../../../components/Gig';
import {Campaign} from 'services/fragments';

export const AdvertiserCampaignQuery = gql`
  query AdvertiserCampaignQuery($domain: String!) {
    advertiser(domain: $domain) {
      id
      name
      domain
      campaigns {
        id
        archived
        created
        started
        name
        state
        isFullyReserved
        photos {
          coverPhoto {
            url
          }
        }
        ...targetFields
        posts {
          id
          postType
        }
      }
    }
  }
  ${Campaign.targetFragment}
`;

export const GigQuery = gql`
  query AdvertiserPerformanceGigQuery($campaignId: UUIDString!) {
    gigs(campaignId: $campaignId) {
      edges {
        node {
          id
          ...GigMediaFields
          state
          influencer {
            id
            fullName
            username
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${GigMedia.fragments.gig}
`;
