import React from 'react';
import {Link} from 'react-router-dom';
import emojiFlag from 'emoji-flag';
import {makeStyles} from '@material-ui/styles';

import ProfilePicture from '../ProfilePicture';
import {getLocation} from '../../../consts/influencerHelper';
import useRequiresPermission from '../../../hooks/useRequiresPermission';
import {ACCESS_ALL_INFLUENCERS} from '../../../consts/permissions';
import InfluencerHandles from '../InfluencerHandles/InfluencerHandles';
import {Box, Typography} from '@material-ui/core';
import CopyPasteable from '../CopyPasteable/CopyPasteable';
import {Icon} from '@iconify/react';
import {black80} from 'consts/brand.integration';

interface Props {
  influencer: Influencer;
  showFollowers?: boolean;
  showEmail?: boolean;
  showRegion?: boolean;
}

const InfluencerLink: React.FC<{id: string; children: JSX.Element}> = ({id, children}) => {
  const hasPermission = useRequiresPermission(ACCESS_ALL_INFLUENCERS);
  return hasPermission ? <Link to={`/influencers/${id}/`}>{children}</Link> : children;
};

const InfluencerProfileCard: React.FC<Props> = ({
  influencer,
  showFollowers,
  showEmail,
  showRegion,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <InfluencerLink id={influencer.id}>
        <Box>
          <ProfilePicture src={influencer.profilePicture!} size={40} />
        </Box>
      </InfluencerLink>
      <Box marginLeft={2}>
        <InfluencerLink id={influencer.id}>
          <Typography variant="subtitle1">{influencer.fullName}</Typography>
        </InfluencerLink>
        {showEmail && (
          <Box display="flex" marginTop={0.5}>
            <Icon icon="ic:outline-email" fontSize="0.95rem" color={black80} />
            <Typography variant="caption" style={{marginLeft: '.2rem'}}>
              <CopyPasteable>{influencer.email}</CopyPasteable>
            </Typography>
          </Box>
        )}
        <Box marginTop={showEmail ? 1.5 : 1}>
          <InfluencerHandles influencer={influencer} size="small" showFollowers={showFollowers} />
        </Box>
        {showRegion && influencer.targetRegion && (
          <Box marginTop={1}>
            <Typography variant="caption" gutterBottom>
              {emojiFlag(influencer.targetRegion.countryCode)}{' '}
              {getLocation(influencer.targetRegion.name, influencer.targetRegion.country)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    // margin: '10px 10px 14px 12px',
  },
});

export default InfluencerProfileCard;
