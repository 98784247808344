import {Box, Button, Grid, Typography} from '@material-ui/core';
import React from 'react';
import {createStyles, makeStyles} from '@material-ui/styles';
import {Link} from 'react-router-dom';

import SubmitModal from '../SubmitModal';
import SubmitInsightsModal from '../InsightsModal';
import {purple} from '../../../../../consts/brand.integration';
import {getPostTypeFormatted} from 'consts/campaignPostType';
import useCurrentUser from 'hooks/useCurrentUser';
import {getSocialPlatformNameByPostType} from 'utils/gigUtils';
import {hasSocialPlatformLinked} from 'utils/influencerUtils';
import {Alert} from '@material-ui/lab';

interface Props {
  offer: Offer;
  influencer: Influencer;
  disabled?: boolean;
}

const GigOptions: React.FC<Props> = ({offer, influencer, disabled}) => {
  const classes = useStyles();
  const currentUser = useCurrentUser();

  const getGigForPost = (post: Post) => {
    for (const gig of offer?.gigs!) {
      if (gig?.post?.id === post.id) {
        return gig;
      }
    }
    return null;
  };

  const renderOpenGig = (
    gig: Gig,
    deliverable: OfferDeliverable,
    index: number,
    isPlatformLinked: boolean = false
  ) => {
    const canSubmit = gig?.state === 'requires_resubmit';
    const insightMissing = gig?.insightStatus === 'missing';
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2">{`State: "${gig.state
            ?.toUpperCase()
            .replaceAll('_', ' ')}"`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to={`/gigs/${gig.id}`}>
            <Button variant="outlined" color="secondary">
              Open Gig
            </Button>
          </Link>
        </Grid>
        <Grid container item direction="column" xs={12}>
          <Grid item>
            {canSubmit && (
              <>
                {!isPlatformLinked && renderPlatformNotLinked()}
                <SubmitModal
                  influencer={influencer}
                  post={deliverable?.post}
                  idx={index + 1}
                  disabled={disabled || !isPlatformLinked}
                />
              </>
            )}
          </Grid>
          <Grid item>
            {insightMissing && (
              <>
                {!isPlatformLinked && renderPlatformNotLinked()}
                <SubmitInsightsModal
                  gig={gig}
                  post={deliverable?.post}
                  idx={index + 1}
                  disabled={disabled || !isPlatformLinked}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderNoSubmission = (
    deliverable: OfferDeliverable,
    index: number,
    isPlatformLinked: boolean = false
  ) => {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>{!isPlatformLinked && renderPlatformNotLinked()}</Grid>
        <Grid item xs={12} sm={6}>
          <Alert severity="warning">No Submission.</Alert>
        </Grid>
        <Grid item>
          <SubmitModal
            influencer={influencer}
            post={deliverable?.post}
            idx={index + 1}
            refetchQueries={['InfluencerOfferQuery']}
            disabled={disabled || !isPlatformLinked}
          />
        </Grid>
      </Grid>
    );
  };

  const renderPlatformNotLinked = () => (
    <Alert severity="error">The social platform required for this deliverable is not linked!</Alert>
  );

  return (
    <>
      {offer.deliverables?.map((deliverable, index) => {
        const gig = getGigForPost(deliverable?.post!);
        const platform = getSocialPlatformNameByPostType(deliverable?.post?.postType ?? '');
        const hasPlatformLinked = hasSocialPlatformLinked(platform, influencer);
        return (
          <Box key={deliverable?.post?.id} marginTop="1rem">
            <Typography variant="h5" className={classes.postTitle}>
              {`Post ${index + 1}. ${getPostTypeFormatted(deliverable?.post?.postType ?? '')}`}
            </Typography>
            <Box marginBottom="1rem">
              {gig
                ? renderOpenGig(gig, deliverable, index, hasPlatformLinked)
                : renderNoSubmission(deliverable, index, hasPlatformLinked)}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    postTitle: {
      color: purple,
      borderBottom: `1px solid ${purple}`,
      marginBottom: '20px',
    },
  })
);

export default GigOptions;
