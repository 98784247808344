import React, {useState, useEffect} from 'react';
import {Divider, makeStyles, Grid, Typography} from '@material-ui/core';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {ApiError, LoaderDots} from '../../../../../components/Widgets';
import {useDataServiceApi} from '../../../../../hooks/useDataServiceApi';
import PlatformTable from './PlatformTable';
import {shouldDisplayMetric} from '../helper';
import {DataExport, PerformanceData} from '../types';

interface Props {
  campaign?: Campaign;
  apiData: (data: DataExport) => void;
}

const CampaignPerformanceByPlatform: React.FC<Props> = ({campaign, apiData}) => {
  const user = useCurrentUser();
  const classes = useStyles();
  const {error, loading, fetchCampaignPerformanceByPlatformData} = useDataServiceApi();
  const [resourceData, setResourceData] = useState<any>(null);
  const title = 'Social Platforms';

  useEffect(() => {
    const fetchData = async () => {
      if (user?.takumiDataApiToken && campaign?.id) {
        const data = await fetchCampaignPerformanceByPlatformData({
          apiToken: user?.takumiDataApiToken || '',
          campaignId: campaign?.id || '',
        });
        setResourceData(data);
        apiData(data);
      }
    };
    fetchData();
  }, [user?.takumiDataApiToken, campaign?.id, fetchCampaignPerformanceByPlatformData]);

  const enabledMetrics = (campaign?.organicPerformanceMetrics ?? []) as string[];
  const platformMetrics = resourceData
    ? resourceData.map((apiResponse: PerformanceData) => ({
        platform: apiResponse.social_media_platform,
        metrics: {
          ...(shouldDisplayMetric(enabledMetrics, 'influencer_count')
            ? {impression_total: apiResponse.influencer_count}
            : {}),

          ...(shouldDisplayMetric(enabledMetrics, 'digital_content_count')
            ? {digital_content_count: apiResponse.digital_content_count}
            : {}),

          ...(shouldDisplayMetric(enabledMetrics, 'impression_total')
            ? {impression_total: apiResponse.impression_total}
            : {}),

          ...(shouldDisplayMetric(enabledMetrics, 'reach_total')
            ? {reach_total: apiResponse.reach_total}
            : {}),

          ...(shouldDisplayMetric(enabledMetrics, 'engagement_total')
            ? {engagement_total: apiResponse.engagement_total}
            : {}),

          ...(shouldDisplayMetric(enabledMetrics, 'engagement_rate_by_impressions')
            ? {engagement_rate_by_impressions: apiResponse.engagement_rate_by_impressions}
            : {}),
        },
      }))
    : [];

  if (loading) {
    return <LoaderDots className={classes.spacing} />;
  }

  if (Object.keys(platformMetrics[0]?.metrics || {}).length === 0) return null;

  if (error) {
    return <ApiError title={title} classes={classes} />;
  }

  return (
    <>
      {platformMetrics?.length > 1 && (
        // If there's only one platform then these are just the summary metrics, so no need to show
        <>
          <Typography variant="h4" className={classes.header}>
            {title}
          </Typography>
          <Divider className={classes.spacing} />
          <Grid container spacing={3}>
            <PlatformTable metrics={platformMetrics} />
          </Grid>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default CampaignPerformanceByPlatform;
