import {gql} from '@apollo/client';
import {Campaign} from 'services/fragments';

export const AudienceInsightFragment = gql`
  fragment AudienceInsightFragment on InstagramAudienceInsight {
    id
    created
    totalRegionFollowers
    genderSplit {
      id
      name
      followerCount
      followerPercentage {
        value
      }
    }
    ageSplit {
      id
      name
      followerCount
      followerPercentage {
        value
      }
    }
    regionInsights(maxResults: 3) {
      id
      region {
        id
        name
        countryCode
      }
      followerPercentage {
        formattedValue
      }
    }
  }
`;

export const RecentPostsStatsFragment = gql`
  fragment RecentPostsStatsFragment on RecentPostsStats {
    impressions {
      image {
        median
        count
      }
      video {
        median
        count
      }
      gallery {
        median
        count
      }
    }
    engagements {
      image {
        median
        count
      }
      video {
        median
        count
      }
      gallery {
        median
        count
      }
    }
    likes {
      image {
        median
        count
      }
      video {
        median
        count
      }
      gallery {
        median
        count
      }
    }
    comments {
      image {
        median
        count
      }
      video {
        median
        count
      }
      gallery {
        median
        count
      }
    }
  }
`;

export const CampaignParticipantsOfferFragment = gql`
  fragment CampaignParticipantsOfferFragment on Offer {
    id
    addressMissing
    inTransit
    isClaimable
    isSelected
    state
    accepted
    rejected
    reach
    revokeEvent {
      created
    }
    trackingCode
    campaign {
      id
    }
    reward {
      value
      currency
      formattedValue
    }
    influencer {
      id
      email
      fullName
      profilePicture
      email
      address {
        id
        address1
        address2
        name
        city
        postalCode
        state
        phonenumber
      }
      targetRegion {
        id
        name
        country
        countryCode
      }
      instagramAccount {
        id
        username
        followers
        recentPostsStats {
          ...RecentPostsStatsFragment
        }
        audienceInsight {
          id
          ...AudienceInsightFragment
        }
        engagement {
          formattedValue
        }
        estimatedEngagementsPerPost
        estimatedImpressions
      }
      tiktokAccount {
        id
        followers
        username
        engagementRate90days {
          formattedValue
        }
      }
      youtubeChannel {
        id
        customUrl
        subscriberCount
      }
      twitchChannel {
        id
        url
        followers
      }
    }
    rewardBreakdown {
      netValue {
        formattedValue
        value
      }
      vatValue {
        formattedValue
        value
      }
      totalValue {
        formattedValue
        value
      }
      showNetAndVat
    }
  }
  ${RecentPostsStatsFragment}
  ${AudienceInsightFragment}
`;

export const CampaignQuery = gql`
  query CampaignQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      archived
      rewardModel
      canBeCompleted
      state
      shippingRequired
      marketSlug
      brandMatch
      applyFirst
      participation {
        type
        count
      }
      price {
        currency
        formattedValue
      }
      isFullyReserved
      ...targetFields
      requiresTiktokAccount
    }
  }
  ${Campaign.targetFragment}
`;

export const CampaignParticipantsQuery = gql`
  query CampaignParticipantsQuery(
    $campaignId: UUIDString!
    $state: String
    $awaitingSubmission: Boolean
    $cursor: String
  ) {
    offers: offersForCampaign(
      id: $campaignId
      state: $state
      awaitingSubmission: $awaitingSubmission
      first: 20
      after: $cursor
    ) {
      edges {
        node {
          id
          ...CampaignParticipantsOfferFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CampaignParticipantsOfferFragment}
`;

export const OffersDeclinedQuery = gql`
  query OffersDeclinedQuery($campaignId: UUIDString!, $cursor: String) {
    offers: offersDeclined(campaignId: $campaignId, first: 20, after: $cursor) {
      edges {
        node {
          id
          state
          rejected
          reach
          campaign {
            id
            requiresTiktokAccount
          }
          influencer {
            id
            email
            fullName
            profilePicture
            email
            instagramAccount {
              id
              username
              followers
              engagement {
                formattedValue
              }
              audienceInsight {
                id
                ...AudienceInsightFragment
              }
            }
            tiktokAccount {
              id
              followers
              username
            }
            youtubeChannel {
              id
              customUrl
              subscriberCount
            }
            twitchChannel {
              id
              url
              followers
            }
          }
        }
      }
      count
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${AudienceInsightFragment}
`;

export const OffersInterestedQuery = gql`
  query OffersInterestedQuery($campaignId: UUIDString!, $cursor: String, $minER: Int) {
    offers: offersInterested(campaignId: $campaignId, first: 20, after: $cursor, minEr: $minER) {
      edges {
        node {
          id
          ...CampaignParticipantsOfferFragment
        }
      }
      count
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CampaignParticipantsOfferFragment}
`;

export const OffersCandidatesQuery = gql`
  query OffersCandidatesQuery($campaignId: UUIDString!, $cursor: String) {
    offers: offersCandidates(campaignId: $campaignId, first: 20, after: $cursor) {
      edges {
        node {
          id
          ...CampaignParticipantsOfferFragment
        }
      }
      count
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CampaignParticipantsOfferFragment}
`;

export const OffersApprovedByBrandQuery = gql`
  query OffersApprovedByBrandQuery($campaignId: UUIDString!, $cursor: String) {
    offers: offersApprovedByBrand(
      campaignId: $campaignId
      first: 20
      after: $cursor
      includeAccepted: false
    ) {
      edges {
        node {
          id
          ...CampaignParticipantsOfferFragment
        }
      }
      count
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CampaignParticipantsOfferFragment}
`;

export const RevokeOfferMutation = gql`
  mutation revokeOffer($id: UUIDString!) {
    revokeOffer(id: $id) {
      ok
      offer {
        id
        state
        campaign {
          id
          name
          isFullyReserved
          participation {
            type
            count
          }
        }
      }
    }
  }
`;

export const RevokeRequestedOffersMutation = gql`
  mutation revokeRequestedOffers($id: UUIDString!, $state: RevokeOfferState!) {
    revokeRequestedOffers(id: $id, state: $state) {
      ok
    }
  }
`;

export const MarkAsSelectedMutation = gql`
  mutation MarkAsSelectedMutation($id: UUIDString!) {
    markAsSelected(offerId: $id) {
      ok
      offer {
        id
        isSelected
      }
    }
  }
`;

export const UnmarkAsSelectedMutation = gql`
  mutation UnmarkAsSelectedMutation($id: UUIDString!) {
    unmarkAsSelected(offerId: $id) {
      ok
      offer {
        id
        isSelected
      }
    }
  }
`;
