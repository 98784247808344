import React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';

import {
  ACCESS_ALL_ADVERTISERS,
  CREATE_BRAND,
  EDIT_CAMPAIGN,
  MANAGE_INFLUENCERS,
  MANAGE_SHIPPING_INFO,
  VIEW_CAMPAIGN_PERFORMANCE,
  VIEW_PARTICIPANTS,
  VIEW_POST_REPORTS,
} from '../consts/permissions';
import {AdminAdvertisers} from '../scenes/Admin';
import {
  AdvertiserCreate,
  AdvertiserSettings,
  AdvertiserAccessSettings,
  AdvertiserMembers,
  AdvertiserSocials,
  AdvertiserPerformance,
  AdvertiserCampaignAggregate,
  AdvertiserCampaignCompare,
} from '../scenes/Advertiser';
import {
  CampaignAdvertisers,
  CampaignBrandMatch,
  CampaignDetailedReport,
  CampaignEditPost,
  CampaignGigWrap,
  CampaignList,
  CampaignManageInfluencers,
  CampaignParticipants,
  CampaignPlaylist,
  CampaignPosts,
  CampaignPrompts,
  CampaignShipping,
  CampaignTargeting,
  CampaignWrapper,
  CampaignContentCalendar,
  CampaignPerformance,
  CampaignInsights,
  EditCampaign,
  NewCampaign,
  SetupCampaign,
  UnreviewedGigs,
  UnverifiedGigs,
} from '../scenes/Campaign';
import UnverifiedGig from '../scenes/Campaign/UnverifiedGigs/UnverifiedGig';
import SecureRoute from './components/SecureRoute';
import AdvertiserCompetitors from 'scenes/Advertiser/AdvertiserCompetitors';

/**
 * @path `/brands`
 */
export const Brands = (): JSX.Element => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <SecureRoute
        exact
        path={`${path}`}
        permission={ACCESS_ALL_ADVERTISERS}
        component={AdminAdvertisers}
      />
      <SecureRoute path={`${path}/new`} permission={CREATE_BRAND} component={AdvertiserCreate} />
      <Route path={`${path}/:advertiser`}>
        <Advertiser />
      </Route>
    </Switch>
  );
};

/**
 * @path `/brands/:advertiser`
 */
const Advertiser = (): JSX.Element => {
  const match = useRouteMatch();
  const {path} = match;
  return (
    <CampaignAdvertisers {...{match}}>
      <Switch>
        <SecureRoute
          path={`${path}/campaigns/new`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={NewCampaign}
        />
        <SecureRoute
          path={`${path}/campaigns/setup`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={SetupCampaign}
        />
        <SecureRoute
          path={`${path}/campaigns`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={CampaignList}
        />
        <SecureRoute
          path={`${path}/settings`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={AdvertiserSettings}
        />
        <SecureRoute
          path={`${path}/members`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={AdvertiserMembers}
        />
        <SecureRoute
          path={`${path}/socials`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={AdvertiserSocials}
        />
        <SecureRoute
          path={`${path}/performance`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={AdvertiserPerformance}
        />
        <SecureRoute
          path={`${path}/campaign-aggregate`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={AdvertiserCampaignAggregate}
        />
        <SecureRoute
          path={`${path}/campaign-comparison`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={AdvertiserCampaignCompare}
        />
        <SecureRoute
          path={`${path}/competitors`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={AdvertiserCompetitors}
        />
        <SecureRoute
          path={`${path}/access-settings`}
          permission={ACCESS_ALL_ADVERTISERS}
          component={AdvertiserAccessSettings}
        />
        <Route path={`${path}/:campaign`}>
          <Campaign />
        </Route>
        <Redirect exact from={`${path}`} to={`${path}/campaigns`} />
      </Switch>
    </CampaignAdvertisers>
  );
};

/**
 * @path `/brands/:advertiser/:campaign`
 */
const Campaign = (): JSX.Element => {
  const match = useRouteMatch();
  const {path} = match;
  return (
    <CampaignWrapper {...{match}}>
      <Switch>
        <SecureRoute path={`${path}/setup`} permission={EDIT_CAMPAIGN} component={EditCampaign} />

        <Route path={`${path}/posts`}>
          <Switch>
            <Route exact path={`${path}/posts`} component={CampaignPosts} />
            <Route path={`${path}/posts/:postId`}>
              <CampaignPosts>
                <Switch>
                  <Route path={`${path}/posts/:postId/edit`} component={CampaignEditPost} />
                  <Route path={`${path}/posts/:postId/gig/:gigId`} component={CampaignGigWrap} />
                  <Redirect from={`${path}/posts/:postId`} to={`${path}/posts/:postId/edit`} />
                </Switch>
              </CampaignPosts>
            </Route>
          </Switch>
        </Route>

        <Route path={`${path}/unapproved`}>
          <Switch>
            <Route exact path={`${path}/unapproved`} component={UnreviewedGigs} />
            <Route path={`${path}/unapproved/:gigId`} component={UnreviewedGigs} />
          </Switch>
        </Route>

        <SecureRoute
          path={`${path}/detailed_report`}
          permission={VIEW_POST_REPORTS}
          component={CampaignDetailedReport}
        />

        <SecureRoute path={`${path}/unverified`} permission={MANAGE_INFLUENCERS}>
          <Switch>
            <Route exact path={`${path}/unverified`} component={UnverifiedGigs} />
            <Route path={`${path}/unverified/:gigId`}>
              <UnverifiedGigs {...{match}}>
                <UnverifiedGig {...{match}} />
              </UnverifiedGigs>
            </Route>
          </Switch>
        </SecureRoute>

        <SecureRoute
          path={`${path}/targeting`}
          permission={EDIT_CAMPAIGN}
          component={CampaignTargeting}
        />

        <SecureRoute
          path={`${path}/participants`}
          permission={VIEW_PARTICIPANTS}
          component={CampaignParticipants}
        />

        <SecureRoute
          path={`${path}/prompts`}
          permission={VIEW_PARTICIPANTS}
          component={CampaignPrompts}
        />

        <SecureRoute
          path={`${path}/influencers`}
          permission={MANAGE_INFLUENCERS}
          component={CampaignManageInfluencers}
        />

        <Route path={`${path}/match`} component={CampaignBrandMatch} />

        <SecureRoute
          path={`${path}/notify`}
          permission={MANAGE_INFLUENCERS}
          component={CampaignPlaylist}
        />

        <SecureRoute
          path={`${path}/playlist`}
          permission={MANAGE_INFLUENCERS}
          component={CampaignPlaylist}
        />

        <SecureRoute
          path={`${path}/calendar`}
          permission={VIEW_PARTICIPANTS}
          component={CampaignContentCalendar}
        />

        <SecureRoute
          path={`${path}/shipping`}
          permission={MANAGE_SHIPPING_INFO}
          component={CampaignShipping}
        />
        <SecureRoute
          path={`${path}/insights`}
          permission={MANAGE_INFLUENCERS}
          component={CampaignInsights}
        />

        <SecureRoute
          path={`${path}/performance`}
          permission={VIEW_CAMPAIGN_PERFORMANCE}
          component={CampaignPerformance}
        />

        <Redirect exact from={`${path}`} to={`${path}/posts`} />
        <Redirect from={`${path}/progress`} to={`${path}/posts`} />
        <Redirect from={`${path}/photos`} to={`${path}/posts`} />
      </Switch>
    </CampaignWrapper>
  );
};
