import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import * as qs from 'query-string';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {isEqual} from 'lodash';
import {useLazyQuery, useQuery} from '@apollo/client';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {RowWrap, InfluencerFilter, SearchInput} from '../../../../components/Widgets';
import {SelfTagModal} from '../../../../components/SelfTags';
import {numberWithCommas} from '../../../../consts/utilities';
import InfluencerCard from './components/InfluencerCard/InfluencerCard';
import {InfluencerSearchQuery, RegionQuery} from './queries';
import SelectRegion, {ALL_SUPPORTED_REGIONS} from '../../../../components/Region/SelectRegion';
import {InfluencerFilterType} from '../../../../components/Widgets/InfluencerFilter/helper';
import {INFLUENCER_FILTER_STATE} from '../../../../consts/variables';
import {withHookPagination} from '../../../../services/graphql';
import InfiniteScroller from '../../../../components/Widgets/InfiniteScroller/InfiniteScroller';
import {parseAccountStateForQuery, qsParseOptions, qsStringifyOptions} from './helper';
import {black40} from '../../../../consts/brand.integration';
import CreateInfluencerProfile from './components/CreateInfluencerProfile';
import useFeatureFlags from 'hooks/useFeatureFlags';
import AlternativeInfluencerSearch from './components/AlternativeInfluencerSearch';

const AdminInfluencers: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search);

  const {useCreateInfluencerProfile, useAlternativeInfluencerSearch} = useFeatureFlags();
  const [accountState, setAccountState] = useState<string>('reviewed');
  const [filters, setFilters] = useState<InfluencerFilterType>();
  const [selfTags, setSelfTags] = useState<Record<string, any>>({tagIds: []});
  const [region, setRegion] = useState<string>(ALL_SUPPORTED_REGIONS);
  const [searchString, setSearchString] = useState<string>('');

  const searchTimerRef = useRef<NodeJS.Timeout | null>(null);

  const {loading: regionLoading, data: regionData} = useQuery(RegionQuery, {
    variables: {
      includeUnsupported: true,
    },
  });

  const [fetchInfluencers, {loading, data, fetchMore, variables}] = useLazyQuery(
    InfluencerSearchQuery,
    {
      variables: {
        regionId: region,
        searchString,
        eligible: accountState === INFLUENCER_FILTER_STATE.New.value,
        state: parseAccountStateForQuery(accountState),
        sortOrder: 'desc',
        ...filters,
        information: selfTags,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadMore = withHookPagination(
    InfluencerSearchQuery,
    'influencerSearch',
    data,
    variables,
    fetchMore
  );

  const updateURI = () =>
    history.replace({
      ...location,
      search: qs.stringify(
        {
          state: accountState,
          region,
          filters: qs.stringify(filters, qsStringifyOptions),
          selfTags: qs.stringify(selfTags, qsStringifyOptions),
          q: searchString,
        },
        qsStringifyOptions
      ),
    });

  // Run delayed update URI and fetch results
  const debounceFetchInfluencers = () => {
    if (searchTimerRef.current) {
      clearTimeout(searchTimerRef.current);
      searchTimerRef.current = null;
    }
    searchTimerRef.current = setTimeout(() => {
      updateURI();
      fetchInfluencers();
    }, 1000);
  };

  useEffect(() => {
    if (params.region && params.region !== region) {
      setRegion(params.region as string);
    }

    if (params.filter && params.filter !== accountState) {
      setAccountState(params.filter as string);
    }

    if (params.filters) {
      const parsed = qs.parse(params.filters as string, qsParseOptions);

      // fixes case where single element is not parsed as array by qs.parse
      if (parsed.interestIds && typeof parsed.interestIds === 'string') {
        parsed.interestIds = [parsed.interestIds];
      }

      if (!isEqual(parsed, filters)) {
        setFilters(parsed);
      }
    }

    if (params.selfTags) {
      const parsed = qs.parse(params.selfTags as string, qsParseOptions);
      if (!isEqual(parsed, selfTags)) {
        setSelfTags(parsed);
      }
    }

    return () => {
      if (searchTimerRef.current) {
        clearTimeout(searchTimerRef.current);
        searchTimerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    debounceFetchInfluencers();
  }, [accountState, region, filters, selfTags, searchString]);

  return (
    <Grid container style={{marginTop: 70}} direction="column" spacing={4}>
      <Grid item container direction="column" spacing={2}>
        <Grid item container spacing={4}>
          <Grid item xs={12} md={8}>
            <SearchInput
              searchString={searchString}
              placeholder="Search Influencers"
              onChange={event => setSearchString(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectRegion
              countries={regionData?.countries || []}
              pending={regionLoading}
              region={regionLoading ? '' : region}
              showUnsupported
              onChange={setRegion}
            />
          </Grid>
        </Grid>
        <Grid item xs>
          <ToggleButtonGroup
            value={accountState}
            exclusive
            onChange={(event, value) => setAccountState(value)}
          >
            {[
              INFLUENCER_FILTER_STATE.All,
              INFLUENCER_FILTER_STATE.Disabled,
              INFLUENCER_FILTER_STATE.New,
              INFLUENCER_FILTER_STATE.Reviewed,
              INFLUENCER_FILTER_STATE.Verified,
            ].map(item => (
              <ToggleButton key={item.name} value={item.value} aria-label={item.name}>
                <Typography variant="button">{item.name}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs>
          <InfluencerFilter selected={filters} onChange={setFilters} />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {useCreateInfluencerProfile && (
            <Grid item xs={6}>
              <CreateInfluencerProfile />
            </Grid>
          )}
          <Grid item xs={6}>
            <SelfTagModal right value={selfTags} onChange={setSelfTags} />
          </Grid>
        </Grid>
        {!loading && data?.influencerSearch.count === 0 && searchString && (
          <>
            <Grid item xs>
              <Typography variant="body1" align="center">
                No influencers found when searching for "{`${searchString}`}"
              </Typography>
            </Grid>
            {useAlternativeInfluencerSearch && (
              <Grid item xs>
                <Typography variant="body1" align="center">
                  Was this influencer created just recently?
                  <p>Try this option instead...</p>
                  <br />
                  <p>
                    <AlternativeInfluencerSearch />
                  </p>
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        {data?.influencerSearch?.count > 0 && (
          <>
            <div className={classes.influencerCount}>
              <Typography variant="body2">
                {`${numberWithCommas(data.influencerSearch.count)} influencer${
                  data.influencerSearch.count > 1 ? 's' : ''
                }`}
              </Typography>
            </div>
            <InfiniteScroller
              loading={loading}
              loadMore={loadMore}
              hasMore={data?.influencerSearch?.pageInfo.hasNextPage}
            >
              <RowWrap>
                {data?.influencerSearch?.edges
                  .map((edge: InfluencerSearchResultEdge) => edge.node)
                  .map((influencer: Influencer) => (
                    <Link
                      key={influencer.id}
                      to={`/influencers/${influencer.id}/${
                        influencer.isSignedUp ? 'gigs' : 'settings'
                      }`}
                    >
                      <InfluencerCard influencer={influencer} />
                    </Link>
                  ))}
              </RowWrap>
            </InfiniteScroller>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  influencerCount: {
    textTransform: 'lowercase',
    fontVariant: 'small-caps',
    color: black40,
    marginBottom: '30px',
  },
});

export default AdminInfluencers;
