import {gql, useLazyQuery} from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {LoaderDots, Modal, ProfilePicture} from 'components/Widgets';
import {useFormik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import * as yup from 'yup';
import AddIcon from '@material-ui/icons/Add';
import {Alert} from '@material-ui/lab';
import {ModalElement} from 'components/Widgets/Modal';
import {debounce} from 'lodash/fp';
import emojiFlag from 'emoji-flag';

interface Props {
  advertiserId: string;
  onAddCompetitor: (id: string, bidirectional?: boolean) => Promise<any>;
}

const AddAdvertiserCompetitorModal: React.FC<Props> = ({advertiserId, onAddCompetitor}) => {
  const modal = useRef<ModalElement>(null);
  const [error, setError] = useState<string | null>(null);

  const handleAddCompetitor = async () => {
    const response = await onAddCompetitor(formik.values.competitor, formik.values.bidirectional);
    if (response?.error) {
      setError(response.errors[0].message);
    } else {
      modal.current?.close();
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      search: '',
      competitor: '',
      bidirectional: false,
    },
    validationSchema: yup.object({
      competitor: yup.string().required(),
      bidirectional: yup.boolean(),
    }),
    onSubmit: () => handleAddCompetitor(),
  });

  const [getAdvertisers, {loading, data}] = useLazyQuery(AdvertisersQuery);

  useEffect(() => {
    getAdvertisers();
  }, []);

  useEffect(() => {
    const debounceSearch = debounce(500, value => {
      formik.setFieldValue('competitor', '');
      formik.setFieldValue('bidirectional', false);
      getAdvertisers({variables: {search: value}});
    });
    debounceSearch(formik.values.search.length >= 2 ? formik.values.search : null);
  }, [formik.values.search]);

  const renderSelectMenuItem = (node: Advertiser) => (
    <MenuItem key={node?.id} value={node?.id}>
      <Box key={node.id} sx={{display: 'flex', flex: 1, padding: '.5rem'}}>
        <Box>
          <ProfilePicture src={node?.profilePicture ?? ''} size={30} />
        </Box>
        <Box flexGrow={1} marginLeft={2} alignSelf="center">
          <Typography variant="body1">{node?.name}</Typography>
        </Box>
        <Box alignSelf="center">{emojiFlag(node?.primaryRegion?.countryCode)}</Box>
      </Box>
    </MenuItem>
  );

  return (
    <Modal
      ref={modal}
      title="Add a competitor advertiser"
      id="add-social-account-modal"
      modalToggler={
        <Button variant="contained" color="primary" startIcon={<AddIcon />} size="large">
          Add Competitor
        </Button>
      }
      submitTitle="Add"
      fullWidth
      maxWidth="sm"
      loading={loading}
      onSubmit={formik.handleSubmit}
      onClose={formik.resetForm}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="search">Search for advertiser</InputLabel>
            <Input
              id="search"
              name="search"
              type="text"
              value={formik.values.search}
              aria-describedby="search"
              error={Boolean(formik.errors.search)}
              onChange={formik.handleChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {loading && <LoaderDots />}
          <FormControl fullWidth>
            <InputLabel id="competitor-label" style={{width: '100%'}}>
              <Box sx={{display: 'flex'}}>
                <Box sx={{flexGrow: 1}}>
                  <span>Select an advertiser</span>
                </Box>
                <Box marginRight={3}>
                  <Typography variant="caption">
                    ( showing {data?.advertisers.edges.length ?? 0} of{' '}
                    {data?.advertisers.count ?? 0} )
                  </Typography>
                </Box>
              </Box>
            </InputLabel>
            <Select
              id="competitor"
              name="competitor"
              labelId="competitor-label"
              value={formik.values.competitor}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.competitor)}
              required
              fullWidth
            >
              {data?.advertisers.edges.map((edge: AdvertiserEdge) => {
                const {node} = edge;
                return node?.id !== advertiserId && renderSelectMenuItem(node);
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormControlLabel
              label="The current advertiser is also a competitor of the selected advertiser"
              aria-label="bidirectional competitor checkbox"
              control={
                <Checkbox
                  id="bidirectional"
                  checked={formik.values.bidirectional}
                  onChange={formik.handleChange}
                  disabled={formik.values.competitor === ''}
                />
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  );
};

const AdvertisersQuery = gql`
  query Advertisers($search: String, $archived: Boolean) {
    advertisers(search: $search, archived: $archived, first: 25) {
      count
      edges {
        node {
          id
          name
          domain
          profilePicture
          primaryRegion {
            id
            countryCode
            country
          }
        }
      }
    }
  }
`;

export default AddAdvertiserCompetitorModal;
