import {Icon} from '@iconify/react';
import {Box, Tooltip, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {black80} from 'consts/brand.integration';
import {PlatformsConfig} from 'consts/platforms';
import React from 'react';

export type PlatformWithLabelSize = 'small' | 'medium';

interface Props {
  platform: 'instagram' | 'tiktok' | 'youtube' | 'twitch';
  label?: string;
  tooltip?: string;
  color?: 'primary' | 'secondary';
  size?: PlatformWithLabelSize;
}

const PlatformWithLabel: React.FC<Props> = ({platform, label, tooltip, color, size}) => {
  const classes = useStyles({color, size});
  const platformConfig = PlatformsConfig[platform];
  return (
    <Tooltip title={tooltip ?? platformConfig.name} arrow>
      <Box className={classes.root}>
        <Icon
          icon={platformConfig.iconMono}
          fontSize={size === 'small' ? 16 : 18}
          color={black80}
        />
        {label && (
          <Typography className={classes.label} variant={size === 'small' ? 'caption' : 'body2'}>
            {label}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: (props: any) => (props.color === 'secondary' ? '#4E4E5A' : '#000000'),
    fontSize: (props: any) => (props.size === 'small' ? '14px' : '16px'),
  },
  label: {
    overflow: 'hidden',
    marginLeft: '0.3rem',
    textOverflow: 'ellipsis',
  },
});

export default PlatformWithLabel;
