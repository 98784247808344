import {gql} from '@apollo/client';
import {Campaign} from 'services/fragments';

export const CampaignCardFragment = gql`
  fragment CampaignCardCampaign on Campaign {
    id
    archived
    brandSafety
    brandMatch
    hasNda
    rewardModel
    created
    started
    name
    state
    isFullyReserved
    price {
      formattedValue
    }
    advertiser {
      id
      name
      domain
      profilePicture
    }
    photos {
      coverPhoto {
        url
      }
    }
    ...targetFields
    tags
    posts {
      id
      postType
    }
  }
  ${Campaign.targetFragment}
`;
