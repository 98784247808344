import {
  Box,
  Button,
  DialogContentText,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
} from '@material-ui/core';
import {Modal} from 'components/Widgets';
import {ModalElement} from 'components/Widgets/Modal';
import {useFormik} from 'formik';
import * as yup from 'yup';
import React, {useRef, useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {Alert} from '@material-ui/lab';

const CreateInfluencerProfile: React.FC = () => {
  const [createInfluencerAndUser, {loading, error}] = useMutation(CreateInfluencerAndUserMutation);
  const [newProfileUrl, setNewProfileUrl] = useState('');
  const modal = useRef<ModalElement>(null);

  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      instagramHandle: '',
      tiktokHandle: '',
    },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      fullName: yup.string().required(),
      instagramHandle: yup.lazy(() => {
        return formik.values.tiktokHandle.length
          ? yup.string().notRequired()
          : yup.string().required();
      }),
      tiktokHandle: yup.lazy(() => {
        return formik.values.instagramHandle.length
          ? yup.string().notRequired()
          : yup.string().required();
      }),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    const response = await createInfluencerAndUser({
      variables: formik.values,
    });

    if (response.data?.createInfluencerAndUser?.ok) {
      setNewProfileUrl(
        `${window.location.origin}/influencers/${response.data.createInfluencerAndUser.influencer.id}/settings`
      );
    }
  };

  const onOpenNewProfile = () => {
    window.open(newProfileUrl, '_blank');
  };

  const onClose = () => {
    formik.resetForm();
    setNewProfileUrl('');
    modal.current?.close();
  };

  return (
    <Modal
      ref={modal}
      id="create-influencer-profile"
      title="Create Influencer Profile"
      modalToggler={
        <Button variant="outlined" color="primary" size="large">
          <Typography variant="button">Create Influencer Account</Typography>
        </Button>
      }
      submitTitle="Create New Account"
      fullWidth
      maxWidth="xs"
      showClose
      onSubmit={formik.submitForm}
      onClose={onClose}
      loading={loading}
    >
      <DialogContentText>
        <Typography variant="body2" color="primary">
          You can create a new Influencer account using this form.
        </Typography>
        <Typography variant="body2" color="primary">
          Please remember that the Influencer will still have to complete onboarding using the
          TAKUMI mobile App before you can add them as participants in a campaign.
        </Typography>
        <br />
        <Typography variant="body2" color="error">
          Newly created Influencer accounts are not imediately available in the influencer search,
          so we'll give you a direct link to the new profile below.
        </Typography>
        <br />
        {newProfileUrl !== '' && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" color="primary">
              Profile created successfuly:
            </Typography>
            <Button variant="outlined" color="primary" size="small" onClick={onOpenNewProfile}>
              <Typography variant="body2" color="primary">
                Open New Profile
              </Typography>
            </Button>
            <br />
            <br />
          </Box>
        )}
        <FormControl fullWidth>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            id="email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            required
          />
          <FormHelperText error={formik.errors.email?.length}>
            {formik.errors.email?.length ? 'is a required field' : ''}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="full-name">Full name</InputLabel>
          <Input
            id="full-name"
            name="fullName"
            type="text"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            required
          />
          <FormHelperText error={formik.errors.fullName?.length}>
            {formik.errors.fullName?.length ? 'is a required field' : ''}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="instagram-handle">Instagram handle</InputLabel>
          <Input
            id="instagram-handle"
            name="instagramHandle"
            type="text"
            value={formik.values.instagramHandle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormHelperText error={formik.errors.instagramHandle?.length}>
            {formik.errors.instagramHandle?.length ? 'is a required field' : ''}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="tiktok-handle">TikTok handle</InputLabel>
          <Input
            id="tiktok-handle"
            name="tiktokHandle"
            type="text"
            value={formik.values.tiktokHandle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormHelperText error={formik.errors.tiktokHandle?.length}>
            {formik.errors.tiktokHandle?.length ? 'is a required field' : ''}
          </FormHelperText>
        </FormControl>
        {error && (
          <>
            <br />
            <br />
            <Alert severity="error">{error?.message}</Alert>
          </>
        )}
      </DialogContentText>
    </Modal>
  );
};

const CreateInfluencerAndUserMutation = gql`
  mutation CreateInfluencerAndUserMutation(
    $email: String!
    $fullName: String
    $instagramHandle: String
    $tiktokHandle: String
  ) {
    createInfluencerAndUser(
      email: $email
      fullName: $fullName
      instagramHandle: $instagramHandle
      tiktokHandle: $tiktokHandle
    ) {
      ok
      influencer {
        id
      }
    }
  }
`;

export default CreateInfluencerProfile;
