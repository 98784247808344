import compose from 'lodash/flowRight';
import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';
import EditorJS from '@editorjs/editorjs';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import {Box, Button, ButtonGroup} from '@material-ui/core';

import {MaterialDesignIcon} from 'components/Icons';

import {printPdf} from 'utils/downloadPdf';

import DosAndDonts from './DosAndDonts';
import Header from './Header';
import Important from './Important';
import Divider from './Divider';
import {deserialize} from '../helpers';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import {getPostTypeFormatted} from '../../../../consts/campaignPostType';

import styles from './style.css';

const tools = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  header: Header,
  list: List,
  dosAndDonts: DosAndDonts,
  divider: Divider,
  important: Important,
};

class Editor extends React.Component {
  static propTypes = {
    holderId: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    initialValue: PropTypes.array,
    placeholder: PropTypes.string,
    briefTemplates: PropTypes.array,
    documentTitle: PropTypes.string,
  };

  editorRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {holderId = 'editor-js', initialValue, placeholder} = this.props;
    const {onReady, onChange} = this;
    this.editor = new EditorJS({
      holder: holderId,
      data: {blocks: initialValue},
      onReady,
      onChange,
      placeholder,
      tools,
    });
    this.setState({showTemplateMenu: initialValue.length === 0});
  }

  replaceContent(content) {
    this.editor.blocks.render({blocks: content});
  }

  clearContent() {
    this.replaceContent([]);
    this.setState({showTemplateMenu: true});
  }

  setTemplate(template) {
    this.replaceContent(deserialize(template.template));
    this.setState({showTemplateMenu: false});
  }

  onReady = () => {
    const {onReady} = this.props;
    if (onReady) onReady();
  };

  onChange = async () => {
    const {onChange} = this.props;
    const data = await this.editor.save();

    if (onChange) onChange(data.blocks);
  };

  render() {
    const {
      holderId = 'editor-js',
      briefTemplates,
      documentTitle,
      useYoutubeIntegration,
      useTwitchIntegration,
    } = this.props;
    const {showTemplateMenu} = this.state;

    const templateSelector = briefTemplates?.map((template, i) => {
      if (!useYoutubeIntegration && String(template.type).toLowerCase().includes('youtube'))
        return null;
      if (!useTwitchIntegration && String(template.type).toLowerCase().includes('twitch'))
        return null;
      return (
        <div key={i} className={styles.templateItem} onClick={() => this.setTemplate(template)}>
          <MaterialDesignIcon icon="subject" />
          <div className={styles.templateText}>
            {getPostTypeFormatted(template.type.toLowerCase().replace(' ', '_'))}
          </div>
        </div>
      );
    });

    return (
      <>
        {showTemplateMenu && (
          <div>
            <div className={styles.templateInstructions} data-testid="choose-brief-template">
              Please choose a template for the brief:
            </div>
            {templateSelector}
          </div>
        )}
        <Box display="flex" justifyContent="flex-end">
          <ButtonGroup>
            {!showTemplateMenu && (
              <Button data-testid="clear-button" onClick={() => this.clearContent()}>
                Clear
              </Button>
            )}
            {!showTemplateMenu && (
              <Button
                data-testid="download-pdf-button"
                onClick={() => printPdf(holderId, documentTitle)}
              >
                Download PDF
              </Button>
            )}
          </ButtonGroup>
        </Box>
        <div ref={this.editorRef} style={showTemplateMenu ? {display: 'none'} : {}} id={holderId} />
      </>
    );
  }
}

const BriefTemplatesQuery = gql`
  query BriefTemplatesQuery {
    postBriefTemplates {
      type
      ... on BriefTemplate {
        template {
          type
          ... on BriefHeading {
            value
          }
          ... on BriefSubHeading {
            value
          }
          ... on BriefParagraph {
            value
          }
          ... on BriefImportant {
            value
          }
          ... on BriefDosAndDonts {
            dos
            donts
          }
          ... on BriefOrderedList {
            items
          }
          ... on BriefUnorderedList {
            items
          }
        }
      }
    }
  }
`;

const EditorComposed = compose(
  graphql(BriefTemplatesQuery, {
    props: ({data}) => {
      return {
        briefTemplates: data.postBriefTemplates,
      };
    },
  })
)(Editor);

const WrappedComponent = ({...props}) => {
  const {useYoutubeIntegration, useTwitchIntegration} = useFeatureFlags();
  return <EditorComposed {...{useYoutubeIntegration, useTwitchIntegration, ...props}} />;
};

export default WrappedComponent;
