import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Alert } from '@material-ui/lab';
import {Grid} from '@material-ui/core';

import {ProfilePicture, MediaPreview, Definition, RelativeDate} from 'components/Widgets';

import {numberWithCommas} from 'consts/utilities';

import GigCaption from '../../../../../components/Campaign/GigCaption';
import styles from './style.css';

const Content = ({content}) => {
  const story = content?.__typename === 'InstagramStory';
  const gigClasses = [styles.gigImageWrapper, story ? styles.storyWrapper : styles.postWrapper];
  const size = story ? 585 : 350;

  if (content?.media?.__typename === 'Gallery') {
    return (
      <div className={styles.gallery}>
        {content?.media?.items.map(media => (
          <div key={media.id} className={classNames(gigClasses)}>
            <MediaPreview media={media} height={size} width={size} />
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className={styles.gallery}>
        <div className={classNames(gigClasses)}>
          {content.media && <MediaPreview media={content.media} height={size} width={size} />}
        </div>
      </div>
    );
  }
};

Content.propTypes = {
  content: PropTypes.object,
};

const ReportGigItem = ({ gig }) => {

  if (!gig) {
    return (
      <Alert severity='error'>No Gig id!</Alert>
    )
  }

  return (
  <div className={styles.root}>
    <div className={styles.details}>
      <div className={styles.influencerBox}>
        <ProfilePicture
          className={styles.userProfilePicture}
          size={50}
          src={gig.influencer?.profilePicture}
        />
        <div>
          <p className={styles.influencerUsername}>{gig.influencer?.fullName}</p>
          <p className={styles.influencerFollowers}>
            {numberWithCommas(gig.instagramContent.followers)} followers
          </p>
        </div>
        <p className={styles.postDate}>
          Posted <RelativeDate date={gig.instagramContent.posted || gig.instagramContent.created} />
        </p>
      </div>
      {gig.instagramContent.__typename === 'InstagramPost' && (
        <div>
          <div className={styles.caption}>
            <GigCaption
              caption={gig.instagramContent.caption}
              hashtags={gig.instagramContent.hashtags}
              mentions={gig.instagramContent.mentions}
            />
          </div>

          <div className={classNames(styles.stats, styles.performance)}>
            <div className={styles.heading}>Post Performance</div>
              <Grid container spacing={2}>
                <Grid item>
                  <Definition
                    gold
                    title="Likes"
                    definition={numberWithCommas(gig.instagramContent.likes)}
                  />
                </Grid>
                <Grid item>
                  <Definition
                    gold
                    title="Comments"
                    definition={numberWithCommas(gig.instagramContent.comments)}
                  />
                </Grid>
                <Grid item>
                  <Definition
                    title="Engagement"
                    gold
                    definition={<div>{gig.instagramContent.engagement?.formattedValue}</div>}
                  />
                </Grid>
                {!!gig.instagramContent.videoViews && (
                  <Definition
                    title="Video views"
                    gold
                    definition={numberWithCommas(gig.instagramContent.videoViews)}
                  />
                )}  
              </Grid>
          </div>
        </div>
      )}
      <Content content={gig.instagramContent} />
    </div>
  </div>
)};

ReportGigItem.propTypes = {
  gig: PropTypes.object,
};

export default ReportGigItem;
