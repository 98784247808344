import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent} from '@material-ui/core';
import {AxiosError} from 'axios';

import {ApiError, SortableTable, LoaderDots} from 'components/Widgets';

import useFeatureFlags from 'hooks/useFeatureFlags';

import PlanningTable from './PlanningTable';
import ContentTable from './ContentTable';
import {CalendarRow, PlanningRow} from './types';

interface Props {
  calendar: CalendarRow[];
  campaign: PlanningRow;
  loading: boolean;
  error: AxiosError | null;
  timezone: string;
}

const CampaignCalendar: React.FC<Props> = ({calendar, campaign, timezone, loading, error}) => {
  const classes = useStyles();
  const {showContentPlanningTable} = useFeatureFlags();

  const getPlaceholderData = (campaign: PlanningRow) => {
    if (!showContentPlanningTable) {
      return [{creatorNumber: 1, post: {postType: '', deadline: ''}, postTypeIndex: 1}];
    }

    const {posts, target} = campaign;
    const numRows = target.targetOverall;
    return Array.from({length: numRows}).flatMap((_, i) =>
      posts.map((post, postIndex) => ({
        creatorNumber: i + 1,
        post: post,
        postTypeIndex: postIndex + 1,
      }))
    );
  };

  const headCells = [
    {id: 'influencer_full_name', numeric: false, label: 'Creator'},
    {id: 'full_name', numeric: false, label: 'Deliverable'},
    {id: 'date_submitted', numeric: true, label: 'Submission Date'},
    {id: 'date_posted_to_social_media_platform', numeric: true, label: 'Live Date'},
    {id: 'post_deadline', numeric: true, label: 'Post Deadline'},
  ];

  if (loading) {
    return <LoaderDots className={classes.isLoading} />;
  }

  if (error) {
    return <ApiError />;
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <>
            {calendar && (
              <SortableTable
                rows={calendar.length ? calendar : getPlaceholderData(campaign)}
                headCells={headCells}
                sortMetric="engagement_rate_by_impressions"
                ariaLabel="Content Calendar Table"
                renderRow={(row: any) => {
                  return calendar.length ? (
                    <ContentTable row={row} timezone={timezone} />
                  ) : (
                    <PlanningTable row={row} timezone={timezone} />
                  );
                }}
              />
            )}
          </>
        </CardContent>
      </Card>
    </>
  );
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'table',
    tableLayout: 'fixed',
    overflowX: 'auto',
    marginTop: '20px',
  },
  isLoading: {
    margin: '0 auto',
  },
});

export default CampaignCalendar;
