import React from 'react';
import PlatformWithLabel from '../PlatformWithLabel/PlatformWithLabel';

interface LinkProps {
  username: string;
}

export const InstagramLink: React.FC<LinkProps> = ({username}: {username: string}) => {
  return username ? (
    <a href={`https://www.instagram.com/${username}/`} target="_blank" rel="noopener noreferrer">
      <PlatformWithLabel platform="instagram" label={username} />
    </a>
  ) : null;
};

export const TikTokLink: React.FC<LinkProps> = ({username}: {username: string}) => {
  return username ? (
    <a href={`https://www.tiktok.com/@${username}/`} target="_blank" rel="noopener noreferrer">
      <PlatformWithLabel platform="tiktok" label={username} />
    </a>
  ) : null;
};

export const YouTubeLink: React.FC<LinkProps> = ({customUrl}: {customUrl: string}) => {
  const handle = customUrl?.split('/').pop() ?? '';
  return customUrl ? (
    <a href={`https://www.youtube.com/${handle}/`} target="_blank" rel="noopener noreferrer">
      <PlatformWithLabel platform="youtube" label={handle} />
    </a>
  ) : null;
};
