import {useState, useCallback} from 'react';
import axios, {AxiosError} from 'axios';

import ErrorReporting from 'services/error-reporting';

import {
  CampaignPerformanceResponse,
  CampaignPerformanceHistoricalDataResponse,
  CampaignPerformanceByInfluencerResponse,
  CampaignPlatformPerformanceResponse,
  CampaignPerformanceByGigResponse,
  CampaignPaidPerformanceResponse,
  CampaignTikTokPostPaidAdPerformanceResponse,
  CampaignInstagramPaidAdPerformanceResponse,
  CampaignPaidAdsPerformanceResponse,
  InfluencerPerformanceByPlatformResponse,
  InfluenscoreCampaignV1BetaResponse,
} from './types';

const apiEndpoint = `https://${process.env.DATA_SERVICE_API}`;

interface CommonParams {
  apiToken: string;
  select?: string;
  orderBy?: string;
  offset?: number;
  limit?: number;
}

interface CampaignPerformanceParams extends CommonParams {
  campaignId?: string;
  campaignIds?: string;
  advertiserId?: string;
}

interface InfluencerPerformanceParams extends CommonParams {
  influencerId?: string;
  influencerIds?: string;
}

interface InfluenscoreCampaignV1BetaParams extends CommonParams {
  campaignId?: string;
}

type UseDataServiceApi = {
  loading: boolean;
  error: AxiosError | null;
  fetchContentCalendarData: <T>(params: CampaignPerformanceParams) => Promise<T | undefined>;
  fetchCampaignPerformanceData: (
    params: CampaignPerformanceParams
  ) => Promise<CampaignPerformanceResponse | undefined>;
  fetchCampaignPerformanceHistoricalData: (
    params: CampaignPerformanceParams
  ) => Promise<CampaignPerformanceHistoricalDataResponse | undefined>;
  fetchCampaignPerformanceByInfluencerData: (
    params: CampaignPerformanceParams
  ) => Promise<CampaignPerformanceByInfluencerResponse | undefined>;
  fetchCampaignPerformanceByPlatformData: (
    params: CampaignPerformanceParams
  ) => Promise<CampaignPlatformPerformanceResponse | undefined>;
  fetchCampaignPerformanceByGigData: (
    params: CampaignPerformanceParams
  ) => Promise<CampaignPerformanceByGigResponse | undefined>;
  fetchCampaignPaidPerformanceByCampaign: (
    params: CampaignPerformanceParams
  ) => Promise<CampaignPaidPerformanceResponse | undefined>;
  fetchCampaignPaidPerformanceByTikTokAds: (
    params: CampaignPerformanceParams
  ) => Promise<CampaignTikTokPostPaidAdPerformanceResponse | undefined>;
  fetchCampaignPaidPerformanceByInstagramAds: (
    params: CampaignPerformanceParams
  ) => Promise<CampaignInstagramPaidAdPerformanceResponse | undefined>;
  fetchCampaignPaidPerformanceByAds: (
    params: CampaignPerformanceParams
  ) => Promise<CampaignPaidAdsPerformanceResponse | undefined>;
  fetchAdvertiserPerformance: <T>(params: CampaignPerformanceParams) => Promise<T | undefined>;
  fetchInfluencerPerformanceByPlatform: (
    params: InfluencerPerformanceParams
  ) => Promise<InfluencerPerformanceByPlatformResponse | undefined>;
  fetchInfluenscoreCampaignV1Beta: (
    params: InfluenscoreCampaignV1BetaParams
  ) => Promise<InfluenscoreCampaignV1BetaResponse | undefined>;
};

export const useDataServiceApi = (): UseDataServiceApi => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  const genericFetchData = async <T>(
    resource: string,
    filters: any,
    params: CommonParams
  ): Promise<T> => {
    const {apiToken, offset, limit, orderBy, ...otherParams} = params;

    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        params: {
          ...filters,
          order: orderBy,
          offset,
          limit,
          ...otherParams,
        },
      };
      const result = await axios.get<T>(`${apiEndpoint}/${resource}`, config);
      setLoading(false);
      return result.data;
    } catch (err) {
      setLoading(false);
      setError(err as AxiosError);
      ErrorReporting.captureError(err as AxiosError);
      return [] as unknown as T;
    }
  };

  const fetchCampaignData = useCallback(
    async <T>(resource: string, params: CampaignPerformanceParams) => {
      const {campaignId, campaignIds, advertiserId, ...otherParams} = params;
      const isCampaignFilter = !!campaignId || !!campaignIds;
      const filterParams = {
        campaign_id: isCampaignFilter
          ? `in.(${[campaignIds, campaignId].filter(Boolean).join(',')})`
          : undefined,
        advertiser_id: advertiserId ? `eq.${advertiserId}` : undefined,
      };
      return await genericFetchData<T>(resource, filterParams, otherParams);
    },
    []
  );

  const fetchInfluencerData = useCallback(
    async <T>(resource: string, params: InfluencerPerformanceParams) => {
      const {influencerId, influencerIds, ...otherParams} = params;
      const filterParams = {
        influencer_id: `in.(${[influencerIds, influencerId].filter(Boolean).join(',')})`,
      };
      return await genericFetchData<T>(resource, filterParams, otherParams);
    },
    []
  );

  const fetchContentCalendarData = useCallback(
    <T>(params: CampaignPerformanceParams) =>
      fetchCampaignData<T>('campaign_content_calendar', params),
    [fetchCampaignData]
  );
  const fetchCampaignPerformanceData = useCallback(
    (params: CampaignPerformanceParams) =>
      fetchCampaignData<CampaignPerformanceResponse>('campaign_performance', params),
    [fetchCampaignData]
  );
  const fetchCampaignPerformanceHistoricalData = useCallback(
    (params: CampaignPerformanceParams) =>
      fetchCampaignData<CampaignPerformanceHistoricalDataResponse>(
        'campaign_performance_history',
        params
      ),
    [fetchCampaignData]
  );
  const fetchCampaignPerformanceByPlatformData = useCallback(
    (params: CampaignPerformanceParams) =>
      fetchCampaignData<CampaignPlatformPerformanceResponse>(
        'campaign_performance_by_platform',
        params
      ),
    [fetchCampaignData]
  );
  const fetchCampaignPerformanceByInfluencerData = useCallback(
    (params: CampaignPerformanceParams) =>
      fetchCampaignData<CampaignPerformanceByInfluencerResponse>(
        'campaign_performance_by_influencer',
        params
      ),
    [fetchCampaignData]
  );
  const fetchCampaignPerformanceByGigData = useCallback(
    (params: CampaignPerformanceParams) =>
      fetchCampaignData<CampaignPerformanceByGigResponse>('campaign_performance_by_gig', params),
    [fetchCampaignData]
  );
  const fetchCampaignPaidPerformanceByCampaign = useCallback(
    (params: CampaignPerformanceParams) =>
      fetchCampaignData<CampaignPaidPerformanceResponse>('paid_ad_performance_by_campaign', params),
    [fetchCampaignData]
  );
  const fetchCampaignPaidPerformanceByTikTokAds = useCallback(
    (params: CampaignPerformanceParams) =>
      fetchCampaignData<CampaignTikTokPostPaidAdPerformanceResponse>(
        'paid_ad_performance_tiktok_post_by_campaign',
        params
      ),
    [fetchCampaignData]
  );
  const fetchCampaignPaidPerformanceByInstagramAds = useCallback(
    (params: CampaignPerformanceParams) =>
      fetchCampaignData<CampaignInstagramPaidAdPerformanceResponse>(
        'paid_ad_performance_instagram_by_campaign',
        params
      ),
    [fetchCampaignData]
  );
  const fetchCampaignPaidPerformanceByAds = useCallback(
    (params: CampaignPerformanceParams) =>
      fetchCampaignData<CampaignPaidAdsPerformanceResponse>('paid_ad_performance_by_ad', params),
    [fetchCampaignData]
  );
  const fetchAdvertiserPerformance = useCallback(
    <T>(params: CampaignPerformanceParams) =>
      fetchCampaignData<T>('campaign_performance_by_advertiser', params),
    [fetchCampaignData]
  );

  const fetchInfluencerPerformanceByPlatform = useCallback(
    <T>(params: InfluencerPerformanceParams) =>
      fetchInfluencerData<T>('influencer_performance_by_platform', params),
    [fetchInfluencerData]
  );

  const fetchInfluenscoreCampaignV1Beta = useCallback(
    <T>(params: InfluenscoreCampaignV1BetaParams) =>
      fetchCampaignData('influenscore_campaign_v1beta', params),
    [fetchCampaignData]
  );

  return {
    loading,
    error,
    fetchContentCalendarData,
    fetchCampaignPerformanceData,
    fetchCampaignPerformanceHistoricalData,
    fetchCampaignPerformanceByPlatformData,
    fetchCampaignPerformanceByInfluencerData,
    fetchCampaignPerformanceByGigData,
    fetchCampaignPaidPerformanceByCampaign,
    fetchCampaignPaidPerformanceByTikTokAds,
    fetchCampaignPaidPerformanceByInstagramAds,
    fetchCampaignPaidPerformanceByAds,
    fetchAdvertiserPerformance,
    fetchInfluencerPerformanceByPlatform,
    fetchInfluenscoreCampaignV1Beta,
  };
};
