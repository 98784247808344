import React, {useState, useEffect} from 'react';
import {Divider, Grid, Typography, makeStyles} from '@material-ui/core';

import {ApiError, LoaderDots} from 'components/Widgets';

import useCurrentUser from 'hooks/useCurrentUser';
import {useDataServiceApi} from 'hooks/useDataServiceApi';
import type {CampaignPaidPerformanceResponse} from 'hooks/useDataServiceApi/types';

import PaidSummaryMetrics from './PaidSummaryMetrics';
import {DataExport} from '../types';

interface Props {
  campaign?: Campaign;
  apiData?: (data: DataExport) => void;
}

const CampaignPaidPerformanceSummary: React.FC<Props> = ({campaign, apiData}) => {
  const user = useCurrentUser();
  const classes = useStyles();
  const {error, loading, fetchCampaignPaidPerformanceByCampaign} = useDataServiceApi();
  const [resourceData, setResourceData] = useState<CampaignPaidPerformanceResponse | null>(null);
  const title = 'Paid Media';

  useEffect(() => {
    const fetchData = async () => {
      if (user?.takumiDataApiToken && campaign?.id) {
        const data = await fetchCampaignPaidPerformanceByCampaign({
          apiToken: user?.takumiDataApiToken || '',
          campaignId: campaign?.id || '',
        });
        setResourceData(data);
        apiData(data);
      }
    };
    fetchData();
  }, [user?.takumiDataApiToken, campaign?.id, fetchCampaignPaidPerformanceByCampaign]);

  const modelName = 'paid_performance';
  const apiResponse = resourceData?.[0];
  const currency = campaign?.marketSlug;
  const summaryMetrics = apiResponse
    ? [
        {
          impression_total: apiResponse.impression_cumulative_total,
          engagement_total: apiResponse.engagement_cumulative_total,
          video_play_cumulative_total:
            (apiResponse.video_view_2_second_cumulative_total ?? 0) +
            (apiResponse.video_view_3_second_cumulative_total ?? 0),
          engagement_rate_by_impressions_average:
            apiResponse.engagement_rate_by_impressions_average,
          click_through_rate_average: apiResponse.click_through_rate_average,
          view_through_rate_average: apiResponse.view_through_rate_average,
        },
      ]
    : [];

  if (loading) {
    return <LoaderDots className={classes.spacing} />;
  }

  if (error) {
    return <ApiError title={title} classes={classes} />;
  }

  if (!apiResponse) {
    return null;
  }

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        {title}
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container spacing={3} aria-label={`${title} Performance Metrics`}>
        <PaidSummaryMetrics
          summaryMetrics={summaryMetrics}
          modelName={modelName}
          enabledMetrics={(campaign?.paidPerformanceMetrics ?? []) as string[]}
        />
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default CampaignPaidPerformanceSummary;
