import React from 'react';
import {Icon as IconifyIcon} from '@iconify/react';
import {Box, Grid, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {numberMillified, numberWithCommas} from 'consts/utilities';
import {black50} from 'consts/brand.integration';
import {isNumber} from 'lodash/fp';

interface Props {
  data: YoutubeVideo;
}

const YoutubePostStats: React.FC<Props> = ({data}) => {
  const classes = useStyles();
  return (
    <>
      {isNumber(data.viewCount) && (
        <Grid item>
          <Tooltip title={`Views - ${numberWithCommas(data.viewCount)}`} placement="bottom" arrow>
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:remove-red-eye" />
              <span className={classes.value}>{numberMillified(data.viewCount)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(data.commentCount) && (
        <Grid item>
          <Tooltip
            title={`Comments - ${numberWithCommas(data.commentCount)}`}
            placement="bottom"
            arrow
          >
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:chat" />
              <span className={classes.value}>{numberMillified(data.commentCount)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(data.likeCount) && (
        <Grid item>
          <Tooltip title={`Likes - ${numberWithCommas(data.likeCount)}`} placement="bottom" arrow>
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:heart" />
              <span className={classes.value}>{numberMillified(data.likeCount)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(data.engagementRateBySubscribers) && (
        <Grid item>
          <Tooltip
            title={`Engagement rate by subscribers - ${numberWithCommas(
              data.engagementRateBySubscribers
            )}%`}
            placement="bottom"
            arrow
          >
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:graph-line" />
              <span className={classes.value}>
                {numberWithCommas(data.engagementRateBySubscribers)}%
              </span>
            </Box>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};

const Icon = ({icon}: {icon: string}): JSX.Element => <IconifyIcon icon={icon} color={black50} />;

const useStyles = makeStyles({
  iconWithValue: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '.6rem',
  },
  value: {
    marginLeft: '0.2rem',
    color: black50,
  },
});

export default YoutubePostStats;
