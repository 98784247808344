import React from 'react';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';

import {Media, MediaContainer, FormattedDate} from '../../../../../../components/Widgets';
import {useStyles} from './styles';

interface Props {
  gig: any;
  children: any;
  isStory?: boolean;
}

const Header: React.FC<Props> = ({gig, children, isStory}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MediaContainer width={200} height={200}>
        {gig.instagramContent?.media && (
          <Media
            alt={`${isStory ? 'Story' : 'Post'} for ${gig?.offer?.campaign?.name}`}
            media={gig.instagramContent.media}
            width={200}
            height={200}
            autoPlay={false}
            galleryDotsStyle={classes.dots}
            galleryDotStyle={classes.dot}
            galleryActiveDotStyle={classes.activeDot}
          />
        )}
      </MediaContainer>
      <Typography variant="caption" className={classes.posted}>
        Posted on <FormattedDate date={gig.instagramContent?.posted} format="Do MMMM, YYYY" />
      </Typography>
      <div
        className={classNames(classes.summary, {
          [classes.isStorySummary]: isStory,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Header;
