import {gql, useLazyQuery, useReactiveVar} from '@apollo/client';
import {useEffect, useState} from 'react';

import ErrorReporting from '../services/error-reporting';
import {isLoggedIn} from '../services/auth';

const useCurrentUser = () => {
  const loggedIn = useReactiveVar(isLoggedIn);
  const [getCurrentUser, {error, data}] = useLazyQuery(CurrentUserQuery);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    if (loggedIn) {
      getCurrentUser();
    } else {
      setCurrentUser(null);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (error) {
      ErrorReporting.captureError(error);
    }

    if (data?.currentUser) {
      setCurrentUser(data.currentUser);
    }
  }, [error, data]);

  return currentUser;
};

const CurrentUserQuery = gql`
  query CurrentUserQuery {
    currentUser {
      id
      email
      roleName
      fullName
      profilePicture
      birthday
      hasInvitationSent
      hasAcceptedInvite
      takumiDataApiToken
    }
  }
`;

export default useCurrentUser;
