import React from 'react';
import {useHistory, useLocation, useRouteMatch, withRouter} from 'react-router-dom';
import {gql, useQuery, useMutation} from '@apollo/client';
import * as qs from 'query-string';

import App404 from '../../App404/App404';
import {LoaderDots, Select, ExportCSV, Prompt, Button, Tooltip} from '../../../components/Widgets';
import {Filters, ListOffers} from './components';
import {numberWithCommas} from '../../../consts/utilities';
import styles from './style.css';
import {MaterialDesignIcon} from '../../../components/Icons';
import {CampaignHeader} from '../components';
import InfluencerProfileCard from 'components/Widgets/InfluencerProfileCard/InfluencerProfileCard';

interface Props {
  data: {
    loading: boolean;
    campaign?: Campaign[];
  };
}

const CampaignPrompts: React.FC<Props> = () => {
  const history = useHistory();
  const location = useLocation();
  const {params}: {params: {advertiser: string; campaign: string}} = useRouteMatch();
  const campaignResult = useQuery(CampaignPromptsQuery, {
    variables: {campaignId: params.campaign},
  });

  const query = qs.parse(location.search);
  const filter = query.state || 'accepted';

  const offersAnswers = useQuery(OfferAnswersQuery, {
    variables: {
      campaignId: params.campaign,
      state: filter,
      answer: query.prompt && query.answer ? {prompt: query.prompt, answer: [query.answer]} : null,
    },
  });
  const [revokeOffer] = useMutation(RevokeOfferMutation, {
    refetchQueries: ['CampaignPromptsQuery'],
  });

  if (campaignResult.loading) {
    return <LoaderDots />;
  }

  const campaign = campaignResult?.data?.campaign;
  if (!campaign) {
    return <App404 />;
  }

  const onRejectOffer = (id: string) =>
    revokeOffer({
      variables: {
        id,
      },
    });

  const prompt =
    campaign.prompts.find((p: CampaignPrompt) => p.text === query.prompt) || campaign.prompts[0];

  const campaignFilters = [campaign.applyFirst ? 'requested' : 'invited']
    .concat(campaign.brandMatch ? ['candidate', 'approved_by_brand'] : [])
    .concat(['accepted', 'rejected']);

  const columns = [
    {
      heading: 'Influencer',
      data: (offer: Offer): Offer => offer,
      render: (offer: Offer) => <InfluencerProfileCard influencer={offer.influencer} showEmail />,
    },
    {
      heading: 'Followers',
      data: (offer: Offer): number => offer.reach,
      render: (reach: number): string => numberWithCommas(reach),
    },
    {
      heading: 'Answer',
      data: (offer: Offer): CampaignAnswer => {
        const prompt = query.prompt || campaign.prompts.find((p: CampaignPrompt) => p.text).text;
        return (
          offer.answers?.find(a => a?.prompt === prompt) || {
            prompt,
            answer: [''],
          }
        );
      },
      render: (answer: CampaignAnswer): string => answer.answer!.join(', '),
    },
  ];

  if (filter !== 'accepted') {
    columns.push({
      heading: 'Action',
      data: (offer: Offer): Offer => offer,
      render: (offer: Offer) => (
        <Prompt
          title="Revoke Influencer Offer?"
          body="Are you sure you want to revoke this influencer's offer?"
          confirmText="Revoke Offer"
          control={
            <Button
              inline
              danger
              className={styles.actionButton}
              text={
                <Tooltip placement="bottom" overlay={<span>Revoke Offer</span>}>
                  <MaterialDesignIcon icon="remove_circle" />
                </Tooltip>
              }
            />
          }
          onSubmit={() => {
            onRejectOffer(offer.id);
          }}
        />
      ),
    });
  }

  const fetchExportCSVData = async () => {
    const {data} = offersAnswers;

    const offers: Offer[] = data.offers.edges.map((edge: {node: Offer}) => edge.node);
    const result: Record<string, string>[] = [];
    offers.forEach((offer: Offer) => {
      const entry: Record<string, string> = {
        username: offer.influencer?.username,
        email: offer.influencer?.email,
      };
      offer.answers?.map((answer: CampaignAnswer) => {
        entry[answer.prompt] = answer.answer?.join(', ');
      });
      result.push(entry);
    });

    return {
      data: result,
    };
  };

  return (
    <div className={styles.root}>
      <CampaignHeader title="Prompts" campaignId={campaign.id} showProgress />
      {!offersAnswers.loading && offersAnswers.data.offers.count > 0 && (
        <ExportCSV
          text={'Export prompts for state'}
          dataFetcher={() => fetchExportCSVData()}
          filename={`${campaign.name}- Prompts.csv`}
        />
      )}
      <div className={styles.promptFilter}>
        <Select
          label="Prompt"
          value={query.prompt || campaign.prompts.find((p: Prompt) => p.text).text}
          options={
            campaign.prompts
              ? campaign.prompts
                  .filter((p: Prompt) => p.text)
                  .map((p: Prompt) => ({name: p.text, value: p.text}))
              : []
          }
          onChange={(val: string) => {
            delete query.answer;
            history.replace({
              ...location,
              search: qs.stringify({
                ...query,
                prompt: val,
              }),
            });
          }}
        />
        {prompt.type.indexOf('choice') !== -1 && (
          <Select
            label="Answer"
            value={query.answer || ''}
            options={[{name: 'Any', value: ''}].concat(
              prompt.choices.map((a: Prompt) => ({name: a, value: a}))
            )}
            onChange={(val: string) => {
              if (!val && !query.answer) return;
              if (!val) {
                delete query.answer;
                history.replace({
                  ...location,
                  search: qs.stringify({
                    ...query,
                  }),
                });
              } else {
                history.replace({
                  ...location,
                  search: qs.stringify({
                    ...query,
                    answer: val,
                  }),
                });
              }
            }}
          />
        )}
      </div>
      <Filters
        campaignFilters={campaignFilters}
        filterCounts={campaign.participation}
        location={location}
      />
      <ListOffers
        query={OfferAnswersQuery}
        variables={{
          campaignId: campaign.id,
          state: filter,
          answer:
            query.prompt && query.answer ? {prompt: query.prompt, answer: [query.answer]} : null,
        }}
        columns={columns}
        filters={typeof filter === 'string' ? [filter] : filter}
      />
    </div>
  );
};

const CampaignPromptsQuery = gql`
  query CampaignPromptsQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      prompts {
        text
        choices
        type
      }
      applyFirst
      brandMatch
      participation {
        type
        count
      }
    }
  }
`;

const OfferAnswersQuery = gql`
  query OfferAnswersQuery($campaignId: UUIDString!, $state: String, $answer: OfferAnswer) {
    offers: offersForCampaign(id: $campaignId, state: $state, answer: $answer, allResults: true) {
      count
      edges {
        node {
          id
          state
          reach
          answers {
            prompt
            answer
          }
          influencer {
            id
            fullName
            profilePicture
            email
            instagramAccount {
              id
              username
            }
            tiktokAccount {
              id
              username
            }
            targetRegion {
              id
              name
              country
              countryCode
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const RevokeOfferMutation = gql`
  mutation RevokeOfferMutation($id: UUIDString!) {
    revokeOffer(id: $id) {
      ok
      offer {
        id
        state
        formattedState
        revertableState
      }
    }
  }
`;

export default withRouter(CampaignPrompts);
