import React, {ReactNode, useState} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import classNames from 'classnames';
import {Box, Container, Grid, Typography} from '@material-ui/core';

import {Form, FormSubmitButton} from 'components/Widgets/Forms';
import {
  Button,
  Definition,
  FormattedDate,
  HideInDemoMode,
  Internal,
  LoaderDots,
  Prompt,
  TagInput,
} from 'components/Widgets';

import App404 from 'scenes/App404/App404';
import {getBaseURL, getEnvLocationURL} from 'services/api';
import {EDIT_CAMPAIGN, LAUNCH_CAMPAIGN, MANAGE_INFLUENCERS} from 'consts/permissions';
import useRequiresPermission from 'hooks/useRequiresPermission';

import CampaignPost from '../CampaignPost';
import {LaunchCampaign, CampaignChecklist, CampaignHeader, PostAnalysis} from '../components';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import {useStyles} from './styles';
import CampaignStats from './CampaignStats';
import {
  CampaignPostsQuery,
  CreatePostMutation,
  RenewTokenMutation,
  UpdateTagsMutation,
} from './components/queries';
import CopyPasteable from 'components/Widgets/CopyPasteable/CopyPasteable';

const CampaignMetabaseDashboard: React.FC<{campaign: Campaign}> = ({campaign}) => (
  <Container maxWidth="lg">
    <iframe
      src={campaign.dashboardCampaignStatsUrl}
      width="100%"
      height="390"
      title="Campaign stats"
      style={{border: 'none', overflow: 'none'}}
      scrolling="no"
    ></iframe>
  </Container>
);

interface Props {
  children?: ReactNode | ReactNode[];
}

const CampaignPosts: React.FC<Props> = ({children}) => {
  const classes = useStyles();
  const history = useHistory();
  const {params}: {params: {campaign: string}} = useRouteMatch();
  const campaignId = params.campaign;

  const {useMetabaseCampaignStats} = useFeatureFlags();
  const {loading, data} = useQuery(CampaignPostsQuery, {
    variables: {
      campaignId,
    },
  });
  const [renewToken] = useMutation(RenewTokenMutation, {
    variables: {
      campaignId,
    },
  });
  const [updateTags] = useMutation(UpdateTagsMutation);
  const [createPost] = useMutation(CreatePostMutation, {
    variables: {
      campaignId,
    },
    refetchQueries: ['CampaignPostsQuery'],
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [analysisOpen, setAnalysisOpen] = useState(false);

  const canEditCampaign = useRequiresPermission(EDIT_CAMPAIGN);
  const canLaunchCampaign = useRequiresPermission(LAUNCH_CAMPAIGN);
  const canManageInfluencers = useRequiresPermission(MANAGE_INFLUENCERS);

  const toggleAnalysis = () => setAnalysisOpen(!analysisOpen);

  const inputClick = (e: Record<any, any>) => e.target.select();

  const toggleReportModal = () => setModalOpen(!modalOpen);

  const addPost = async () => await createPost();

  const handleUpdateTags = async (tags: string[]) =>
    await updateTags({
      variables: {
        campaignId,
        tags,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateCampaign: {
          __typename: 'UpdateCampaign',
          ok: true,
          campaign: {
            __typename: 'Campaign',
            id: campaignId,
            tags: tags.map(tag => tag.toLowerCase()),
          },
        },
      },
    });

  if (loading || !data) {
    return <LoaderDots />;
  }

  if (!data.campaign) {
    return <App404 />;
  }

  const {campaign} = data;

  const isOverlay = history.location.pathname.includes('gig/');

  const hasAnInstagramPost = campaign.posts.some(
    p => p.postType !== 'tiktok' && p.postType !== 'youtube'
  );

  if (children && !isOverlay) {
    return (
      <div className={classes.root}>
        <CampaignHeader title="Posts" campaignId={campaign.id} />
        {children}
      </div>
    );
  }

  const firstPost = campaign.posts?.[0];

  const earliestDeadline = campaign.posts.reduce(
    (deadline, post) => (Date.parse(post.deadline) < deadline ? post.deadline : deadline),
    firstPost?.deadline
  );

  const firstSubmissionDeadline =
    firstPost?.submissionDeadline || firstPost?.schedule?.submissionDeadline;
  const earliestSubmissionDeadline = campaign.posts.reduce((deadline, post) => {
    const newDeadline = post.submissionDeadline || post.schedule?.submissionDeadline;
    return newDeadline < deadline ? newDeadline : deadline;
  }, firstSubmissionDeadline);

  return (
    <div>
      <CampaignHeader title="Posts" campaignId={campaign.id} showProgress showCompleteCampaign />
      {useMetabaseCampaignStats && <CampaignMetabaseDashboard campaign={campaign} />}
      <div className={classes.campaignInfo}>
        <div className={classes.left}>
          <HideInDemoMode>
            {canEditCampaign && (
              <div className={classes.tags}>
                <TagInput
                  tags={campaign.tags}
                  options={['hero', 'event', 'multipost', 'video', 'story']}
                  placeholder="Add tags..."
                  onChange={handleUpdateTags}
                />
              </div>
            )}
          </HideInDemoMode>
          {campaign.state === 'draft' && (
            <>
              {canEditCampaign && <CampaignChecklist campaign={campaign} />}
              {canLaunchCampaign && <LaunchCampaign campaign={campaign} />}
            </>
          )}
          {!useMetabaseCampaignStats && <CampaignStats campaign={campaign} />}

          {canManageInfluencers && (
            <div className={classes.definitionRow}>
              <Internal>
                <Grid container spacing={2}>
                  <Grid item>
                    <Definition
                      gold={new Date(earliestSubmissionDeadline) >= Date.now()}
                      red={new Date(earliestSubmissionDeadline) < Date.now()}
                      title="Earliest submission deadline"
                      definition={
                        <FormattedDate
                          date={earliestSubmissionDeadline}
                          format="Do MMM, YYYY HH:mm:ss"
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Definition
                      gold={new Date(earliestDeadline) >= Date.now()}
                      red={new Date(earliestDeadline) < Date.now()}
                      title="Earliest deadline"
                      definition={
                        <FormattedDate date={earliestDeadline} format="Do MMM, YYYY HH:mm:ss" />
                      }
                    />
                  </Grid>
                </Grid>
              </Internal>
            </div>
          )}
        </div>
        <div className={classes.right}>
          <div className={classes.links}>
            <div className={classes.textLinks}>
              {hasAnInstagramPost && (
                <Link
                  to={{
                    pathname: `https://download.${getEnvLocationURL()}/download/${
                      campaign.reportToken
                    }`,
                  }}
                  target="_blank"
                >
                  <Typography variant="body1" color="textSecondary">
                    Download posted images
                  </Typography>
                </Link>
              )}
              {campaign.submissionsToken && (
                <Link
                  to={{
                    pathname: `https://download.${getEnvLocationURL()}/submissions/${
                      campaign.submissionsToken
                    }`,
                  }}
                  target="_blank"
                >
                  <Typography variant="body1" color="textSecondary">
                    Download original submissions
                  </Typography>
                </Link>
              )}
              <Link
                to={{
                  pathname: `${getBaseURL()}/campaigns/csv/report/${campaign.reportToken}`,
                }}
                target="_blank"
              >
                <Typography variant="body1" color="textSecondary">
                  Download campaign wrap report CSV (Excel)
                </Typography>
              </Link>
            </div>

            <div className={classes.shareWrapper}>
              <div
                className={classNames([
                  classes.shareReport,
                  {[classes.shareReportOpen]: modalOpen},
                ])}
              >
                <CopyPasteable
                  copyString={`${window.location.origin}/report/${campaign.reportToken}`}
                >
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className={classes.shareReportInput}
                  >
                    {window.location.origin}/report/{campaign.reportToken}
                  </Typography>
                </CopyPasteable>
                <Button
                  className={classes.shareReportButton}
                  small
                  lightGold
                  text="Share report"
                  onClick={toggleReportModal}
                />
              </div>

              <Prompt
                title="Renew campaign report token?"
                body={
                  <div>
                    <p>This will invalidate any previously shared report links. </p>
                    <p>You'll need to share the new link with your colleagues.</p>
                  </div>
                }
                confirmText="Renew token"
                control={
                  <div className={classNames([classes.renew, {[classes.renewOpen]: modalOpen}])}>
                    Renew
                  </div>
                }
                onSubmit={renewToken}
              />
            </div>
            <p className={classNames([classes.helpText, {[classes.helpTextOpen]: modalOpen}])}>
              Use this link to share the report with co-workers
            </p>
          </div>
        </div>
      </div>
      <PostAnalysis
        open={analysisOpen}
        toggle={toggleAnalysis}
        emojis={campaign.emojis}
        hashtags={campaign.hashtags}
        commentSentiment={campaign.commentSentiment}
        captionSentiment={campaign.captionSentiment}
      />
      {campaign.state == 'draft' && canEditCampaign && (
        <div className={classes.addPost}>
          <Form onSubmit={addPost}>
            <FormSubmitButton text="Add post +" gold noMargin inline skipSuccess />
          </Form>
        </div>
      )}
      <Box marginTop="22px">
        {campaign.posts.map((post: Post, i: number) => (
          <CampaignPost key={post.id} post={post} postIndex={i + 1} campaign={campaign} />
        ))}
      </Box>
      {isOverlay && children}
    </div>
  );
};

export default CampaignPosts;
