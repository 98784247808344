import React, {ReactNode, useEffect} from 'react';
import {useRouteMatch, withRouter} from 'react-router-dom';
import {gql, useLazyQuery, useQuery} from '@apollo/client';
import {createStyles, Grid, Hidden, makeStyles} from '@material-ui/core';

import {Definition, LoaderDots, LogoWithText} from 'components/Widgets';

import {useDataServiceApi} from 'hooks/useDataServiceApi';
import useCurrentUser from 'hooks/useCurrentUser';
import {InfluencerPerformanceByPlatformResponse} from 'hooks/useDataServiceApi/types';

import {InfluencerInterests} from '../components';
import {InfluencerNavigationLinks, InfluencerProfile, InfluencerTags} from './components';
import InfoDump from './components/InfoDump';
import RewardsEngagementAndScore from './components/RewardsEngagementAndScore';
import GigEngagement from './components/GigEngagement/GigEngagement';

interface Props {
  children: ReactNode | ReactNode[];
}

const InfluencerWrapper: React.FC<Props> = ({children}) => {
  const classes = useStyles();
  const {params} = useRouteMatch();
  const currentUser = useCurrentUser();

  const {influencerId, hideInfluencerNavigation} = params;

  const {
    error: dataApiError,
    loading: dataApiLoading,
    fetchInfluencerPerformanceByPlatform,
  } = useDataServiceApi();
  const [influencerPerformanceByPlatform, setInfluencerPerformanceByPlatform] = React.useState<
    InfluencerPerformanceByPlatformResponse | undefined
  >();

  const {
    loading: gqlLoading,
    data,
    error: gqlError,
  } = useQuery(InfluencerWrapperQuery, {
    variables: {id: influencerId},
  });
  const [loadCounts, countsData] = useLazyQuery(InfluencerWrapperCountQuery, {
    variables: {id: influencerId},
  });
  const loading = gqlLoading || dataApiLoading;
  const error = gqlError ?? dataApiError;

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser?.takumiDataApiToken || !influencerId) return;
      const data = await fetchInfluencerPerformanceByPlatform({
        apiToken: currentUser?.takumiDataApiToken,
        influencerId,
      });
      setInfluencerPerformanceByPlatform(data);
    };
    fetchData();
  }, [currentUser?.takumiDataApiToken, influencerId, fetchInfluencerPerformanceByPlatform]);

  if (error) return <div>Unknown error encountered</div>;
  if (loading) return <LoaderDots />;

  const {influencer} = data;

  if (!influencer) {
    return <LogoWithText header="Influencer not found" />;
  }

  if (!hideInfluencerNavigation && !countsData.called) {
    if (influencer.hasFacebookPage) {
      loadCounts();
    }
  }

  return (
    <Grid container alignItems="flex-start">
      <Grid item xs={12}>
        <InfluencerProfile influencer={influencer} />
      </Grid>
      <Grid item container xs={12} md={8} direction="column">
        {influencer.isSignedUp && (
          <Grid item xs={12} className={classes.interests}>
            <Definition
              title="Interests"
              extraMargin
              definition={<InfluencerInterests influencer={influencer} />}
            />
          </Grid>
        )}
        {!hideInfluencerNavigation && (
          <Grid item xs={12} className={classes.navigation}>
            <InfluencerNavigationLinks
              influencer={influencer}
              mediaCount={countsData.data?.media?.count}
              gigCount={countsData.data?.gigs?.count}
              campaignCount={countsData.data?.campaigns?.count}
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.content}>
          {children}
        </Grid>
      </Grid>

      <Hidden mdDown>
        <Grid item md={1} />
      </Hidden>

      <Grid container item xs={12} md={3} spacing={4}>
        <Grid item>
          <RewardsEngagementAndScore influencer={influencer} />
        </Grid>
        {influencerPerformanceByPlatform?.length && (
          <Grid item>
            <GigEngagement data={influencerPerformanceByPlatform} />
          </Grid>
        )}
        <Grid item>
          <InfluencerTags influencer={influencer} />
        </Grid>
        <Grid item>
          <InfoDump influencer={influencer} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    interests: {
      marginTop: '20px',
    },
    navigation: {
      marginTop: '20px',
    },
    content: {
      marginTop: '20px',
    },
    tags: {
      marginTop: '20px',
    },
  })
);

const InfluencerWrapperQuery = gql`
  query InfluencerWrapper($id: UUIDString!) {
    influencer: profile(id: $id) {
      id
      ...InfluencerProfileFields
    }
  }
  ${InfluencerProfile.fragments.influencer}
`;

const InfluencerWrapperCountQuery = gql`
  query InfluencerWrapperCountQuery($id: UUIDString!) {
    campaigns: influencerCampaigns(id: $id) {
      count
    }
    gigs: gigsForInfluencer(id: $id) {
      count
    }
    media: instagramMediaByUsername(id: $id) {
      count
    }
  }
`;

export default withRouter(InfluencerWrapper);
