import compose from 'lodash/flowRight';
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link, RouteComponentProps} from 'react-router-dom';
import {graphql} from '@apollo/client/react/hoc';
import {Grid} from '@material-ui/core';

import {StoryInsight, PostInsight} from './components';
import styles from './style.css';
import {LoaderDots, Media, MediaContainer} from '../../../../components/Widgets';
import {Compare} from '../../../../components/Icons';
import {
  InsightQuery,
  ProcessPostInsightMutation,
  ProcessStoryInsightMutation,
  ProcessTikTokInsightMutation,
  RequestInsightResubmissionMutation,
} from './graphqlQueries';
import {campaignUrl} from '../../../../consts/urls';
import {IInsightProps} from './types';
import Pagination from './components/Pagination';
import TikTokInsight from './components/TikTokInsight';
import {usernameForPost} from 'consts/influencerHelper';

class Insight extends PureComponent<IInsightProps & RouteComponentProps> {
  static propTypes = {
    insight: PropTypes.object,
    loading: PropTypes.bool,
    processStoryInsight: PropTypes.func.isRequired,
    processPostInsight: PropTypes.func.isRequired,
    requestInsightResubmission: PropTypes.func.isRequired,
  };

  constructor(props: IInsightProps & RouteComponentProps) {
    super(props);

    this.state = {offset: 0};
  }

  processStoryInsight = async (id: string, model: any) =>
    await this.props.processStoryInsight({id, ...model});

  processPostInsight = async (id: string, model: any) =>
    await this.props.processPostInsight({id, ...model});

  processTikTokInsight = async (id: string, model: any) =>
    await this.props.processTikTokInsight({id, ...model});

  requestInsightResubmission = async (reason: string) =>
    await this.props.requestInsightResubmission(reason);

  friendlyInsightName = (postType: string) => {
    const postTypeMap: Record<string, string> = {
      story: 'Instagram Story',
      tiktok: 'TikTok Post',
      standard: 'Instagram Post',
      reel: 'Instragram Reel',
      youtube: 'YouTube Video',
      content: 'Content only',
    };

    if (!(postType in postTypeMap)) {
      return 'Instagram Post';
    }

    return postTypeMap[postType];
  };

  insightFormByType = (insight: PostInsight | StoryInsight | TikTokInsight) => {
    if (insight.__typename === 'StoryInsight') {
      return (
        <StoryInsight
          key={insight.id}
          insight={insight}
          submitInsight={(model: any) => this.processStoryInsight(insight.id, model)}
          requestInsightResubmission={this.requestInsightResubmission}
        />
      );
    } else if (insight.__typename === 'TikTokInsight') {
      return (
        <TikTokInsight
          key={insight.id}
          insight={insight}
          submitInsight={(model: any) => this.processTikTokInsight(insight.id, model)}
          requestInsightResubmission={this.requestInsightResubmission}
        ></TikTokInsight>
      );
    } else {
      return (
        <PostInsight
          key={insight.id}
          insight={insight}
          submitInsight={(model: any) => this.processPostInsight(insight.id, model)}
          requestInsightResubmission={this.requestInsightResubmission}
        />
      );
    }
  };

  render() {
    const {insight, loading} = this.props;
    if (loading) {
      return <LoaderDots />;
    } else if (!insight) {
      return null;
    }

    return (
      <div>
        <div className={styles.explanation}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <div className={styles.explanationcontent}>
                {this.friendlyInsightName(insight.gig.post.postType)} insight submitted by{' '}
                <Link
                  className={styles.link}
                  to={`/influencers/${insight.gig.influencer.id}/gigs/${insight.gig.id}`}
                >
                  {usernameForPost(insight.gig.post, insight.gig.influencer)}
                </Link>{' '}
                for{' '}
                <Link className={styles.link} to={campaignUrl(insight.gig.offer.campaign)}>
                  {insight.gig.offer.campaign.name}
                </Link>
              </div>
            </Grid>
            <Grid item>
              {this.props.items && (
                <Pagination
                  items={this.props.items}
                  count={this.props.insightsCount}
                  goToFirstPage={this.props.goToFirstPage}
                />
              )}
            </Grid>
          </Grid>
        </div>

        <div className={styles.root}>
          <MediaContainer width={480} lightBackground>
            {insight.media && (
              <Media
                media={insight.media}
                width={480}
                height={1550}
                autoPlay={false}
                alt={`Screenshot for ${insight?.gig?.offer?.campaign?.name}`}
                galleryDotsStyle={undefined}
                galleryDotStyle={undefined}
                galleryActiveDotStyle={undefined}
              />
            )}
          </MediaContainer>
          <div className={styles.compare}>
            <Compare ariaLabel="compare" />
          </div>
          {this.insightFormByType(insight)}
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(InsightQuery, {
    options: ({match: {params}}) => ({
      variables: {
        insightId: params.insightId,
      },
    }),
    props: ({data: {insight, loading}}: any) => {
      return {
        loading,
        insight,
      };
    },
  }),
  graphql(ProcessStoryInsightMutation, {
    props: ({mutate}) => ({
      processStoryInsight: ({id, ...args}: any) =>
        mutate({
          variables: {id, ...args},
          refetchQueries: ['InsightsCountQuery', 'InsightsQuery'],
          optimisticResponse: {
            updateStoryInsight: {
              __typename: 'UpdateStoryInsight',
              ok: true,
              insight: {
                __typename: 'Insight',
                id,
                state: 'approved',
                processed: true,
              },
            },
          },
        }),
    }),
  }),
  graphql(ProcessPostInsightMutation, {
    props: ({mutate}) => ({
      processPostInsight: ({id, ...args}: any) =>
        mutate({
          variables: {id, ...args},
          refetchQueries: ['InsightsCountQuery', 'InsightsQuery'],
          optimisticResponse: {
            updatePostInsight: {
              __typename: 'UpdatePostInsight',
              ok: true,
              insight: {
                __typename: 'Insight',
                id,
                state: 'approved',
                processed: true,
              },
            },
          },
        }),
    }),
  }),
  graphql(ProcessTikTokInsightMutation, {
    props: ({mutate}) => ({
      processTikTokInsight: ({id, ...args}: any) =>
        mutate({
          variables: {id, ...args},
          refetchQueries: ['InsightsCountQuery', 'InsightsQuery'],
          optimisticResponse: {
            updateTikTokInsight: {
              __typename: 'UpdateTikTokInsight',
              ok: true,
              insight: {
                __typename: 'Insight',
                id,
                state: 'approved',
                processed: true,
              },
            },
          },
        }),
    }),
  }),
  graphql(RequestInsightResubmissionMutation, {
    props: ({
      mutate,
      ownProps: {
        match: {params},
      },
    }) => ({
      requestInsightResubmission: (reason: string) =>
        mutate({
          variables: {
            insightId: params.insightId,
            reason,
          },
          refetchQueries: ['InsightsCountQuery', 'InsightsQuery'],
          optimisticResponse: {
            requestInsightResubmission: {
              __typename: 'UpdatePostInsight',
              ok: true,
              insight: {
                __typename: 'Insight',
                id: params.insightId,
                state: 'requires_resubmit',
              },
            },
          },
        }),
    }),
  })
)(Insight);
