import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {Grid} from '@material-ui/core';

import {Definition, Heading, ProfilePicture} from 'components/Widgets';
import buttonStyles from 'components/Widgets/Button/style.css';

import {numberWithCommas, capitalize} from 'consts/utilities';
import {getBaseURL, getEnvLocationURL} from 'services/api';
import BudgetDefinition from 'scenes/Campaign/BudgetDefinition';

import {ReportLogo} from '../../components';
import {PostAnalysis} from '../../../components';
import styles from './style.css';

const CampaignReport = ({campaign}) => {
  const brief = campaign.description;
  const campaignType = campaign.rewardModel;
  const price = campaign.price.formattedValue;
  const cpe = campaign.costPerEngagement.formattedValue;
  const csvUrl = `${getBaseURL()}/campaigns/csv/report/${campaign.reportToken}`;
  const minGigs = campaign.target?.targetOverall;
  const rewardModel = campaign.rewardModel;
  const showDownloadButton = campaign.posts[0]?.gigs?.length > 0;
  const zipUrl = `https://download.${getEnvLocationURL()}/download/${campaign.reportToken}`;
  let key = 0;
  const getKey = () => `CampaignReport${key++}`;

  return (
    <div className={styles.root}>
      <div className={styles.topRow}>
        <div className={styles.leftColumn}>
          <div className={styles.advertiserHeader}>
            <div className={styles.advertiserInfo}>
              <div className={styles.advertiserLogo}>
                <ProfilePicture square size={40} src={campaign.advertiser.profilePicture} />
              </div>
              <p className={styles.advertiserName}>{campaign.advertiser.name}</p>
            </div>
          </div>
        </div>

        <div className={styles.rightColumn}>
          <ReportLogo />
        </div>
      </div>

      <div className={styles.leftColumn}>
        <Heading title={campaign.name || 'New Campaign'} />
        <div className={styles.campaignDatesWrapper}>
          <Grid container spacing={2}>
            <Grid item>
              <Definition gold title="Type" definition={capitalize(campaignType)} />
            </Grid>
            <Grid item>
              <BudgetDefinition budget={price} />
            </Grid>
            <Grid item>
              {rewardModel === 'assets' && <Definition gold title="Assets" definition={minGigs} />}
            </Grid>
          </Grid>

        </div>
      </div>
      <div className={styles.rightColumn}>
        {showDownloadButton && (
          <a
            href={zipUrl}
            download
            className={classNames([buttonStyles.root, styles.downloadButton])}
          >
            Download all images
            <span key=".zip" className={styles.fade}>
              .zip
            </span>
          </a>
        )}
        {showDownloadButton && (
          <a href={csvUrl} className={styles.csvLink}>
            Download CSV (Excel) <span className={styles.fade}>.csv</span>
          </a>
        )}
      </div>

      <div className={styles.leftColumn}>
        {brief && (
          <div className={styles.brief}>
            {brief.split('\n').map(i => (
              <p key={getKey()}>{i}</p>
            ))}
          </div>
        )}
      </div>

      <div className={styles.pageBreak} />

      {campaign.reach > 0 && (
        <section className={styles.reportSection}>
          <div className={styles.sectionHeading}>Results</div>
          <div className={styles.definitionRow}>
            <Grid container spacing={2}>
              <Grid item>
                <Definition gold title="Submissions" definition={numberWithCommas(campaign.totalSubmissions)} />
              </Grid>
              <Grid item>
                <Definition gold title="Followers" definition={numberWithCommas(campaign.reach)} />
              </Grid>  
            </Grid>
          </div>
          <div className={styles.definitionRow}>
            <Grid container spacing={2}>
              <Grid item>
                <Definition gold title="Likes" definition={numberWithCommas(campaign.likes)} />
              </Grid>
              <Grid item>
                <Definition gold title="Comments" definition={numberWithCommas(campaign.comments)} />
              </Grid>
              <Grid item>
                <Definition gold title="Engagements" definition={numberWithCommas(campaign.engagements)} />
              </Grid>
              <Grid item>
                <Definition gold title="Engagement rate" definition={campaign.engagement.formattedValue} />
              </Grid>
              <Grid item>
                <Definition gold title="CPE" definition={cpe} />
              </Grid>
            </Grid>
          </div>
        </section>
      )}

      {campaign.reportSummary && (
        <section className={styles.reportSection}>
          <div className={styles.sectionHeading}>Summary</div>
          <div className={styles.leftColumn}>
            <div className={styles.brief}>
              {campaign.reportSummary.split('\n').map(i => (
                <p key={getKey()}>{i}</p>
              ))}
            </div>
          </div>
        </section>
      )}

      <section className={styles.reportSection}>
        <PostAnalysis
          open
          hashtags={campaign.hashtags}
          emojis={campaign.emojis}
          commentSentiment={campaign.commentSentiment}
          captionSentiment={campaign.captionSentiment}
        />
      </section>

      <div className={styles.pageBreak} />
    </div>
  );
};

CampaignReport.propTypes = {
  campaign: PropTypes.object,
};

export default CampaignReport;
