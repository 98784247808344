import {gql} from '@apollo/client';
import {Influencer} from 'services/fragments';

export const PlaylistInfluencerFragment = gql`
  fragment playlistInfluencerFragment on InfluencerSearchResult {
    __typename
    id
    fullName
    email
    profilePicture
    interests {
      id
    }
    ... on InfluencerSearchResult {
      id
      ...socialPlatformsForInfluencerSearch
    }
    userCreated
    participatingCampaignIds
    estimatedEngagementsPerPost
    estimatedImpressions
  }
  ${Influencer.socialPlatformsForInfluencerSearchFragment}
`;

export const NotifyInfluencerSearchQuery = gql`
  query NotifyInfluencerSearch(
    $campaignId: UUIDString
    $searchString: String
    $gender: String
    $hasFacebookPage: Boolean
    $hasTiktokAccount: Boolean
    $hasYoutubeChannel: Boolean
    $hasTwitchChannel: Boolean
    $minFollowers: Int
    $maxFollowers: Int
    $minEngagement: Float
    $maxEngagement: Float
    $minAge: Int
    $maxAge: Int
    $minParticipatingCampaignCount: Int
    $maxParticipatingCampaignCount: Int
    $interestIds: [UUIDString]
    $hasInterests: Boolean
    $sortBy: InfluencerSortType
    $sortOrder: SortOrder
    $information: InformationParams
    $state: [String]
    $eligible: Boolean
    $cursor: String
  ) {
    influencerSearch(
      eligibleForCampaignId: $campaignId
      hasNoOfferInCampaignId: $campaignId
      search: $searchString
      gender: $gender
      hasFacebookPage: $hasFacebookPage
      hasTiktokAccount: $hasTiktokAccount
      hasYoutubeChannel: $hasYoutubeChannel
      hasTwitchChannel: $hasTwitchChannel
      minFollowers: $minFollowers
      maxFollowers: $maxFollowers
      minEngagement: $minEngagement
      maxEngagement: $maxEngagement
      minAge: $minAge
      maxAge: $maxAge
      minParticipatingCampaignCount: $minParticipatingCampaignCount
      maxParticipatingCampaignCount: $maxParticipatingCampaignCount
      interestIds: $interestIds
      hasInterests: $hasInterests
      sortBy: $sortBy
      sortOrder: $sortOrder
      information: $information
      state: $state
      eligible: $eligible
      first: 24
      after: $cursor
    ) {
      edges {
        node {
          id
          ...playlistInfluencerFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      count
    }
  }
  ${PlaylistInfluencerFragment}
`;
