import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Card, Divider, Grid, makeStyles, TableCell, Typography} from '@material-ui/core';
import {Icon} from '@iconify/react';

import {useDataServiceApi} from 'hooks/useDataServiceApi';
import type {CampaignPerformanceByGigResponse} from 'hooks/useDataServiceApi/types';

import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {influencerSelect} from '../../../../../consts/dataApiProperties';
import {DATA_SERVICE_MODEL_INFO, shouldDisplayMetric} from '../helper';
import {
  ApiError,
  NoPerformanceData,
  LoaderDots,
  TableZebraRow,
  Tooltip,
  MediaPreview,
  MediaContainer,
  SortableTable,
  FormattedDate,
} from '../../../../../components/Widgets';
import {numberWithCommas} from '../../../../../consts/utilities';
import {socialIcons} from '../../../../../consts/icons';
import {isDeleted, latestMedia, hasMedia} from '../../../../../consts/gigHelper';
import {getPostTypeFormatted} from '../../../../../consts/campaignPostType';
import {PerformanceData, GigData} from '../types';

interface Props {
  campaign?: Campaign;
  gigs?: GigData;
  apiData: (data: CampaignPerformanceByGigResponse) => void;
}

const CampaignPerformanceByGig: React.FC<Props> = ({campaign, gigs, apiData}) => {
  const user = useCurrentUser();
  const classes = useStyles();
  const {error, loading, fetchCampaignPerformanceByGigData} = useDataServiceApi();
  const [resourceData, setResourceData] = useState<any>(null);
  const title = 'Posts';

  useEffect(() => {
    const fetchData = async () => {
      if (user?.takumiDataApiToken && campaign?.id) {
        const data =
          (await fetchCampaignPerformanceByGigData({
            apiToken: user?.takumiDataApiToken || '',
            campaignId: campaign?.id || '',
            orderBy: 'impression_total.desc',
            select: influencerSelect,
          })) ?? [];
        setResourceData(data);
        apiData(data);
      }
    };
    fetchData();
  }, [user?.takumiDataApiToken, campaign?.id, fetchCampaignPerformanceByGigData]);

  const gigMetrics = resourceData
    ? resourceData.map((apiResponse: PerformanceData) => {
        const influencer = {
          gig_id: apiResponse.gig_id,
          influencer_id: apiResponse.influencer?.influencer_id,
          full_name: apiResponse.influencer?.full_name,
          instagram_handle: apiResponse.influencer?.instagram_handle,
          tiktok_handle: apiResponse.influencer?.tiktok_handle,
          instagram_profile_url: apiResponse.influencer?.instagram_profile_url,
          tiktok_profile_url: apiResponse.influencer?.tiktok_profile_url,
          instagram_profile_picture_url: apiResponse.influencer?.instagram_profile_picture_url,
          tiktok_profile_picture_url: apiResponse.influencer?.tiktok_profile_picture_url,
          generated_timestamp: apiResponse.generated_timestamp,

          follower_total: {
            raw: apiResponse.follower_total,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.follower_total.formatter!(
              apiResponse.follower_total
            ),
          },
          digital_content_count: {
            raw: apiResponse.digital_content_count,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.digital_content_count
              .formatter!(apiResponse.digital_content_count),
          },
          impression_total: {
            raw: apiResponse.impression_total,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.impression_total.formatter!(
              apiResponse.impression_total
            ),
          },
          reach_total: {
            raw: apiResponse.reach_total,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.reach_total.formatter!(
              apiResponse.reach_total
            ),
          },
          engagement_total: {
            raw: apiResponse.engagement_total,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.engagement_total.formatter!(
              apiResponse.engagement_total
            ),
          },
          engagement_rate_by_impressions: {
            raw: apiResponse.engagement_rate_by_impressions,
            formatted: DATA_SERVICE_MODEL_INFO.campaign_performance.engagement_rate_by_impressions
              .formatter!(apiResponse.engagement_rate_by_impressions),
          },
        };
        return influencer;
      })
    : [];

  const enabledMetrics = (campaign?.organicPerformanceMetrics ?? []) as string[];
  const gigContent = gigs?.edges.map(gig => gig.node);
  const gigData = gigContent?.map(gig => {
    const gigMetric = gigMetrics.find(metric => metric.gig_id === gig.id);
    return {...gig, ...gigMetric};
  });

  if (loading) {
    return <LoaderDots className={classes.spacing} />;
  }

  if (error) {
    return <ApiError title={title} classes={classes} />;
  }

  const headCells = [
    {id: 'full_name', numeric: false, label: 'Post'},
    {id: 'follower_total', numeric: true, label: 'Followers'},
    {id: 'reach_total', numeric: true, label: 'Reach'},
    {id: 'impression_total', numeric: true, label: 'Impressions'},
    {id: 'engagement_total', numeric: true, label: 'Engagements'},
    {id: 'engagement_rate_by_impressions', numeric: true, label: 'Engagement Rate'},
  ].filter(({id}) => id === 'full_name' || shouldDisplayMetric(enabledMetrics, id));

  const engagementTotal = (row: GigData) =>
    row.post.postType === 'story'
      ? {raw: 0, formatted: '-'}
      : row.engagement_total || {raw: 0, formatted: '0'};

  const engagementRateByImpressions = (row: GigData) =>
    row.post.postType === 'story'
      ? {raw: 0, formatted: '-'}
      : row.engagement_rate_by_impressions || {raw: 0, formatted: '0'};

  const filteredData = gigData.filter(row => row.digital_content_count?.raw > 0);

  const formattedMetric = (metric: any) => {
    return metric
      ? {raw: metric.raw || 0, formatted: metric.formatted || '-'}
      : {raw: 0, formatted: '-'};
  };

  return (
    <>
      <Typography variant="h4" className={classes.header}>
        {title}
      </Typography>
      <Divider className={classes.spacing} />
      <Grid container spacing={3}>
        {filteredData.length > 0 ? (
          <Grid item xs={12}>
            <Card>
              <SortableTable
                rows={filteredData}
                headCells={headCells}
                sortMetric="engagement_rate_by_impressions"
                renderRow={(row: GigData, index: number) => {
                  const follower_total = formattedMetric(row.follower_total);
                  const reach_total = formattedMetric(row.reach_total);
                  const impression_total = formattedMetric(row.impression_total);
                  return (
                    <TableZebraRow key={index} tabIndex={-1}>
                      <TableCell component="th" scope="row" className={classes.link}>
                        <Link to={`/gigs/${row.gig_id}`}>
                          <Grid container spacing={1} alignItems="center" wrap="nowrap">
                            <Grid item>
                              {hasMedia(row) && (
                                <MediaContainer height={100} width={100}>
                                  <MediaPreview
                                    media={latestMedia(row)}
                                    height={100}
                                    width={100}
                                    isDeleted={isDeleted(row)}
                                    arePillsShown={false}
                                  />
                                </MediaContainer>
                              )}
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">
                                <strong>{row.full_name}</strong>
                              </Typography>
                              <Typography variant="body2">
                                <i>@{row.instagram_handle || row.tiktok_handle}</i>
                              </Typography>
                              <Divider style={{margin: '5px auto'}} />
                              <Typography
                                variant="caption"
                                style={{display: 'flex', alignItems: 'center'}}
                              >
                                <Icon
                                  icon={socialIcons[row.post.postType] || 'ic:baseline-photo'}
                                  width={16}
                                />
                                {getPostTypeFormatted(row.post.postType)}
                              </Typography>
                              <Typography variant="caption">
                                <FormattedDate date={row.created} />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Link>
                      </TableCell>

                      {shouldDisplayMetric(enabledMetrics, 'follower_total') && (
                        <TableCell align="center">
                          <Tooltip overlay={numberWithCommas(follower_total.raw)} placement="left">
                            <Typography variant="body1" component="span">
                              {follower_total.formatted}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      )}

                      {shouldDisplayMetric(enabledMetrics, 'reach_total') && (
                        <TableCell align="center">
                          <Tooltip overlay={numberWithCommas(reach_total.raw)} placement="left">
                            <Typography variant="body1" component="span">
                              {reach_total.formatted}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      )}

                      {shouldDisplayMetric(enabledMetrics, 'impression_total') && (
                        <TableCell align="center">
                          <Tooltip
                            overlay={numberWithCommas(impression_total.raw)}
                            placement="left"
                          >
                            <Typography variant="body1" component="span">
                              {impression_total.formatted}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      )}

                      {shouldDisplayMetric(enabledMetrics, 'engagement_total') && (
                        <TableCell align="center">
                          <Tooltip
                            overlay={numberWithCommas(engagementTotal(row).raw)}
                            placement="left"
                          >
                            <Typography variant="body1" component="span">
                              {engagementTotal(row).formatted}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      )}

                      {shouldDisplayMetric(enabledMetrics, 'engagement_rate_by_impressions') && (
                        <TableCell align="center">
                          <Tooltip
                            overlay={numberWithCommas(engagementRateByImpressions(row).raw)}
                            placement="left"
                          >
                            <Typography variant="body1" component="span">
                              {engagementRateByImpressions(row).formatted}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableZebraRow>
                  );
                }}
              />
            </Card>
          </Grid>
        ) : (
          <Grid item>
            <NoPerformanceData title="Post performance metrics" />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
  link: {
    cursor: 'pointer',
  },
});

export default CampaignPerformanceByGig;
