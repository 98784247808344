import React from 'react';
import {Icon as IconifyIcon} from '@iconify/react';
import {Box, Grid, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {numberMillified, numberWithCommas} from 'consts/utilities';
import {black50} from 'consts/brand.integration';
import {isNumber} from 'lodash/fp';

interface Props {
  data: TwitchVideo;
}

const TwitchPostStats: React.FC<Props> = ({data}) => {
  const classes = useStyles();
  return (
    <>
      {isNumber(data.viewCount) && (
        <Grid item>
          <Tooltip title={`Views - ${numberWithCommas(data.viewCount)}`} placement="bottom" arrow>
            <Box className={classes.iconWithValue}>
              <Icon icon="mdi:videocam" />
              <span className={classes.value}>{numberMillified(data.viewCount)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(data.streamAverageViewers) && (
        <Grid item>
          <Tooltip
            title={`Stream average viewers - ${numberWithCommas(data.streamAverageViewers)}`}
            placement="bottom"
            arrow
          >
            <Box className={classes.iconWithValue}>
              <Icon icon="simple-line-icons:graph" />
              <span className={classes.value}>{numberMillified(data.streamAverageViewers)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
      {isNumber(data.streamMaxViewers) && (
        <Grid item>
          <Tooltip
            title={`Stream max viewers - ${numberWithCommas(data.streamMaxViewers)}`}
            placement="bottom"
            arrow
          >
            <Box className={classes.iconWithValue}>
              <Icon icon="tdesign:chart-maximum" />
              <span className={classes.value}>{numberMillified(data.streamMaxViewers)}</span>
            </Box>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};

const Icon = ({icon}: {icon: string}): JSX.Element => <IconifyIcon icon={icon} color={black50} />;

const useStyles = makeStyles({
  iconWithValue: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '.6rem',
  },
  value: {
    marginLeft: '0.2rem',
    color: black50,
  },
});

export default TwitchPostStats;
