import {gql} from '@apollo/client';
import {Campaign} from 'services/fragments';

export const UserQuery = gql`
  query userSettings {
    currentUser {
      id
      takumiDataApiToken
    }
  }
`;

export const CampaignQuery = gql`
  query CampaignContentCalendarCampaignQuery($campaignId: UUIDString!) {
    campaign(id: $campaignId) {
      id
      name
      description
      brandSafety
      extendedReview
      archived
      canBeCompleted
      state
      started
      reachTotal
      impressionsTotal
      engagementsTotal
      followersTotal
      likes
      marketSlug
      participation {
        type
        count
      }
      isFullyReserved
      ...targetFields
      posts {
        id
        archived
        opened
        deadline
        submissionDeadline
        postType
        schedule {
          submissionDeadline
        }
      }
    }
  }
  ${Campaign.targetFragment}
`;
