import React, {useState, useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {Typography, makeStyles} from '@material-ui/core';

import useCurrentUser from '../../../hooks/useCurrentUser';
import {ApiError, NoPerformanceData, LoaderDots} from '../../../components/Widgets';
import {useDataServiceApi} from '../../../hooks/useDataServiceApi';
import AdvertiserTabs from '../AdvertiserTabs';
import {Match} from '../AdvertiserMembers/types';
import {AdvertiserCampaignQuery} from './graphqlQuery';
import {AdvertiserMetrics, AdvertiserCampaigns} from '../components';
import {brandTotals, brandAverages} from '../../../consts/brandMetrics';

interface Props {
  match: Match;
}

const AdvertiserPerformance: React.FC<Props> = ({match: {params}}) => {
  const user = useCurrentUser();
  const classes = useStyles();
  const {data} = useQuery(AdvertiserCampaignQuery, {
    variables: {domain: params.advertiser},
  });
  const advertiserId = data?.advertiser?.id;
  const {error, fetchAdvertiserPerformance} = useDataServiceApi();
  const [resourceData, setResourceData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user?.takumiDataApiToken && advertiserId) {
        const fetchedData = await fetchAdvertiserPerformance({
          apiToken: user?.takumiDataApiToken,
          advertiserId: advertiserId,
        });
        setResourceData(fetchedData || []);
        setLoading(false);
      }
    };
    fetchData();
  }, [user?.takumiDataApiToken, advertiserId, fetchAdvertiserPerformance]);

  const brandMetrics = {...brandTotals, ...brandAverages};

  const performanceMetrics = Object.entries(resourceData[0] || {}).filter(
    ([key]) => brandMetrics[key]
  );

  const advertiser = data?.advertiser;

  if (loading) {
    return <LoaderDots className={classes.spacing} />;
  }

  if (error) {
    return <ApiError title="Brand Performance" classes={classes} />;
  }

  return (
    <>
      <AdvertiserTabs params={params} />
      <Typography component="h3" variant="h3" className={classes.header}>
        Performance
      </Typography>
      {performanceMetrics.length ? (
        <>
          <AdvertiserMetrics
            title="Total Performance"
            metrics={performanceMetrics}
            metricsCategory={brandTotals}
            classes={classes}
          />
          <AdvertiserMetrics
            title="Campaign Averages"
            metrics={performanceMetrics}
            metricsCategory={brandAverages}
            classes={classes}
          />
          <AdvertiserCampaigns advertiser={advertiser} />
        </>
      ) : (
        <NoPerformanceData title="Brand performance metrics" />
      )}
    </>
  );
};

const useStyles = makeStyles({
  header: {
    marginTop: '3rem',
  },
  spacing: {
    margin: '10px auto 20px',
  },
});

export default AdvertiserPerformance;
